import React from "react";
import Dialog from "@mui/material/Dialog";
import DeleteImage from "../../../assets/icons/delete-modal-pic.svg";
import Cross from "../../../assets/icons/cross.svg";
import "./CommonStylingForDeleteDialog.scss";
import { deleteBusinessAsync } from "../../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
const TempBusAccountVerificationDialog = ({
  SelectedTempId,
  tempBussAccountDeleteData,
  setTempBussAccountDeleteData,
  TempBusAccountHandleClose,
}) => {
  const dispatch = useDispatch()
  const { deleteBusinessLoading } = useSelector(state => state.users)
  const DeleteBusVerification = () => {
    dispatch(deleteBusinessAsync(SelectedTempId?.current)).then((r) => {
      if (r === true) {
        setTempBussAccountDeleteData(false)
      }
    });

  };
  return (
    <Dialog open={tempBussAccountDeleteData}>
      {
        deleteBusinessLoading ? (<Loader />) : null
      }
      <div className="DeleteContainer">
        <img
          onClick={TempBusAccountHandleClose}
          className="Cancelicon"
          src={Cross}
          alt="Cancel"
        />
        <img className="DeleteImage" src={DeleteImage} alt="Delete User" />
        <p>Are you sure you want to delete this admin?</p>
        <div className="CancelAndDeleteBTN">
          <h5 onClick={() => setTempBussAccountDeleteData(false)}>
            No, Cancel
          </h5>
          <button onClick={DeleteBusVerification}>
            <h4>Yes Delete</h4>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default TempBusAccountVerificationDialog;
