import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
const initialState = {
  // State----
  getAllStateLoading: false,
  getAllStateData: [],
  getAllStateError: "",

  //----Cities--
  getAllCitiesLoading: false,
  getAllCitiesData: [],
  getAllCitiesError: "",
};
export const locationsSlice = createSlice({
  name: "locationSlice",
  initialState,
  reducers: {
    getAllState: (state) => {
      state.getAllStateLoading = true;
    },
    getAllStateSuccess: (state, action) => {
      state.getAllStateLoading = false;
      state.getAllStateData = action.payload;
    },
    getAllStateFailed: (state, action) => {
      state.getAllStateLoading = false;
      state.getAllStateError = action.payload;
    },

    getAllCites: (state) => {
      state.getAllCitiesLoading = true;
    },
    getAllCitesSuccess: (state, action) => {
      state.getAllCitiesLoading = false;
      state.getAllCitiesData = action.payload;
    },
    getAllCitesFailed: (state, action) => {
      state.getAllCitiesLoading = false;
      state.getAllCitiesError = action.payload;
    },
  },
});

export const getAllStateAsync = () => (dispatch) => {
  dispatch(getAllState());
  axiosInstance
    .get("/UsCanada/GetAllstate")
    .then((resp) => {
      console.log(" state response", resp);
      dispatch(getAllStateSuccess(resp.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(getAllStateFailed(error));
    });
};
export const getAllCitiesAsync = () => (dispatch) => {
  dispatch(getAllCites());
  axiosInstance
    .get("/UsCanada/GetAllCities")
    .then((resp) => {
      console.log(" cities response", resp);
      dispatch(getAllCitesSuccess(resp.data));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch(getAllCitesFailed(error));
    });
};

export const {
  getAllCites,
  getAllCitesSuccess,
  getAllCitesFailed,
  getAllState,
  getAllStateSuccess,
  getAllStateFailed,
} = locationsSlice.actions;
export default locationsSlice.reducer;
