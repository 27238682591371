import React, { useState, useCallback } from "react";
import "./FilterMenu.scss";
import Dialog from "@mui/material/Dialog";
import cross from "../../../assets/icons/cross.svg";
import select from "../../../assets/icons/select.svg";
import calenderIcon from "../../../assets/icons/calender.svg";
import LocationBy from "../LocationBy/LocationBy";
import ZipcodeBy from "../ZipCodeBy/ZipcodeBy";
import CountryBy from "../CountryBy/CountryBy";
import FromDate from "../FromDateBy/FromDate";
import ToDate from "../ToDateBy/ToDate";
import {
  getAllCitiesAsync,
  getAllStateAsync,
} from "../../../redux/locationsSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { useEffect } from "react";
import { getAllUsersDataAsync } from "../../../redux/staticsSlice";
import {
  SelectedFilterFromDate,
  SelectedFilterLocation,
  SelectedFilterToDate,
} from "../../../redux/usersSlice";
import { Constants } from "../../../utils/Constants";
const FilterMenu = ({ filteMenus, setFilterMenus, alignment }) => {
  const [openLocationBy, setOpenLocationby] = useState(false);
  const [openZip, setOpenZip] = useState(false);
  const [openCountryDialog, setOpenCountryDialog] = useState(false);
  const [openFromDate, setOpenFromDate] = useState(false);
  const [openToDate, setOpenToDate] = useState(false);
  const {
    selectedLocationForFilter,
    selectedToDateForFilter,
    selectedFromDateForFilter,
  } = useSelector((state) => state.users);
  // Receiving data from child-----------------------
  const [childData, setChildData] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [dateFrom, setDatefrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  // useEffect(() => {
  //   setChildData(null)
  // }, [])
  const handleChildData = useCallback((data) => {
    setChildData(data);
  }, []);
  const handleZipData = useCallback((data) => {
    setZipCode(data);
  }, []);
  const handleCountryData = useCallback((data) => {
    setCountry(data);
  }, []);
  const handleFromDateData = useCallback((data) => {
    setDatefrom(data);
    if (data) {
    } else {
      return <Loader />;
    }
  }, []);
  const handleToDateData = useCallback((data) => {
    setDateTo(data);
  }, []);
  //limits
  const limit = 3;
  var displayedZipData = zipCode.slice(0, limit);
  var displayedChildData = childData;
  var displayedCountryData = country?.slice(0, limit);
  //--Limits ---------------
  const LocationLimitNumber = childData?.length - limit;
  const CountryLimitNumber = country?.length - limit;
  const ZipLimitNumber = zipCode?.length - limit;
  const dispatch = useDispatch();
  const handleClick = () => {
    setFilterMenus(false);
    setOpenZip(false);
    setOpenCountryDialog(false);
    setChildData("");
    setZipCode("");
    setCountry("");
    dispatch(SelectedFilterLocation());
    dispatch(SelectedFilterToDate());
    dispatch(SelectedFilterFromDate());
  };
  const LocationList = () => {
    dispatch(SelectedFilterLocation());
    setOpenLocationby(true);
    dispatch(getAllCitiesAsync());
  };
  const GetFilteredData = () => {
    const filterdata = {
      location: selectedLocationForFilter ? selectedLocationForFilter : "",
      country: "",
      zipCode: "",
      FromDate: selectedFromDateForFilter ? selectedFromDateForFilter : "",
      Type:
        alignment === "Total Pro accounts"
          ? "Pro Business"
          : alignment === "Business Accounts"
          ? "business"
          : "customer",
    };
    dispatch(getAllUsersDataAsync(filterdata, Constants.TAKE, 0, 1));
    handleClick();
  };

  //---------------------------------------------------
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            position: "fixed",
            right: 0,
            m: 0,
            minHeight: "100vh",
            maxHeight: "100vh",
          },
        }}
        open={filteMenus}
        onClose={handleClick}
      >
        <div className="FilterMenuDiv">
          <div className="topDiv">
            <h1>Filter </h1>
            <img
              onClick={() => (
                setFilterMenus(false), dispatch(SelectedFilterLocation())
              )}
              src={cross}
              alt="cancel"
            />
          </div>
          <div className="selectionDiv">
            <div onClick={LocationList} className="FilterBox">
              <h2>Location</h2>
              <img src={select} alt="select" />
              <input
                type="text"
                defaultValue={selectedLocationForFilter}
                placeholder="Choose State"
              />
            </div>
            <div onClick={() => setOpenFromDate(true)} className="FilterBox">
              <h2>Select Month</h2>
              <img className="CalnederIcon" src={calenderIcon} alt="select " />
              <input
                type="text"
                placeholder="choose month"
                value={selectedFromDateForFilter}
              />
            </div>
            {/* <div onClick={() => setOpenToDate(true)} className="FilterBox">
              <h2>To Date</h2>
              <img className="CalnederIcon" src={calenderIcon} alt="select" />
              <input
                type="text"
                placeholder="choose date"
                value={selectedToDateForFilter}
              />
            </div> */}
          </div>
          <div className="BottomItems">
            <h1>Clear All</h1>
            <h2 onClick={GetFilteredData}>Filter Applied</h2>
          </div>
        </div>
      </Dialog>
      <LocationBy
        sendData={handleChildData}
        openLocationBy={openLocationBy}
        setOpenLocationby={setOpenLocationby}
      />
      <ZipcodeBy
        sendData={handleZipData}
        openZip={openZip}
        setOpenZip={setOpenZip}
      />
      <CountryBy
        sendData={handleCountryData}
        openCountryDialog={openCountryDialog}
        setOpenCountryDialog={setOpenCountryDialog}
      />
      <FromDate
        sendData={handleFromDateData}
        openFromDate={openFromDate}
        setOpenFromDate={setOpenFromDate}
      />
      {/* <ToDate
        sendData={handleToDateData}
        openToDate={openToDate}
        setOpenToDate={setOpenToDate}
      /> */}
    </>
  );
};

export default FilterMenu;
