import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AddSubCategoryModal({
  subCategoryName,
  setsubCategoryName,
  handleSubCategoryClose,
  saveSubCategory,
  openSubCategoryModal,
}) {
  return (
    <Modal
      open={openSubCategoryModal}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Add Sub Category
        </Typography>
        <div id="modal-modal-description" className="mt-4" sx={{ mt: 2 }}>
          <label className="text-sm">Sub Category name</label>
          <input
            type="text"
            name="category"
            id="category"
            className="w-full h-10 border-gray-500 border rounded-md p-1"
            value={subCategoryName}
            onChange={(e) => setsubCategoryName(e.target.value)}
          />
        </div>
        <div
          id="modal-modal-footer"
          sx={{ mt: 2 }}
          className="flex justify-end items-center mt-4"
        >
          <button
            className=" p-2 cursor-pointer"
            onClick={handleSubCategoryClose}
          >
            Cancel
          </button>
          <button
            className="bg-[#38AB7C] text-white p-2 cursor-pointer rounded-md"
            onClick={saveSubCategory}
          >
            Save
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default AddSubCategoryModal;
