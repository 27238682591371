import React from "react";
import Dialog from "@mui/material/Dialog";
import DeleteImage from "../../../assets/icons/delete-modal-pic.svg";
import Cross from "../../../assets/icons/cross.svg";
import "./CommonStylingForDeleteDialog.scss";
import { useDispatch } from "react-redux";
import { deleteBusinessAsync } from "../../../redux/usersSlice";
const SearchBusinessAccDialog = ({
  searchBussAccountDeleteData,
  setSearchBussAccountDeleteData,
  searchBusAccountHandleClose,
  SelectedBusinessEmail,
}) => {
  const dispatch = useDispatch();
  //--------------------------
  const DeletedSucessfully = (e) => {
    e.preventDefault();
    setSearchBussAccountDeleteData(false);
    console.log(SelectedBusinessEmail.current);
    dispatch(deleteBusinessAsync(SelectedBusinessEmail.current));
    // console.log('SelectedBusinessEmail.current', SelectedBusinessEmail.current)
  };
  return (
    <Dialog open={searchBussAccountDeleteData}>
      <div className="DeleteContainer">
        <img
          onClick={searchBusAccountHandleClose}
          className="Cancelicon"
          src={Cross}
          alt="Cancel"
        />
        <img className="DeleteImage" src={DeleteImage} alt="Delete User" />
        <p>Are you sure you want to delete this admin?</p>
        <div className="CancelAndDeleteBTN">
          <h5 onClick={() => setSearchBussAccountDeleteData(false)}>
            No, Cancel
          </h5>
          <button>
            <h4 onClick={DeletedSucessfully}>Yes Delete</h4>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default SearchBusinessAccDialog;
