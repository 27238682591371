// // Development
export const ApiConnection = {
  //Development
  // BACKEND_URL: "https://dev-global-marketplace.azurewebsites.net/",
  // Stage---
  BACKEND_URL: "https://stag-global-marketplace.azurewebsites.net/",
  //  Rajesh Backend
  // BACKEND_URL: "http://192.168.1.9:5000/",
  //  Piyush
  // BACKEND_URL: "http://192.168.1.12:5000/",
  // BACKEND_URL: "http://192.168.1.7:5000/",
};