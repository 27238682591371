import React from "react";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import LoginHistoryIcon from "../../../assets/icons/loginHistory.svg";
import UnloginHistory from "../../../assets/icons/UnloginHistory.svg";
import SendOTPIcon from "../../../assets/icons/SendOTPIcon.svg";
import ChangePasswordIcon from "../../../assets/icons/changePasswordIcon.svg";
import DeleteIcon from "../../../assets/icons/DeleteIcon.svg";
import TurnOn from "../../../assets/icons/TurnOn.svg";
import TurnOFF from "../../../assets/icons/TurnOFF.svg";
import ChangeEmailIcon from "../../../assets/icons/ChangeEmailIcon.svg";
import "../MenuDialog/DialogStyle.scss";

function MenuDialog(props) {
  const {
    open,
    onClose,
    selectedValue,
    Generateotp,
    ChangePass,
    Deleted,
    item,
    LoginHis,
    setShowMadmin,
    Unsuccessfull,
    setOpen,
    changeEmail,
    ChangeUserId
  } = props;

  const { userDetails } = useSelector((state) => state.auth);

  const lists = [
    { name: "Login History", fn: LoginHis, icon: LoginHistoryIcon },
    { name: "Unsuccessfully Login History", fn: Unsuccessfull, icon: UnloginHistory },
    { name: "Change Password", fn: ChangePass, icon: ChangePasswordIcon },
    { name: "Send OTP", fn: Generateotp, icon: SendOTPIcon },
    { name: "Delete", fn: Deleted, icon: DeleteIcon },
  ];

  const lists2 = [
    { name: "Login History", fn: LoginHis, icon: LoginHistoryIcon },
    { name: "Unsuccessfully Login History", fn: Unsuccessfull, icon: UnloginHistory },
    { name: "Change Password", fn: ChangePass, icon: ChangePasswordIcon },
    { name: "Change Email", fn: changeEmail, icon: ChangeEmailIcon },
    { name: "Change UserID", fn: ChangeUserId, icon: ChangeEmailIcon },
    { name: "Send OTP", fn: Generateotp, icon: SendOTPIcon },
  ];

  const list3 = { name: "ON", fn: setShowMadmin, icon: TurnOn };
  const list4 = { name: "OFF", fn: setShowMadmin, icon: TurnOFF };
  const list5 = {
    name: "You don't have permission to perform this action.",
    fn: null,
    icon: null,
  };

  const loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

  let isAdd =
    item?.accountType?.toLowerCase() === "masteradmin" &&
    loggesInUser?.isPrimaryAccount &&
    !item?.isPrimaryAccount;

  let isShow =
    item?.accountType?.toLowerCase() === "masteradmin" &&
    !loggesInUser?.isPrimaryAccount &&
    item?.isPrimaryAccount;

  if (isAdd) {
    if (loggesInUser?.isShowMasterAdminAcc) {
      lists2.unshift(list4);
    } else {
      lists2.unshift(list3);
    }
  } else if (isShow) {
    lists2.unshift(list5);
    lists2.splice(1);
  }

  const { selectedId } = useSelector((state) => state.adminManage);

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <Dialog sx={{ borderRadius: "6px" }} onClose={handleClose} open={open}>
        <div className="DialogBox">
          <h6 className={item?.isLoggedIn ? "UserIdActive" : "UserId"}>UserId: {selectedId}</h6>
          {item?.accountType?.toLowerCase() === "admin" ? (
            <>
              {lists.map((maplists, i) => (
                <div
                  className="DialogItem btn-5"
                  onClick={() => {
                    setOpen(false);
                   maplists.fn();
                  }}
                  key={i}
                >
                  {maplists.icon && <img src={maplists.icon} alt={maplists.name} />}
                  <p className="ListName">{maplists.name}</p>
                </div>
              ))}
            </>
          ) : (
            <>
              {lists2.map((maplists, i) => (
                <div
                  className="DialogItem btn-5"
                  onClick={() => {
                    setOpen(false);
                    !isShow && maplists.fn(item);
                  }}
                  key={i}
                >
                  {maplists.icon && <img src={maplists.icon} alt={maplists.name} />}
                  <p className="ListName">{maplists.name}</p>
                </div>
              ))}
            </>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default MenuDialog;
