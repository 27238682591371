import React from "react";
import Header from "../Header/Header";
import "./Container.scss";
function Container(props) {
  const url = window.location.href;
  const segments = url.split("/");
  const lastSegment = segments[segments.length - 1];
  return (
    <div className="h-screen w-full flex overflow-hidden MainComponent">
      <div className="whole-container h-full w-full">
        <Header HideImport={props.Comp} />
        {props.children}
      </div>
    </div>
  );
}

export default Container;
