import React, { useState } from "react";
import "./ZipcodeBy.scss";
import Dialog from "@mui/material/Dialog";
import FormControl from '@mui/material/FormControl';
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
const ZipCode = ({ openZip, setOpenZip, sendData }) => {
  const citys = [
    '10065',
    '10021',
    '33480',
    '94027',
    '90210',
    '90402',
    '94970',
    '94301',
    '92661',
    '11962',
    '93108'
  ];
  const [selectedZipCode, setSelectedZipCode] = useState([]);
  const handleLocationChange = (e) => {

    const { value, checked } = e.target;
    if (checked) {
      setSelectedZipCode([...selectedZipCode, value]);
    } else {
      setSelectedZipCode(selectedZipCode.filter((location) => location !== value));
    }
  };

  const handleClick = () => {
    setOpenZip(false);
    sendData(selectedZipCode.slice(1, selectedZipCode.length));
    setSelectedZipCode([null])
  };
  return (
    <Dialog

      PaperProps={{
        sx: {
          position: "fixed", right: 10, m: 0, top: '30%'
        }
      }}
      open={openZip} onClose={handleClick}>
      <div className="InputDiv">
        <img src={SearchIcon} alt="Search" />
        <input type="text" placeholder="Search" />
      </div>
      <div className="ZipContainer">
        <FormControl>
          {
            citys.map((name, i) => (
              <div key={i} className="ZipCodeName">
                <input
                  type="checkbox"
                  value={name}
                  checked={selectedZipCode.includes(name)}
                  onChange={handleLocationChange}
                />
                <p> {name}</p>
              </div>
            ))
          }

        </FormControl>
      </div>
    </Dialog>
  );
};

export default ZipCode;
