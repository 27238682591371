import React, { useEffect, useState } from "react";
import "./Docs.scss";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Dialog from "@mui/material/Dialog";
import document_image from "../../../../assets/icons/document_image.svg";
import { checkImage } from '../../../../components/CheckImage';
const Docs = ({ verificationData, openDialog, setOpenDialog }) => {
  // const getValidURL = async (url) => {
  //   let res = await checkImage(url)
  //   console.log('resppp', res)
  //   return res;
  // }
  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          width: "50%",
          display: "flex",
          justifyContent: "center",
          zIndex: 999999,
        },
      }}
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      {/* <span className="userId">UserId : {UserId}</span> */}
      {verificationData?.length > 1 ? (
        <div className="Docs_image_container">
          <Slide autoplay={false} infinite={false} transitionDuration={500}>
            <div className="each-slide-effect">
              <img
                onClick={() => window.open("https://" + verificationData[0])}
                src={
                  verificationData !== null && verificationData[0] ? "https://" + verificationData[0] : document_image
                }
                alt=""
              />
              {verificationData[0] ? <p>Id</p> : <p>Not Uploaded</p>}
            </div>
            <div className="each-slide-effect">
              <img
                onClick={() =>
                  window.open("https://" + verificationData[1], "_blank")
                }
                src={verificationData !== null && verificationData[1] ? "https://" + verificationData[1] : document_image}
                alt=""
              />
              {/* <LazyLoadImage src={verificationData !== null && verificationData[1] ? "https://" + verificationData[1] : document_image}

                alt="Image Alt"
              /> */}
              {verificationData[1] ? (
                <p>Utility bill 1 </p>
              ) : (
                <p>Not Uploaded</p>
              )}
            </div>
            <div className="each-slide-effect">
              <img
                onClick={() =>
                  window.open("https://" + verificationData[2], "_blank")
                }
                src={verificationData !== null && verificationData[2] ? "https://" + verificationData[2] : document_image}
                alt=""
              />
              {/* <LazyLoadImage src={verificationData !== null && verificationData[2] ? "https://" + verificationData[2] : document_image}

                alt="Image Alt"
              /> */}
              {verificationData[2] ? (
                <p>Utility bill 2 </p>
              ) : (
                <p>Not Uploaded</p>
              )}
            </div>
          </Slide>
        </div>
      ) : null}
    </Dialog>

    // </div>
  );
};

export default Docs;
