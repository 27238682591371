import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Banking.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import Container from "../../../components/HOC/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBankingDataAsync,
  getAllBankingStatics,
} from "../../../redux/staticsSlice";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import Loader from "../../../components/Loader";
import moment from "moment";
import Switch from "react-switch";
import calenderIcon from "../../../assets/icons/calender.svg";
import FromDate from "../../../components/Filters/FromDateBy/FromDate";
import { SelectedFilterFromDate } from "../../../redux/usersSlice";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Card, Col, Dropdown, Row, Space, Statistic } from "antd";
function BankingInfo() {
  const dispatch = useDispatch();

  const {
    getBankingData,
    getBankingDataLoading,
    totalAdminPageBanking,
    AdminPaginationDataLengthBanking,
    adminStartedRecordsBanking,
    adminToRecordsBanking,
    allAdminDataBanking,
    getBankingStaticsLoading,
    getBankingStatics,
  } = useSelector((state) => state.statics);
  const { selectedFromDateForFilter } = useSelector((state) => state.users);

  // Searching functionality
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const classes = useStyles();
  const [alignment, setAlignment] = useState(false);
  const [openFromDate, setOpenFromDate] = useState(false);
  const inputRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState("Filter");
  const data = {
    title: searchTerm,
    page: page,
    perPage: Constants.TAKE,
    type: alignment ? "Subscription" : "",
    month: "",
  };
  const staticsData = {
    month: "",
  };
  useEffect(() => {
    dispatch(getAllBankingDataAsync(data));
    dispatch(SelectedFilterFromDate(""));
    dispatch(getAllBankingStatics(staticsData));
  }, [alignment]);
  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getAllBankingDataAsync(data));
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Backspace") {
      dispatch(SelectedFilterFromDate(""));
    }
  };

  const handleToggle = () => {
    setAlignment(!alignment);
  };
  // const filteredTransactions = getBankingData?.filter((transaction) =>
  //   transaction?.projectTitle?.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  // const SearchQuery = (e) => {
  //   setSearchTerm(e.target.value);
  // };
  // const getDataByQuery = () => {
  //   dispatch(getAllBankingDataAsync(data));
  // };
  const Charge = (data) => {
    // Remove dollar sign and any commas, then parse as a float
    let numericValue = parseFloat(data.replace(/[$,]/g, ""));
    let per = (numericValue * 10) / 100;
    return per;
  };
  const formattedTitle = (title) => {
    return (
      <span style={{ color: "#26405e", fontSize: "14px", fontWeight: "500" }}>
        {title.replace(/_/g, " ")}
      </span>
    );
  };

  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));

    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD/MM/YYYY");
    }
  };

  const RefreshData = () => {
    dispatch(getAllBankingDataAsync(data));
    dispatch(SelectedFilterFromDate(""));
    dispatch(getAllBankingStatics(staticsData));
  };
  const GetDataByMonth = (month) => {
    if (month === "all") {
      RefreshData();
    } else {
      const updatedData = {
        ...data,
        month: month,
      };
      const updatedStaticsData = {
        ...staticsData,
        month: month,
      };
      dispatch(getAllBankingDataAsync(updatedData));
      dispatch(getAllBankingStatics(updatedStaticsData));
    }
  };
  const currentMonth = moment();
  const previousMonth = moment().subtract(1, "month");

  const months = [
    {
      name: currentMonth.format("MMMM"),
      value: currentMonth.format("MM/YYYY"),
      key: "0",
    },
    {
      name: previousMonth.format("MMMM"),
      value: previousMonth.format("MM/YYYY"),
      key: "1",
    },
  ];
  const SelectAllmonths = () => {
    setOpenFromDate(true);
    handleSelect("By month");
  };
  const items = [
    {
      label: (
        <p className="months" onClick={() => handleSelect("all")}>
          All
        </p>
      ),
      key: "all",
    },
    ...months.map((month) => ({
      label: (
        <p className="months" onClick={() => handleSelect(month.value)}>
          {month.name}
        </p>
      ),
      key: month.key,
    })),
    {
      label: (
        <p className="months" onClick={SelectAllmonths}>
          Select
        </p>
      ),
      key: "2",
    },
  ];

  const handleSelect = (value) => {
    if (value === "all") {
      setSelectedFilter("Filter");
    } else if (value === "By month") {
      setSelectedFilter("By month");
    } else {
      const month = months.find((m) => m.value === value);
      setSelectedFilter(month ? month.name : "Filter");
    }
    GetDataByMonth(value);
  };

  return (
    <Container>
      {getBankingDataLoading ? <Loader /> : null}
      <div className="transaction-history-container">
        <div className="transaction-history-header">
          <h2>Transaction History</h2>
          <div className="Top_item">
            <div className="search-bar">
              {getBankingStatics?.map((prices) => (
                <Card style={{ width: 220, background: "#ebfaf4" }}>
                  <Statistic
                    precision={0.5}
                    title={formattedTitle(prices?.title)}
                    value={prices?.total}
                    valueStyle={{
                      color: "#3f8600",
                    }}
                    prefix={<ArrowUpOutlined />}
                    suffix="$"
                  />
                </Card>
              ))}
              <div className="filterContainer">
                <Switch
                  checked={alignment}
                  onChange={handleToggle}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={22}
                  width={47}
                />
                <p>Subscription</p>
              </div>
              <div className="searchContainer">
                <div className="FilterBox">
                  <div className="Title_month">
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <h2 style={{ color: "#fff" }}>{selectedFilter}</h2>
                          <DownOutlined
                            style={{
                              color: "#fff",
                              fontSize: "0.8rem",
                              marginTop: "0.2rem",
                              marginRight: "0.2rem",
                            }}
                          />
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openFromDate && (
          <FromDate
            openFromDate={openFromDate}
            setOpenFromDate={setOpenFromDate}
            BankingData={data}
          />
        )}
        <TableContainer sx={{ maxHeight: "95%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow className={classes.root}>
                <TableCell>
                  <p className="Header_banking">Transaction ID</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking">Payment Date</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking"> Project Id </p>
                </TableCell>
                <TableCell>
                  <p className="Header_banking">Project/Subcription</p>
                </TableCell>
                <TableCell>
                  <p className="Header_banking">Business User</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking">Category</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking">Sub-category</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking">Location</p>
                </TableCell>

                <TableCell>
                  <p className="Header_banking">Payment mode</p>
                </TableCell>
                <TableCell>
                  <p className="Header_banking">Platform Charge (10%)</p>
                </TableCell>
                <TableCell>
                  <p className="Header_banking">Total Amount</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAdminDataBanking?.map((item, i) => {
                return (
                  <TableRow
                    className={
                      item?.paymentType === "add_subscription" ||
                      item?.intent === "SUBSCRIPTION"
                        ? classes.SubscriptionSell
                        : classes.bodyroot
                    }
                    hover
                    role="checkbox"
                    key={i}
                  >
                    <TableCell className={classes.customTableCell}>
                      {item?.payment_Id || item?.paymentId
                        ? `# ${item?.payment_Id || item?.paymentId}`
                        : `#Not added`}
                    </TableCell>
                    <TableCell className={classes.customTableCell}>
                      {formatDate(item?.createdAt * 1000)}
                    </TableCell>
                    {item?.intent === "sale" ||
                    item?.paymentType === "sale" ||
                    item?.PaymentMode === "paypal" ? (
                      <TableCell className={classes.customTableCell}>
                        {item?.project?.project_Id}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.customTableCell}>
                        NA
                      </TableCell>
                    )}
                    <TableCell className={classes.customTableCell}>
                      {item?.intent === "sale" || item?.paymentType === "sale"
                        ? item?.projectTitle || item?.project?.projectTitle
                        : "SUBSCRIPTION"}
                    </TableCell>
                    <TableCell className={classes.customTableCell}>
                      {item?.businessUserTbl?.firstName + ' ' + item?.businessUserTbl?.lastName}
                    </TableCell>
                    {/* {item?.intent === "sale" || item?.paymentType === "sale" ? (
                      <TableCell className={classes.customTableCell}>
                        {item?.businessUserTbl?.fullName}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.customTableCell}>
                        NA
                      </TableCell>
                    )} */}
                    {item?.intent === "sale" ||
                    item?.paymentType === "sale" ||
                    item?.PaymentMode === "paypal" ? (
                      <TableCell className={classes.customTableCell}>
                        {item?.project?.category?.name}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.customTableCell}>
                        NA
                      </TableCell>
                    )}
                    {item?.intent === "sale" ||
                    item?.paymentType === "sale" ||
                    item?.PaymentMode === "paypal" ? (
                      <TableCell className={classes.customTableCell}>
                        {item?.project?.subCategoryTbl?.name}
                      </TableCell>
                    ) : (
                      <TableCell className={classes.customTableCell}>
                        NA
                      </TableCell>
                    )}

                    <TableCell className={classes.customTableCell}>
                      {item?.businessUserTbl?.location?.length > 0 ? (
                        item?.businessUserTbl?.location?.map(
                          (locationItem, index) => (
                            <span
                              style={{ fontSize: "0.7rem" }}
                              key={index}
                              className={classes.locationItem}
                            >
                              {locationItem}
                              {index <
                                item?.businessUserTbl?.location?.length - 1 &&
                                " , "}
                            </span>
                          )
                        )
                      ) : (
                        <span>No locations available</span>
                      )}
                    </TableCell>
                    <TableCell className={classes.customTableCell}>
                      {item?.paymentGateway === "Merchant Payment Gateway"
                        ? "Card"
                        : "Paypal"}
                    </TableCell>
                    <TableCell className={classes.customTableCell}>
                      {item?.totalAmount
                        ? `$${Charge(`$${item?.totalAmount}`).toFixed(2)}`
                        : item?.Amount && item?.projectId
                        ? `$${Charge(`$${item?.Amount}`).toFixed(2)}`
                        : "$0.00"}
                    </TableCell>

                    <TableCell className={classes.customTableCell}>
                      $
                      {item?.totalAmount
                        ? item?.totalAmount
                        : item?.Amount
                        ? item?.Amount
                        : "90"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {AdminPaginationDataLengthBanking > Constants.TAKE ? (
            <Footer
              page={page}
              handleChange={handleChange}
              totalRecord={AdminPaginationDataLengthBanking}
              startedRecords={adminStartedRecordsBanking}
              toRecords={adminToRecordsBanking}
              totalPages={totalAdminPageBanking}
            />
          ) : null}
        </TableContainer>
      </div>
    </Container>
  );
}

export default BankingInfo;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.6rem 1rem",
    },
  },
  customTableCell: {
    color: "#616B7A !important",
    fontSize: "0.75rem !important",
    fontFamily: "roboto !important",
    // display: "flex",
    gap: "8px",
  },
  SubscriptionSell: {
    backgroundColor: "#b6dfca !important",
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      padding: "0.6rem 1rem",
    },
  },
});
