import React, { useEffect, useState } from "react";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import "./EditUser.scss";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";
import Arrow from "../../../assets/icons/arrow-left.svg";
import Cross from "../../../assets/icons/cross-blue.svg";
import Save from "../../../assets/icons/save.svg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocationAsync } from "../../../redux/announcementsSlice";
import document_image from "../../../assets/icons/document_image.svg";
import avatar from "../../../assets/icons/avatar.svg";
import { UpdateProfileAsync } from "../../../redux/usersSlice";
import Loader from "../../../components/Loader";
import {
  getCategoryAsync,
  getSubCategoryAsync,
} from "../../../redux/categorySlice";
import Docs from "../../../components/Dialogs/ViewFiles/Docs/Docs";
import ImageSlider from "../../../components/Dialogs/ViewFiles/Docs/ImageSlider";
import Swal from "sweetalert2";
const EditUser = () => {
  const navigate = useNavigate();
  const { userDetails, updateProfileLoading } = useSelector(
    (state) => state.users
  );
  const [citiState, setCitiState] = useState(false);
  const [selectedCities, setSelectedCities] = useState([]);
  const [rating, setRating] = useState(0);
  const [openCitiesModal, setOpenCitiesModal] = useState(false);
  const [finalLocation, setFinalLocation] = useState("");
  const [selectedDocs1, setSelectedDocs1] = useState([]);
  const [selectedDocs2, setSelectedDocs2] = useState([]);
  const [selectedDocs3, setSelectedDocs3] = useState([]);
  const [file, setFile] = useState();
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImage1, setSelectedImage1] = useState("");
  const [selectedImage2, setSelectedImage2] = useState("");
  const [selectedImage3, setSelectedImage3] = useState("");
  const [serviceCategoryId, setServiceCategoryId] = useState("");
  const [serviceSubCategoryId, setServiceSubCategoryId] = useState("");
  const [verificationData, setVerificationData] = useState([]);
  const [openVerification_link, setOpenVerification_link] = useState([]);
  const [completedJobs, setCompletedJobs] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const { AllCitiesData, AllCitesLoading } = useSelector(
    (state) => state.announcement
  );
  const { categoryData, subCategoryData } = useSelector(
    (state) => state.category
  );
  useEffect(() => {
    setCompletedJobs(userDetails?.No_Of_Completed_Job);
    setRating(userDetails?.Avg_Rating);
    dispatch(getCategoryAsync(true));
  }, [dispatch]);

  const changeRating = (newRating) => {
    setRating(newRating);
  };
  const getSeacrhData = (item) => {
    const categoryId = item.id;
    setServiceCategoryId(categoryId);
    dispatch(getSubCategoryAsync("", categoryId));
  };
  const SubCategoryId = (item) => {
    setServiceSubCategoryId(item.id);
  };

  const GetAllLocation = () => {
    setCitiState(true);
    dispatch(getAllLocationAsync());
  };
  const GetingLocation = (item) => {
    setCitiState(false);
    const citiesToRemove = item?.usCanadaCitiesMasterTbl.filter((cityObj) => {
      const cityName = cityObj.city;
      return (
        cityName.substring(0, 2).toUpperCase() !== cityName.substring(0, 2)
      );
    });

    setSelectedCities(citiesToRemove);
    setOpenCitiesModal(true);
  };
  const LoactionData = (item) => {
    setFinalLocation(item);
    setOpenCitiesModal(false);
  };
  // making first letter capital
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const ChangeJobs = (e) => {
    setCompletedJobs(e.target.value);
  };
  const SelectImage = (e) => {
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const SaveData = () => {
    const formData = new FormData();
    formData.append(`files`, file);
    const selectedDocsArray = [];
    if (selectedDocs1) {
      selectedDocsArray.push(selectedDocs1);
    }
    if (selectedDocs2) {
      selectedDocsArray.push(selectedDocs2);
    }
    if (selectedDocs3) {
      selectedDocsArray.push(selectedDocs3);
    }
    selectedDocsArray.forEach((doc) => {
      formData.append("files", doc);
    });
    formData.append(
      "metadata",
      JSON.stringify({
        userId: userDetails?.userId,
        locationArr: finalLocation?.city
          ? [finalLocation?.city]
          : userDetails?.location,
        accountType: userDetails?.accountType,
        isCompleted: 1,
        serviceCategoryId: serviceCategoryId,
        serviceSubCategoryId: serviceSubCategoryId,
        password: userDetails?.password,
        No_Of_Completed_Job: completedJobs,
        Avg_Rating: rating,
      })
    );

    dispatch(UpdateProfileAsync(formData, navigate));
    setRating(0);
    // if (visible == false) {
    //   Swal.fire({
    //     icon: "error",
    //     text: "All fields are required",
    //   });
    // } else {
    //   dispatch(UpdateProfileAsync(formData, navigate));
    // }
    // };
  };

  //----------

  const ShowtempAlert = () => {
    Swal.fire(
      "In a temporary account, there is no provision for updating documents."
    );
  };

  const SetImages = (items) => {
    setVerificationData(items?.ShowDocsUrl);
    setOpenVerification_link(items?.DownloadDocsUrlUrl);
    setOpenDialog(true);
  };

  // Doc 1 broken image functionality
  const FailedToProfile = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  // Doc 1 broken image functionality
  const FailedToLoad1 = (e) => {
    console.log("not opened");
    e.target.src = document_image;
  };

  // Doc 2 broken image functionality
  const FailedToLoad2 = (e) => {
    console.log("not opened");
    e.target.src = document_image;
  };

  // Doc 3 broken image functionality
  const FailedToLoad3 = (e) => {
    console.log("not opened");
    e.target.src = document_image;
  };
  return (
    <ContainerWithoutFooter>
      {updateProfileLoading || AllCitesLoading ? <Loader /> : null}
      <div className="EditUserMainContainer">
        <div className="TopContainerEditUser">
          <div className=" TopContainerEditUserButtons">
            <button className=" mr-3" onClick={() => navigate(-1)}>
              <img src={Arrow} alt="Arrow" />
            </button>

            {userDetails?.accountType === "customer" ? (
              <>
                {userDetails?.tempAccount === true ? (
                  <h1>Temp. Customer Account</h1>
                ) : userDetails?.isAdminApproved === true ? (
                  <h1>Search Customer Account</h1>
                ) : (
                  <h1>New Customer Account Verification</h1>
                )}
              </>
            ) : (
              <>
                {userDetails?.demo === true ? (
                  <h1>Temp. Business Account</h1>
                ) : userDetails?.isAdminApproved === true ? (
                  <h1>Search Business Account</h1>
                ) : (
                  <h1>New Business Account Verification</h1>
                )}
              </>
            )}
          </div>

          <div className=" RightButtons_EditUser">
            <button
              className="cancelBtn_EditUser "
              onClick={() => navigate(-1)}
            >
              <img src={Cross} alt="Cross" />
              <span className="text">Cancel</span>
            </button>

            <button
              className="saveBtn_EditUser "
              onClick={SaveData}
              // disabled={signUpLoading}
            >
              <img src={Save} alt="Save" />
              <span className="text">Save</span>
            </button>
          </div>
        </div>
        <div className="BottomContainerEditUser">
          <div className="EditUserDetailContainer">
            <div className="upload-photo-view_edit_User">
              {selectedImage === "" ? (
                <img
                  src={"https://" + userDetails?.image}
                  onError={FailedToProfile}
                  alt="upload "
                />
              ) : (
                <img src={selectedImage} alt="upload " />
              )}
              <div className="BTN_And_Condition_Edit">
                <div className=" UploadButtonContainer_Edit_User ">
                  <label htmlFor="fileInput" className="fileInputButton">
                    Choose File
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={SelectImage}
                    style={{ display: "none" }}
                  />
                  <button className="reset-btn ">
                    <span className="reset-text">Reset</span>
                  </button>
                </div>
                <p className="allowed-pic-details">
                  Allowed JPG, GIF or PNG. Max size of 800K
                </p>
              </div>
            </div>
            <div className="UserId_and_Location_Edit">
              <TextField
                className="textfield"
                id="component-disabled"
                label="User Id"
                htmlFor="component-disabled"
                defaultValue={userDetails?.userId}
                disabled
              />

              <TextField
                className="textfieldValid"
                id="outlined-required"
                placeholder="Location"
                onClick={GetAllLocation}
                disabled
                value={
                  finalLocation?.city
                    ? capitalizeFirstLetter(finalLocation.city)
                    : userDetails?.location
                }
              />
              <Dialog
                PaperProps={{
                  sx: {
                    position: "fixed",
                    m: 0,
                    right: "center",
                    width: "50%",
                    height: "90%",
                    backgroundColor: "#fff",
                  },
                }}
                open={citiState}
                onClose={() => setCitiState(false)}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ display: "flex", margin: "0 auto" }}>
                    Select State
                  </p>
                  {AllCitiesData?.map((item, key) => (
                    <MenuItem
                      defaultValue=""
                      onClickCapture={() => GetingLocation(item)}
                    >
                      <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                      <span className="StateAndCityNAme">
                        {item?.shortNameOfState}
                      </span>
                    </MenuItem>
                  ))}
                </div>
              </Dialog>

              <Dialog
                PaperProps={{
                  sx: {
                    position: "fixed",
                    m: 0,
                    right: "center",
                    width: "50%",
                    height: "90%",
                    backgroundColor: "#fff",
                  },
                }}
                open={openCitiesModal}
                onClose={() => setOpenCitiesModal(false)}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p style={{ display: "flex", margin: "0 auto" }}>
                    Select City
                  </p>

                  {/* <span className='StateAndCityNAme'>
                                {selectedCities.city}
                            </span> */}

                  {selectedCities?.map((item, key) => (
                    <MenuItem
                      key={item.city}
                      value={
                        item?.city.charAt(0).toUpperCase() + item?.city.slice(1)
                      }
                      defaultValue=""
                      onClickCapture={() => LoactionData(item)}
                    >
                      <p style={{ padding: "0.5rem 1rem" }}>{key + 1}.</p>
                      <span className="StateAndCityNAme">
                        {item.city.charAt(0).toUpperCase() +
                          item?.city.slice(1)}
                      </span>
                    </MenuItem>
                  ))}
                </div>
              </Dialog>
            </div>
            {userDetails?.accountType === "business" ? (
              <div className="Common_details_Edit">
                <TextField
                  className="Inputs"
                  id="outlined-select-currency"
                  required
                  select
                  label={userDetails?.category?.name}
                >
                  {categoryData.map((item) => (
                    <MenuItem
                      onClick={() => getSeacrhData(item)}
                      key={item?.name}
                      value={item?.name}
                      defaultValue=""
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  className="Inputs"
                  id="outlined-select-currency"
                  required
                  select
                  label={userDetails?.subCategory?.name}
                >
                  {subCategoryData.map((subItem, key) => (
                    <MenuItem
                      onClick={() => SubCategoryId(subItem)}
                      key={subItem?.name}
                      value={subItem?.name}
                      defaultValue=""
                    >
                      {subItem.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            ) : null}

            <div className="UserId_and_Location_Edit">
              <TextField
                className="textfield"
                id="outlined-required"
                label="Email address"
                defaultValue={userDetails?.emailAddress}
                disabled
                style={{ cursor: "no-drop" }}
              />
              <TextField
                className="textfield"
                id="outlined-required"
                label="State"
                defaultValue={userDetails?.state}
                disabled
              />
            </div>
            <div className="UserId_and_Location_Edit">
              <TextField
                className="textfield"
                id="outlined-required"
                label="Completed Jobs"
                type="number"
                onChange={ChangeJobs}
                value={completedJobs}
                InputProps={{ inputProps: { min: 0 } }}
                InputLabelProps={{ shrink: true }}
                sx={{
                  "& input[type=number]": {
                    "-moz-appearance": "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                }}
              />
              <div className="EditRatingContainer">
                <span>Select Rating</span>
                <StarRatings
                  rating={rating}
                  starRatedColor="green"
                  starEmptyColor="grey"
                  starDimension="1.3rem"
                  changeRating={changeRating}
                  numberOfStars={5}
                  name="rating"
                />
              </div>
            </div>
            <>
              <span className="Videos_files">Videos/Pics Files</span>
              <div className="ImageDialogContainerEdit">
                <div
                  onClick={() =>
                    userDetails?.tempAccount ? ShowtempAlert() : null
                  }
                  className="CommonIdContainerEdit"
                >
                  <div className="ImageContainer">
                    {selectedImage1 === "" ||
                    userDetails?.DownloadDocsUrl[0] === "null" ? (
                      <img
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          userDetails?.DownloadDocsUrl[0] === "null"
                            ? SetImages(userDetails)
                            : null
                        }
                        src={
                          userDetails?.DownloadDocsUrl &&
                          userDetails?.DownloadDocsUrl[0] !== "null"
                            ? "https://" + userDetails?.DownloadDocsUrl[0]
                            : document_image
                        }
                        onError={FailedToLoad1}
                        alt="upload "
                      />
                    ) : (
                      <img src={selectedImage1} alt="upload " />
                    )}
                  </div>
                  <div className="UploadButtonContainer_Edit_User">
                    {userDetails?.DownloadDocsUrl &&
                    userDetails?.DownloadDocsUrl[0] !== "null" ? (
                      <p className="Title_image">Id</p>
                    ) : (
                      <p className="Title_image">No ID uploaded</p>
                    )}
                  </div>
                  {userDetails?.DownloadDocsUrl &&
                  userDetails?.DownloadDocsUrl[0] !== "null" ? (
                    <div
                      onClick={() =>
                        userDetails?.DownloadDocsUrl &&
                        userDetails?.DownloadDocsUrl[0] !== "null"
                          ? window.open(
                              "https://" + userDetails?.DownloadDocsUrl[0],
                              "_blank"
                            )
                          : null
                      }
                      className="common_button"
                    >
                      Download
                    </div>
                  ) : (
                    <div className="common_button">Not uploaded</div>
                  )}
                </div>
                <div
                  onClick={() =>
                    userDetails?.tempAccount ? ShowtempAlert() : null
                  }
                  className="CommonIdContainerEdit"
                >
                  <div className="ImageContainer">
                    {selectedImage2 === "" ||
                    (userDetails?.DownloadDocsUrl &&
                      userDetails?.DownloadDocsUrl[1] !== "null") ? (
                      <img
                        onClick={() =>
                          userDetails?.DownloadDocsUrl &&
                          userDetails?.DownloadDocsUrl[1] !== "null"
                            ? SetImages(userDetails)
                            : null
                        }
                        style={{ cursor: "pointer" }}
                        src={
                          userDetails?.DownloadDocsUrl &&
                          userDetails?.DownloadDocsUrl[1] !== "null"
                            ? "https://" + userDetails?.DownloadDocsUrl[1]
                            : document_image
                        }
                        onError={FailedToLoad2}
                        alt="upload "
                      />
                    ) : (
                      <img src={selectedImage2} alt="upload " />
                    )}
                  </div>
                  <div className="UploadButtonContainer_Edit_User">
                    {userDetails?.DownloadDocsUrl &&
                    userDetails?.DownloadDocsUrl[1] !== "null" ? (
                      <>
                        <p className="Title_image">Utility bill 1</p>
                      </>
                    ) : (
                      <p className="Title_image">No utility bill 1 uploaded</p>
                    )}
                  </div>
                  {userDetails?.DownloadDocsUrl &&
                  userDetails?.DownloadDocsUrl[1] !== "null" ? (
                    <div
                      onClick={() =>
                        userDetails?.DownloadDocsUrl &&
                        userDetails?.DownloadDocsUrl[1] !== "null"
                          ? window.open(
                              "https://" + userDetails?.DownloadDocsUrl[1],
                              "_blank"
                            )
                          : null
                      }
                      className="common_button"
                    >
                      Download
                    </div>
                  ) : (
                    <div className="common_button" s>
                      Not uploaded
                    </div>
                  )}
                </div>
                <div
                  onClick={() =>
                    userDetails?.tempAccount ? ShowtempAlert() : null
                  }
                  className="CommonIdContainerEdit"
                >
                  <div className="ImageContainer">
                    {selectedImage3 === "" ||
                    (userDetails?.DownloadDocsUrl &&
                      userDetails?.DownloadDocsUrl[2] !== "null") ? (
                      <img
                        onClick={() =>
                          userDetails?.DownloadDocsUrl &&
                          userDetails?.DownloadDocsUrl[2] !== "null"
                            ? SetImages(userDetails)
                            : null
                        }
                        style={{ cursor: "pointer" }}
                        src={
                          userDetails?.DownloadDocsUrl &&
                          userDetails?.DownloadDocsUrl[2] !== "null"
                            ? "https://" + userDetails?.DownloadDocsUrl[2]
                            : document_image
                        }
                        onError={FailedToLoad3}
                        alt="upload "
                      />
                    ) : (
                      <img src={selectedImage3} alt="upload " />
                    )}
                  </div>
                  <div className="UploadButtonContainer_Edit_User">
                    {userDetails?.DownloadDocsUrl &&
                    userDetails?.DownloadDocsUrl[2] !== "null" ? (
                      <>
                        <p className="Title_image">Utility bill 2</p>
                      </>
                    ) : (
                      <p className="Title_image">No utility bill 2 uploaded</p>
                    )}
                  </div>
                  {userDetails?.DownloadDocsUrl &&
                  userDetails?.DownloadDocsUrl[2] !== "null" ? (
                    <div
                      onClick={() =>
                        userDetails?.DownloadDocsUrl &&
                        userDetails?.DownloadDocsUrl[2] !== "null"
                          ? window.open(
                              "https://" + userDetails?.DownloadDocsUrl[2],
                              "_blank"
                            )
                          : window.alert("No docs Uploaded")
                      }
                      className="common_button"
                    >
                      Download
                    </div>
                  ) : (
                    <div className="common_button">Not uploaded</div>
                  )}

                  {/* <div className=" UploadButtonContainer_Edit_User ">
                        <input type="file" onChange={SelectThirdDoc} />
                      </div> */}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <ImageSlider
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        verificationData={verificationData}
        openVerification_link={openVerification_link}
      />
      {/* <Docs openDialog={openDialog} setOpenDialog={setOpenDialog} verificationData={verificationData} /> */}
    </ContainerWithoutFooter>
  );
};

export default EditUser;
