import React, { useState } from "react";
import "./Unsuccessfully.scss";
import Dialog from "@mui/material/Dialog";
import cancelOTP from "../../../assets/icons/cancelOTP.svg";
import FilterIcon from "../../../assets/icons/FilterIcon.svg";
import Eye from "../../../assets/icons/eye.svg";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useSelector } from "react-redux";
const Unsuccessfully = (props) => {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const { unsuccessfulLoginData } = useSelector((state) => state.adminManage);
  const handleCloseSelect = (reason) => {
    setOpen(false);
  };
  const { unsuccessfull, setUnsuccessfull } = props;
  const handleClose = () => {
    setUnsuccessfull(false);
  };
  const HideNSeek = (i) => {
    // console.log(i);
    setShow(!show);
  };
  const handleChangeSelect = (event) => {
    setFilter(event.target.value || "");
  };
  const formatDate = (dateString) => {
    const [date] = dateString.split(", ");
    const [month, day, year] = date.split("/");
    return `${day}/${month}/${year}`;
  };
  const formatTime = (time) => {
    const dateTime = new Date(time);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return formattedTime;
  };
  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={unsuccessfull}>
      <div className="UnsuccessfullContainer">
        <div className="topItems">
          <div className="Headings">
            <h3>Unsuccessfull login history</h3>
            <p>Recently First</p>
          </div>
          <img
            onClick={() => setUnsuccessfull(false)}
            className="Cross"
            src={cancelOTP}
            alt="cancel"
          />
        </div>
       {  unsuccessfulLoginData &&  unsuccessfulLoginData.length > 0 ? 
        <table style={{ width: "100%", marginTop: "1%" }}>
          <Dialog disableEscapeKeyDown open={open} onClose={handleCloseSelect}>
            <DialogContent>
              <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel htmlFor="demo-dialog-native">
                    Filter By
                  </InputLabel>
                  <Select
                    native
                    value={filter}
                    // onChange={handleChangeSelect}
                    input={
                      <OutlinedInput
                        label="Filter by"
                        id="demo-dialog-native"
                      />
                    }
                  >
                    <option aria-label="All" value="All" />
                    <option value="Login">Login</option>
                    <option value="Logout">Logout</option>
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSelect}>Cancel</Button>
              <Button onClick={handleCloseSelect}>Ok</Button>
            </DialogActions>
          </Dialog>
          <thead style={{ height: 50 }}>
            <tr>
              <th>
                <span className="flex item-center ml-2">
                  <p style={{ marginLeft: "1rem" }}>Ip Address</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Date</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Time</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th onClick={() => setOpen(true)}>
                <span className="flex item-center ml-2">
                  {filter === "Login" ? (
                    <p>Login</p>
                  ) : null || filter === "Logout" ? (
                    <p>Logout</p>
                  ) : null || filter === "" || null ? (
                    <p> Login/Logout</p>
                  ) : null}
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Name</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {unsuccessfulLoginData?.map((item, i) => (
              <tr key={i}>
                <td>
                  <span className="flex item-center ml-4">
                    {/* {item.IPAddress} */}
                    190.16.1
                  </span>
                </td>
                <td>
                  {" "}
                  <span className="flex item-center ml-2">
                    {formatDate(item.Date)}
                  </span>
                </td>
                <td>
                  <span className="flex item-center ml-2">
                    {formatTime(item.Date)}
                  </span>
                </td>
                <td>
                  <span className="flex item-center ml-2 text-red-700">
                    Unsuccessful
                  </span>
                </td>
                <td>
                  {" "}
                  <span className="flex item-center ml-2">{item.username}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table> : <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10%",
                    fontSize:"2rem",
                    color: "#616b7a !important",
                    fontFamily: "roboto !important",
                  }}>No data found</div> }
      </div>
    </Dialog>
  );
};

export default Unsuccessfully;
