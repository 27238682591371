import ClipLoader from "react-spinners/ClipLoader";

const override = {
  borderWidth: 3,
};

const Loader = () => {
  return (
    <div className="w-full h-full flex justify-center items-center bg-white/[0.4] absolute left-0 top-0 z-50 zIndex-10000">
      <ClipLoader
        color="#38AB7C"
        size={42}
        cssOverride={override}
        loading={true}
      />
    </div>
  );
};

export default Loader;
