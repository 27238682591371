import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import "./CommonStylingForDeleteDialog.scss";
import DeleteImage from "../../../assets/icons/delete-modal-pic.svg";
import Cross from "../../../assets/icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomerAsync } from "../../../redux/usersSlice";
import Loader from "../../../components/Loader";
import Deleted from "../../../assets/icons/Deleted.svg";

const SearchCusAccountDeleteDialog = ({
  deleteData,
  setDeleteData,
  handleClose,
  SelectedBussinessEmail,
}) => {
  const { searchCustomerAccountLoading, tempDeleteStatus } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  //-----------Deleting the Customer------

  const DeleteCustomer = (e) => {
    console.log('first', SelectedBussinessEmail)
    e.preventDefault();
    const data = {
      CustomerId: SelectedBussinessEmail?.current,
      userType: 'customer'

    }
    dispatch(deleteCustomerAsync(data)).then((r) => {
      if (r === true) {
        setDeleteData(false);
      }
    });
  };

  return (
    <Dialog open={deleteData}>
      {searchCustomerAccountLoading ? <Loader /> : null}
      <div className="DeleteContainer">
        <img
          onClick={handleClose}
          className="Cancelicon"
          src={Cross}
          alt="Cancel"
        />
        <img
          className="DeleteImage"
          src={tempDeleteStatus.status !== 200 ? DeleteImage : Deleted}
          alt="Delete User"
        />
        <p>Are you sure you want to delete this admin?</p>
        <div className="CancelAndDeleteBTN">
          <h5 onClick={() => setDeleteData(false)}>No, Cancel</h5>
          <button onClick={DeleteCustomer}>
            <h4>Yes Delete</h4>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default SearchCusAccountDeleteDialog;
