import React, { useEffect, useState, useRef } from "react";
import "./SearchCustomerAccount.scss";
import Container from "../../../components/HOC/Container";
import Vector from "../../../assets/icons/Vector.svg";
import Group_345 from "../../../assets/icons/Group_345.svg";
import SearchIcon from "@mui/icons-material/Search";
import keyboard_arrow_down from "../../../assets/icons/keyboard_arrow_down.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateStatusAsync,
  // deleteCustomerAsync,
  getSearchCustomerAsync,
  selectedUserDetails,
} from "../../../redux/usersSlice";
import Loader from "../../../components/Loader";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";
import SearchCusAccountDeleteDialog from "../../../components/Dialogs/AdminPanelActionDialog/SearchCusAccountDeleteDialog";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
//-----------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// --
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";
import Switch from "react-switch";
import TablePagination from "@mui/material/TablePagination";
import { Checkbox } from "@mui/material";
import { SelectedRoute } from "../../../redux/adminManagementSlice";
function SearchCustomerAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const SelectedBussinessEmail = useRef("");
  const [page, setPage] = useState(1);
  const classes = useStyles();
  const {
    searchCustomerAccountData,
    searchCustomerAccountLoading,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    updateProfileLoading,
    newCustomerAccVerificationLoading,
  } = useSelector((state) => state.users);
  //----- Filters
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("Username");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Search by");

  const handleSelection = (value) => {
    setFilter(value);
    setSelectedValue(value);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  const handleClickOpen1 = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleChangeSelect = (event) => {
    setFilter(event.target.value || "");
  };
  console.log("searchCustomerAccountData", searchCustomerAccountData);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCloseSelect = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  //-----
  const data = {
    name,
    location,
    email,
    address,
    isAdminApproved: 1,
    temp: 0,
    page: 1,
    perPage: Constants.TAKE,
  };
  const handleClose = () => {
    setDeleteData(false);
  };
  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getSearchCustomerAsync(data));
  };
  useEffect(() => {
    dispatch(getSearchCustomerAsync(data));
    // if (searchCustomerAccountData?.length <= 0) {
    //   dispatch(getSearchCustomerAsync(data, Constants.TAKE, 0, 1));
    //   console.log('first call in temp ')
    // } else (
    //   console.log('Loaded')
    // )
  }, [dispatch]);

  // Delete User---
  // const DeleteItem = (item) => {
  //   // console.log("first", item.emailAddress);
  //   setDeleteData(true);
  //   SelectedBussinessEmail.current = item?.id;
  // };
  const SearchCustomerByQuery = (e) => {
    const filterToStateMap = {
      Username: setName,
      Email: setEmail,
      Location: setLocation,
      Address: setAddress,
    };

    const setStateFunction = filterToStateMap[filter];
    if (setStateFunction) {
      setStateFunction(e.target.value);
      // Reset other state values
      for (const stateSetter of Object.values(filterToStateMap)) {
        if (stateSetter !== setStateFunction) {
          stateSetter(undefined);
        }
      }
    }
  };

  const getDatabyQuery = () => {
    dispatch(getSearchCustomerAsync(data, Constants.TAKE, 0, 1));
  };
  const NavigateToCustomerProfile = (item) => {
    dispatch(selectedUserDetails(item));
    navigate("/UserProfile/UserProfile");
  };
  // Broken image functionality
  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  const GoToChat = (item) => {
    dispatch(SelectedRoute("/Messages"));
    navigate("/Messages", {
      state: item,
    });
  };

  // mark as Unverified
  const Unapproved = (id) => {
    const changeStatus = {
      accountType: "customer",
      id: id,
      isAdminApproved: false,
      isReview: true,
      PrevVeried: true,
    };

    dispatch(UpdateStatusAsync(changeStatus));
  };
  return (
    <Container>
      {searchCustomerAccountLoading || newCustomerAccVerificationLoading ? (
        <Loader />
      ) : null}
      <div
        style={{ height: `calc(100% - 140px)`, paddingBottom: 0 }}
        className="inner-container p-3"
      >
        <div className="HeadingAndSearchSearchCustomerAccount">
          <h1>Search Customer account</h1>
          <div className="RightBarSearchCustomerAccount">
            {/* filter-buttons */}
            {/* <button className="locationBySearchCustomerAccount">
              Location Filter
              <img src={keyboard_arrow_down} alt="category" />
            </button> */}
            <div>
              <button
                onClick={handleClickOpen1}
                className="SearchBySearchCustomerAccount"
              >
                {selectedValue}
                <img
                  style={{ marginLeft: 5 }}
                  src={keyboard_arrow_down}
                  alt="Search by"
                />
              </button>

              {isDropdownOpen && (
                <div className="dropdown-content2">
                  <ul>
                    <li onClick={() => handleSelection("Username")}>
                      Username
                    </li>
                    <li onClick={() => handleSelection("Location")}>
                      Location
                    </li>
                    <li onClick={() => handleSelection("Email")}>Email</li>
                    <li onClick={() => handleSelection("Address")}>Address</li>
                  </ul>
                </div>
              )}
            </div>

            <div className="inputDivSearchCustomerAccount">
              <SearchIcon
                onClick={getDatabyQuery}
                className="searchIconSearchCustomerAccount"
                style={{ color: "#777", fontSize: 22, cursor: "pointer" }}
              />
              <input
                onChange={SearchCustomerByQuery}
                onKeyDown={(event) => {
                  event.key === "Enter" && getDatabyQuery();
                }}
                className="input-fieldSearchCustomerAccount"
                type="text"
                placeholder={`Search by ${filter}`}
              />
            </div>
          </div>
        </div>
        {searchCustomerAccountData?.length === 0 &&
        !searchCustomerAccountLoading ? (
          <div className="NoAdminDataForAdmin">
            <img src={NOdataFound} alt="NO DATA FOUND" />
            <div className="NoDataTextContainerForAdmin">
              <h1>No Customer Users Available</h1>
              <h3>Currently there are no customers .</h3>
            </div>
          </div>
        ) : (
          <>
            <TableContainer
              sx={{ height: "calc( 100% - 40px)", marginTop: "1rem" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow className={classes.root}>
                    <TableCell>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        {/* <Checkbox checked={checked1}
                              onChange={toggleSelectAll} /> */}
                        <p>Username</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Location</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>

                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Email Address</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Mark as Unverified</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                    <TableCell>
                      <span style={{ display: "flex" }}>
                        <p>Action</p>
                        <img
                          style={{ width: 14, marginLeft: "0.2rem" }}
                          src={Group_345}
                          alt="Group_345"
                        />
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchCustomerAccountData?.map((item, i) => {
                    return (
                      <TableRow
                        className={classes.bodyroot}
                        hover
                        role="checkbox"
                      >
                        <TableCell key={i}>
                          <span className="flex  justify-start items-center">
                            {/* <Checkbox
                                    checked={checkboxStates[item?.userId] || checked1}
                                    onChange={() => toggleCheckbox(item, i)} /> */}
                            <div
                              style={{
                                width: "2rem",
                                height: "2rem",
                                borderRadius: "50%",
                                border: "1px solid grey",
                              }}
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "2rem",
                                  height: "2rem",
                                  borderRadius: "50%",
                                }}
                                src={
                                  item?.image.includes(".jpg", ".jpeg", ".png")
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                onError={FailedToLoad}
                                alt="user"
                                onClick={() => NavigateToCustomerProfile(item)}
                              />
                            </div>
                            <span
                              onClick={() => NavigateToCustomerProfile(item)}
                              className=" FirstClild"
                            >
                              <span className="underLineTextVerify cursor-pointer">
                                {item?.userId}
                              </span>
                              <span className="returingDataVerify">
                                {item?.firstName}  {item?.lastName}
                              </span>
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.location?.map((data, i) => (
                              <span key={i} className="returingDataSearch">
                                {data}
                                {i < item.location.length - 1 && ", "}
                              </span>
                            ))}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.streetAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p className="returingDataSearch">
                            {item?.emailAddress}
                          </p>
                        </TableCell>
                        <TableCell>
                          <Switch
                            onChange={() => Unapproved(item?.id)}
                            checked={searchCustomerAccountData?.filter(
                              (user) => user?.id === item?.id
                            )}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            height={22}
                            width={47}
                          />
                        </TableCell>
                        <TableCell>
                          <img
                            onClick={() => GoToChat(item)}
                            style={{
                              marginLeft: "0.5rem",
                              cursor: "pointer",
                            }}
                            src={!item?.isLastMsgRead ? threedot_red : ThreeDot}
                            alt="More"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </div>
      <SearchCusAccountDeleteDialog
        SelectedBussinessEmail={SelectedBussinessEmail}
        deleteData={deleteData}
        handleClose={handleClose}
        setDeleteData={setDeleteData}
      />
      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : null}
    </Container>
  );
}

export default SearchCustomerAccount;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.3rem 1rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 1rem",
    },
  },
});
