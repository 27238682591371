import React, { useState } from "react";
import { Pagination } from "@mui/material";
import "./Footer.scss";

function Footer(props) {
  const { handleChange, totalRecord, startedRecords, toRecords, totalPages } =
    props;
  return (
    <footer className="footer h-[70px] bg-white w-full flex justify-between items-center  z-50">
      {/* z-50 prev */}
      <div>
        Showing {startedRecords} to {toRecords} of {totalRecord} entries
      </div>
      <div style={{ marginRight: 320 }}>
        <Pagination
          count={totalPages}
          color="primary"
          onChange={(event, value) => handleChange(event, value)}
        />
      </div>
    </footer>
  );
}

export default Footer;
