import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import { SelectedFilterFromDate } from "../../../redux/usersSlice";
import {
  getAllBankingDataAsync,
  getAllBankingStatics,
} from "../../../redux/staticsSlice";

const FromDate = ({ openFromDate, setOpenFromDate, BankingData }) => {
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenFromDate(false);
  };

  const selected = (d) => {
    setValue(d);
    const formattedDate = d.toLocaleDateString("en-GB", {
      month: "2-digit",
      year: "numeric",
    });
    dispatch(SelectedFilterFromDate(formattedDate));
    setOpenFromDate(false);
    BankingData.month = formattedDate;
    let data = {
      month: formattedDate,
    };
    dispatch(getAllBankingDataAsync(BankingData));
    dispatch(getAllBankingStatics(data));
  };

  const currentDate = new Date();
  const nextMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    1
  );
  const filterDate = (date) => {
    return date < nextMonth;
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          position: "fixed",
          m: 0,
          top: "30%",
          right: "3.5%",
        },
      }}
      open={openFromDate}
      onClose={handleClose}
    >
      <DatePicker
        id="month-year-selector"
        selected={value}
        onChange={selected}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        inline
        filterDate={filterDate}
      />
    </Dialog>
  );
};

export default FromDate;
