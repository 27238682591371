import React from "react";
import Dialog from "@mui/material/Dialog";
import DeleteImage from "../../../assets/icons/delete-modal-pic.svg";
import Cross from "../../../assets/icons/cross.svg";
import "./CommonStylingForDeleteDialog.scss";
import { useDispatch } from "react-redux";
import { deleteCustomerAsync } from "../../../redux/usersSlice";
const NewCusAccountDialog = ({
  deleteDataNewCusAccount,
  setDeleteDataNewCusAccount,
  handleCloseNewCusAccount,
  selectedNewCustomerVerificationEmail,
}) => {
  const dispatch = useDispatch();
  const DeleteUser = (e) => {
    e.preventDefault();
    setDeleteDataNewCusAccount(false);
    const data = {
      CustomerId: selectedNewCustomerVerificationEmail.current,
      userType: 'customer'
    }
    dispatch(deleteCustomerAsync(data));

  };
  return (
    <Dialog open={deleteDataNewCusAccount}>
      <div className="DeleteContainer">
        <img
          onClick={handleCloseNewCusAccount}
          className="Cancelicon"
          src={Cross}
          alt="Cancel"
        />
        <img className="DeleteImage" src={DeleteImage} alt="Delete User" />
        <p>Are you sure you want to delete this admin?</p>
        <div className="CancelAndDeleteBTN">
          <h5 onClick={() => setDeleteDataNewCusAccount(false)}>No, Cancel</h5>
          <button>
            <h4 onClick={DeleteUser}>Yes Delete</h4>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default NewCusAccountDialog;
