import React, { useState, useRef } from "react";
import "./Messages.scss";
import Container from "../../../components/HOC/Container";
import NewMessage from "../../../components/MessageNavgations/NewMessage/NewMessage";
import FavoritesUsers from "../../../components/MessageNavgations/NewMessage/FavoritesUsers";
import Swal from "sweetalert2";
import Switch from "react-switch";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useLocation } from "react-router-dom";
import {
  getChatRoomsUser,
  getReceivers,
  getAllMessagesAsync,
  setCurrentChat,
  AddNewUserToChat,
} from "../../../redux/chatSlice";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Chat from "./Chat";

import Socket from "../../../redux/socketConnect";
import newMessageIcon from "../../../assets/icons/newMessage.svg";

import { Constants } from "../../../utils/Constants";
import Loader from "../../../components/Loader";

import { Resizable } from "re-resizable";
import {
  CleaningServices,
  ConnectingAirportsOutlined,
} from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import { red } from "@mui/material/colors";

const Messages = ({ conversations }) => {
  const { state } = useLocation();
  console.log("state", state);
  const dispatch = useDispatch();
  const chatContainerRef = useRef(null);
  const chatUserContainerRef = useRef(null);
  const {
    ChatRoomsUsers,
    currentChat,
    messages,
    RecieverDetails,
    AllUsers,
    totalChatUsersCount,
    getAllMessagesLoading,
    isSocketConnected,
    favList,
    usersNotFoundMessage,
    newMessageCount,
    PreviousChatRoomId,
    ChatData,
    SessionExpiredby,
  } = useSelector((state) => state.chat);

  const [newMessage, setNewMessage] = useState("");
  const [IsScrolled, setIsScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState("Chat");
  const [unreadMessageCounts, setUnreadMessageCounts] = useState({});
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [hasMoreUsers, sethasMoreUsers] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ChatRoomPage, setChatRoomPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryAllUsers, setSearchQueryAllUsers] = useState("");
  const [scrollToLast, setScrollToLast] = useState(false);
  const [LoggedInId, setLoggedInId] = useState([]);
  const [selectedUserId, setselectedUserId] = useState([]);
  const [showUnreadAndUnanswered, setShowUnreadAndUnanswered] = useState(false);
  const [openNewUser, SetOpenNewUser] = useState(false);
  const [userApiCall, SetisUserApiCall] = useState(true);
  const [showIncomingUser, SetIsshowIncomingUser] = useState(false);
  const [showSelectedUser, SetshowSelectedUser] = useState([]);
  const [isValidUser, SetisValidUser] = useState(false);

  let isSender = LoggedInId?.id === ChatRoomsUsers?.senderId;
  let Ischat = RecieverDetails?.filter(
    (u) =>
      u?.id ===
      (isSender ? ChatRoomsUsers.receiverId : ChatRoomsUsers?.senderId)
  );
  let currentChatUser = RecieverDetails?.filter((u) => u?.id === currentChat);

  const totalMessages = messages.filter((m) => typeof m === "object").flat();
  console.log("message in the compo###", totalMessages);

  const ReloadData = () => {
    loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    setLoggedInId(loggesInUser);
    if (loggesInUser?.id) {
      dispatch(
        getReceivers(
          loggesInUser?.id,
          loggesInUser?.location,
          Constants.TakeForChatUser,
          0,
          false
        )
      );
      SetisValidUser(false);
    }
  };

  useEffect(() => {
    let isUser = RecieverDetails?.filter((u) => u?.ChatId === ChatData?.chatId);
    console.log("ChatData", ChatData?.chatId);
    console.log("isUser>>>>>", isUser);
    if (isUser?.length === 0) {
      SetisValidUser(true);
      console.log("is validate");
    } else {
      SetisValidUser(false);
    }
  }, [ChatData]);

  const lastItem = totalMessages[totalMessages.length - 1];

  let loggesInUser;
  useEffect(() => {
    if (isSocketConnected) {
      console.log("isSocketConnected", isSocketConnected, ChatRoomsUsers?.id);
      Socket.joinRoom(ChatRoomsUsers?.id);
    }
  }, [ChatRoomsUsers?.id]);

  useEffect(() => {
    if (state) {
      SetshowSelectedUser([state]);
      handleOpenChat(state);
    }

    return () => {
      SetshowSelectedUser([]);
    };
  }, [state]);

  useEffect(() => {
    loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    setLoggedInId(loggesInUser);
    if (loggesInUser?.id) {
      dispatch(
        getReceivers(
          loggesInUser?.id,
          loggesInUser?.location,
          Constants.TakeForChatUser,
          0,
          false
        )
      );
    }
  }, [loggesInUser, selectedUserId, ChatRoomsUsers]);

  // const ScrollChatUser = () => {
  //   const chatUserRef = chatUserContainerRef.current;

  //   if (
  //     hasMoreUsers &&
  //     chatUserRef.scrollHeight -
  //     chatUserRef.scrollTop -
  //     chatUserRef.clientHeight <
  //     50
  //   ) {
  //     console.log("ScrollChatUser");

  //     const limit = Constants.TAKE;
  //     const skip = ChatRoomPage * limit;

  //     if (totalChatUsersCount > skip) {
  //       dispatch(
  //         getReceivers(
  //           loggesInUser?.id,
  //           loggesInUser?.location,
  //           limit,
  //           skip,
  //           true
  //         )
  //       );
  //       setChatRoomPage(ChatRoomPage + 1);
  //     } else {
  //       sethasMoreUsers(false);
  //       console.log("// No more users to load");
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const chatUserRef = chatUserContainerRef.current;
  //   chatUserRef.addEventListener("scroll", ScrollChatUser);

  //   return () => {
  //     chatUserRef.removeEventListener("scroll", ScrollChatUser);
  //   };
  // }, [hasMoreUsers, ChatRoomPage, ChatRoomsUsers]);

  const updateUnreadMessageCount = (userId, count) => {
    setUnreadMessageCounts((prevCounts) => ({
      ...prevCounts,
      [userId]: count,
    }));
  };
  console.log("cureentChat", currentChatUser);

  const handleOpenChat = async (user) => {
    // let isUserAlreadyExist = RecieverDetails.filter((u) => u?.id === user?.id);
    // SetOpenNewUser(false);
    // if (isFromNewUser && !isUserAlreadyExist.length > 0) {
    //   dispatch(AddNewUserToChat({ chatUser: user }));
    //   SetisUserApiCall(false);
    // }
    if (user) {
      loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
      console.log("loggesInUser", loggesInUser);

      // Socket.markMessageAsRead(user?.ChatId);
      setselectedUserId(user);
      setCurrentPage(1);
      setHasMoreMessages(true);
      dispatch(setCurrentChat(user?.id));
      updateUnreadMessageCount(user?.id, 0);
      dispatch(
        getChatRoomsUser(LoggedInId?.id || loggesInUser?.id, user?.id, "admin")
      ).then((res) => {
        console.log("##resssss", res);
        dispatch(getAllMessagesAsync(res?.id, Constants.takeForChat, 0, false));
      });
    }
  };

  const handleSubmit = async (e) => {
    console.log("ChatRoomsUsers", ChatRoomsUsers);
    if (e) {
      e.preventDefault();
    }
    setIsScrolled(false);
    const trimmedMessage = newMessage.trim();
    if (trimmedMessage !== "") {
      const msg = {
        chatId: ChatRoomsUsers?.id,
        senderId: LoggedInId?.id,
        receiverId: isSender
          ? ChatRoomsUsers.receiverId
          : ChatRoomsUsers?.senderId,
        text: trimmedMessage,
        projectId: "",
        isRead: true,
        isSenderAdmin: true,
        isSeen: false
      };
      console.log("handleSubmit", msg);

      if (msg.chatId && msg.senderId && msg.text) {
        // dispatch(sendMessageAsync(msg));
        Socket.onSend(msg, dispatch);
        setNewMessage(" ");
        setIsScrolled(true);
      }
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const handleOpenNewChat = () => {
  //   SetOpenNewUser(true);
  //   SetisUserApiCall(true);
  // };

  const handleToggleUnreadAndUnanswered = () => {
    setShowUnreadAndUnanswered((prev) => !prev);
  };

  const filteredChatUsers = RecieverDetails?.filter(
    (user) =>
      user && user.userId?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleScroll = () => {
    const chatContainer = chatContainerRef.current;
    if (hasMoreMessages && chatContainer.scrollTop === 0) {
      setScrollToLast(true);
      const limit = Constants.TAKE;
      const skip = currentPage * limit;

      const totalMessages = messages?.flat();
      const lastItem = totalMessages[totalMessages.length - 1];

      if (lastItem > skip) {
        console.log("ChatRoomsUsers#####", ChatRoomsUsers);
        dispatch(
          getAllMessagesAsync(
            ChatRoomsUsers?.id || PreviousChatRoomId,
            limit,
            skip,
            true
          )
        );
        setCurrentPage(currentPage + 1);
      } else {
        setHasMoreMessages(false);
        console.log("// No more messages to load");
      }
    }
  };

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    chatContainer.addEventListener("scroll", handleScroll);

    return () => {
      chatContainer.removeEventListener("scroll", handleScroll);
    };
  }, [hasMoreMessages, messages, currentPage, ChatRoomsUsers]);

  // useEffect(() => {
  //   let storedFavList = localStorage.getItem("favList");
  //   if (storedFavList) {
  //     dispatch(setFavList(JSON.parse(storedFavList)));
  //   } else {
  //     dispatch(setFavList([]));
  //   }
  // }, []);

  // const handleClose = () => {
  //   console.log("calling");
  //   SetOpenNewUser(false);
  //   // setValidDate(formattedDate);
  // };

  // useEffect(() => {
  //   console.log("calling from outSide", showIncomingUser);

  //   if (!showIncomingUser) {
  //     console.log("calling from inside", showIncomingUser);
  //   }
  // }, [messages]);

  return (
    <Container Comp={Chat}>
      <div
        messageContainer
        style={{
          height: `calc(100% - 140px)`,
          display: "flex",
          flexDirection: "row",
        }}
        className="inner-container"
      >
        <Resizable
          defaultSize={{
            width: 445,
            height: "100%",
          }}
          className="leftContainer"
        >
          <div className="messageDiv">
            <div className="searchInput">
              <img
                src={require("../../../assets/icons/SearchIcon.svg").default}
                alt="user"
              />
              <input
                type="text"
                placeholder="Search Users..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "4%",
                gap: "2%",
              }}
            >
              <p
                style={{
                  color: "#717171",
                  fontFamily: "Roboto",
                  fontSize: "0.8rem",
                }}
                // className="mb-1 text-#717171  text-xs"
              >
                Unanswered
              </p>

              <label>      
                <Toggle
                  defaultChecked={showUnreadAndUnanswered}
                  onChange={handleToggleUnreadAndUnanswered}
                  style={{ transform: "scale(0.8)" }}
                />
              </label>
              {/*    <img
                style={{ width: "25px", marginBottom: "6%" }}
                src={require("../../../assets/icons/edit-write.svg").default}
                alt="user"
                onClick={() => handleOpenNewChat()}
              />
             
              <Dialog
                PaperProps={{ 
                  sx: {
                    position: "fixed",
                    padding: "1%",
                    right: "center",
                    width: "30%",
                    height: "80%",
                    backgroundColor: "#fff",
                  },
                }}
                open={openNewUser}
                onClose={handleClose}
              >
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        color: "#717171",
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "22px",
                      }}
                    >
                      Start a New Conversation
                    </span>
                    <span
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => SetOpenNewUser(false)}
                    >
                      X
                    </span>
                  </div>

                  <div className="searchInputInAllUsers">
                    <img
                      src={
                        require("../../../assets/icons/SearchIcon.svg").default
                      }
                      alt="user"
                    />
                    <input
                      type="text"
                      placeholder="Search Users..."
                      value={searchQueryAllUsers}
                      onChange={(e) => setSearchQueryAllUsers(e.target.value)}
                    />
                  </div>
                </div>
                <NewMessage
                  recentMessages={messages
                    .filter((m) => typeof m === "object")
                    .flat()
                    .reverse()}
                  conversation={conversations}
                  currentUser={LoggedInId}
                  handleClick={handleOpenChat}
                  receiver={AllUsers}
                  unreadMessageCounts={unreadMessageCounts}
                  showUnreadAndUnanswered={showUnreadAndUnanswered}
                  isStartNewChat={userApiCall}
                />
              </Dialog>
                  */}
            </div>
          </div>
          {isValidUser && (
            <div onClick={ReloadData} className="refresh-main-container">
              <div className="refresh-container">
                <span>Reload New Message</span>
                <img src={newMessageIcon} alt="refresh" />
              </div>
            </div>
          )}
          <div className="MessageNav">
            <ul>
              <li
                className={activeTab === "Chat" ? "active" : ""}
                onClick={() => handleTabClick("Chat")}
              >
                Chats
              </li>
              <li
                className={activeTab === "Favorites" ? "active" : ""}
                onClick={() => handleTabClick("Favorites")}
              >
                Favorites
              </li>
            </ul>
          </div>
          {activeTab === "Chat" && (
            <div className="MessageDiv" ref={chatUserContainerRef}>
              <div>
                <NewMessage
                  recentMessages={messages
                    .filter((m) => typeof m === "object")
                    .flat()
                    .reverse()}
                  conversation={conversations}
                  currentUser={LoggedInId}
                  handleClick={handleOpenChat}
                  receiver={
                    (showSelectedUser.length > 0 && showSelectedUser) ||
                    (filteredChatUsers ? filteredChatUsers : RecieverDetails)
                  }
                  unreadMessageCounts={unreadMessageCounts}
                  showUnreadAndUnanswered={showUnreadAndUnanswered}
                  // isStartNewChat={false}
                />
              </div>
            </div>
          )}
          {activeTab === "Favorites" && (
            <div className="MessageDiv" ref={chatUserContainerRef}>
              {favList?.length > 0 ? (
                <FavoritesUsers
                  recentMessages={messages
                    .filter((m) => typeof m === "object")
                    .flat()
                    .reverse()}
                  conversation={conversations}
                  currentUser={LoggedInId}
                  handleClick={handleOpenChat}
                  receiver={
                    (filteredChatUsers ? filteredChatUsers : RecieverDetails) ||
                    (showSelectedUser.length > 0 && showSelectedUser)
                  }
                  unreadMessageCounts={unreadMessageCounts}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "25%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1rem",
                      fontFamily: "Segoe UI",
                      color: "#717171",
                    }}
                  >
                    Favorites User Not Found
                  </div>
                </div>
              )}
            </div>
          )}
        </Resizable>
        <div className="rightContainer">
          {currentChatUser[0]?.userId && (
            <h1 className="UserAvatar">
              <p className="UserAvtarIcon">
                {currentChatUser[0]?.userId?.charAt(0).toUpperCase()}
              </p>
              {currentChatUser[0]?.userId?.toUpperCase()}
            </h1>
          )}
          {getAllMessagesLoading && <Loader />}

          <div className="rightContainer" ref={chatContainerRef}>
            {totalMessages?.length > 0 && Ischat?.length > 0 ? (
              <Chat
                messages={messages
                  .filter((m) => typeof m === "object")
                  .flat()
                  .reverse()}
                currentUser={LoggedInId}
                receiver={ChatRoomsUsers}
                currentPage={currentPage}
                isQA={false}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "25%",
                }}
              >
                <div
                  style={{
                    fontSize: "3rem",
                    fontFamily: "Segoe UI",
                    color: "#717171",
                  }}
                >
                  Start Chat
                </div>
              </div>
            )}
          </div>

          {messages && (
            <div className="ReplyDiv">
              <textarea
                value={newMessage}
                onChange={(event) => {
                  setNewMessage(event.target.value);
                }}
                onKeyDown={(event) => {
                  newMessage.length > 0 &&
                    currentChat !== null &&
                    event.key === "Enter" &&
                    handleSubmit();
                }}
                placeholder="type..."
                className="textfield"
              ></textarea>
              <button
                style={{
                  pointerEvents: currentChat === null ? "none" : "auto",
                  opacity: currentChat === null ? "0.5" : "1",
                }}
                onClick={handleSubmit}
                className="btnForReply"
              >
                Reply
              </button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Messages;
