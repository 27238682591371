import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import { Constants } from "../utils/Constants";

const initialState = {
  announcementLoading: false,
  announcementData: [],
  announcementFailedData: "",
  //update Announcement
  updateAnnouncementLoading: false,
  updateAnnouncementData: [],
  updateAnnouncementFailed: "",
  //
  AllCitesLoading: false,
  AllCitiesData: [],
  AllCitiesFailed: "",
  //====
  validUrlLoading: false,
  validURL: false,
  validURLError: "",
  // master term and condition
  masterTnCLoading: false,
  masterTnCData: [],
  masterTnCFailedData: "",
  masterTnCDataLength: 0,
  masterTnCstartedRecords: 1,
  masterTnCtoRecords: 5,
  masterTnCtotalPages: 0,
  // update master term and condition
  updateMasterTnCLoading: false,
  updateMasterTnCData: [],
  updateMasterTnCFailedData: "",
};
export const announcementsSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    // Get Announcements-------------------------
    getAllAnnouncement: (state) => {
      state.announcementLoading = true;
    },
    getAllAnnouncementSuccess: (state, action) => {
      state.announcementLoading = false;
      state.announcementData = action.payload;
    },
    getAllAnnouncementFailed: (state, action) => {
      state.announcementLoading = false;
      state.announcementFailedData = action.payload;
    },
    //
    getValidUrl: (state) => {
      state.validUrlLoading = true;
    },
    getValidUrlSuccess: (state, action) => {
      state.validUrlLoading = false;
      state.validURL = action.payload;
    },
    getValidUrlFailed: (state, action) => {
      state.validUrlLoading = false;
      state.validURLError = action.payload;
    },
    // Update Announcement----------------------
    updateAnnouncement: (state) => {
      state.updateAnnouncementLoading = true;
    },
    updateAnnouncementSuccess: (state, action) => {
      for (let index = 0; index < state.announcementData.length; index++) {
        if (action.payload.id === state.announcementData[index].id) {
          state.announcementData[index].title = action.payload.title;
          state.announcementData[index].description =
            action.payload.description;
        }
      }
      state.updateAnnouncementLoading = false;
      state.announcementData = [...state.announcementData];
    },
    updateAnnouncementFailed: (state, action) => {
      state.updateAnnouncementLoading = false;
      state.updateAnnouncementFailed = action.payload;
    },

    GetAllCities: (state) => {
      state.AllCitesLoading = true;
    },
    GetAllCitiesSuccess: (state, action) => {
      state.AllCitesLoading = false;
      state.AllCitiesData = action.payload;
    },
    GetAllCitiesFailed: (state, action) => {
      state.AllCitesLoading = false;
      state.AllCitiesFailed = action.payload;
    },

    // Master announcement text
    getMasterTermAndCondition: (state) => {
      state.masterTnCLoading = true;
    },
    getMasterTermAndConditionSuccess: (state, action) => {
      state.masterTnCLoading = false;
      state.masterTnCData = action.payload[0];
      state.masterTnCDataLength = action.payload[1];
      state.masterTnCstartedRecords = (action.payload.CurrentPage - 1) * 6 + 1;
      state.masterTnCtoRecords = Math.min(
        action.payload.CurrentPage *6,
        action.payload[1]
      );
      state.masterTnCtotalPages = Math.ceil(action.payload[1] /6);
    },
   
    getMasterTermAndConditionFailed: (state, action) => {
      state.masterTnCLoading = false;
      state.masterTnCFailedData = action.payload;
    },
    // update Master t text
    updateMasterTermAndCondition: (state) => {
      state.updateMasterTnCLoading = true;
    },
    updateMasterTermAndConditionSuccess: (state, action) => {
      state.updateMasterTnCLoading = false;
      state.updateMasterTnCData = action.payload;
    },
    updateMasterTermAndConditionFailed: (state, action) => {
      state.updateMasterTnCLoading = false;
      state.updateMasterTnCFailedData = action.payload;
    },
  },
});
export const getValidUrlAsync = (url) => (dispatch) => {
  dispatch(getValidUrl());
  axiosInstance
    .get("/upload/getIdCardProfileImage", {
      params: {
        userId: "Test12",
        imageName: "d1-download-1700827980510.jpeg",
      },
    })
    .then((response) => {
      console.log("resss", response);
      if (response?.data?.status === 200) {
        dispatch(getValidUrlSuccess(response));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(getValidUrlFailed());
    });
};
//

export const getAllAnnouncementAsync = (serachAnnouncement) => (dispatch) => {
  dispatch(getAllAnnouncement());
  axiosInstance
    .get("/announcement/getAnnouncementByIds", {
      params: {
        title: serachAnnouncement,
        ids: serachAnnouncement,
      },
    })
    .then((response) => {
      console.log("annpuncement resp", response);
      dispatch(getAllAnnouncementSuccess(response.data[0]));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAllAnnouncementFailed());
    });
};

export const updateAnnouncementAsync = (data) => (dispatch) => {
  console.log(data);
  dispatch(updateAnnouncement());
  axiosInstance
    // .put(`/announcement/updateAnnouncementItem?id=${data.id}`, data)
    .patch(`/announcement/${data.id}`, data)
    .then((response) => {
      // console.log(" sfnsnvnvonovnsnvlvnlksnv", response);
      dispatch(updateAnnouncementSuccess(response.data.res));
    })
    .catch((err) => {
      dispatch(updateAnnouncementFailed());
    });
};

export const getAllLocationAsync = () => (dispatch) => {
  dispatch(GetAllCities());
  axiosInstance
    .get("/UsCanada/GetAllstate")
    .then((response) => {
      dispatch(GetAllCitiesSuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(GetAllCitiesFailed());
    });
};

// Master announcement text

export const getAllTermAndConditionAsync = (data) => (dispatch) => {
  dispatch(getMasterTermAndCondition());
  axiosInstance
    .get("upload/getMasterTermsConditions", {
      params: {
        accountType: "masteradmin",
        page: data?.page,
        perPage: data?.perPage,
      },
    })
    .then((response) => {
      console.log("response>>>>>>>>", response);
      response.data.CurrentPage = data.page;
      dispatch(getMasterTermAndConditionSuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getMasterTermAndConditionFailed());
    });
};

// Update master term and condition

export const updateTermAndConditionAsync = (data) => async (dispatch) => {
  dispatch(updateMasterTermAndCondition());
  try {
    const response = await axiosInstance.post(
      "/upload/updateMasterTermsConditions",
      data
    );
    console.log("response", response);
    dispatch(updateMasterTermAndConditionSuccess(response.data));
    if (response?.status === 200 || 201) {
      toast.success("Updated successfully");
    }
    return response;
  } catch (err) {
    console.log("errrrrrr", err);
    dispatch(updateMasterTermAndConditionFailed());
    toast.error(err?.response?.data?.message);

    return err?.response;
  }
};
export const {
  updateMasterTermAndCondition,
  updateMasterTermAndConditionSuccess,
  updateMasterTermAndConditionFailed,
  getMasterTermAndCondition,
  getMasterTermAndConditionSuccess,
  getMasterTermAndConditionFailed,
  getAllAnnouncement,
  getAllAnnouncementSuccess,
  getAllAnnouncementFailed,
  getValidUrl,
  getValidUrlSuccess,
  getValidUrlFailed,
  getUpdateAnnouncement,
  getUpdateAnnouncementSuccess,
  getUpdateAnnouncementFailed,
  updateAnnouncement,
  updateAnnouncementSuccess,
  updateAnnouncementFailed,
  GetAllCities,
  GetAllCitiesSuccess,
  GetAllCitiesFailed,
} = announcementsSlice.actions;
export default announcementsSlice.reducer;
