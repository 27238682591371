import React from "react";
import ComingSoon from "../../assets/icons/ComingSoon.png";
const ComingSoonPage = () => {
  return (
    <div style={{ height: "100vh" ,width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
      <img style={{width:'60vw'}} src={ComingSoon} alt="show" />
    </div>
  );
};

export default ComingSoonPage;
