import React from "react";
import "./SearchAdsPerLocation.scss";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import Group_345 from "../../../assets/icons/Group_345.svg";
import keyboard_arrow_down from "../../../assets/icons/keyboard_arrow_down.svg";
import calendar_month from "../../../assets/icons/calendar_month.svg";
// table --
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
function createData(no, name, calories, fat, carbs, protein, multivitamin) {
  return { no, name, calories, fat, carbs, protein, multivitamin };
}

const rows = [
  createData(
    1,
    "Go Boat",
    "$346.00",
    "$1000",
    "deanna.curtis@example.com",
    "(308) 555-0121",
    "20 Poland Road, Wairau Valley, Auckland .."
  ),
  createData(
    1,
    "Go Boat",
    "$346.00",
    "$100",
    "deanna.curtis@example.com",
    "(308) 555-0121",
    "20 Poland Road, Wairau Valley, Auckland .."
  ),
  createData(
    1,
    "Go Boat",
    "$346.00",
    "$100",
    "deanna.curtis@example.com",
    "(308) 555-0121",
    "20 Poland Road, Wairau Valley, Auckland .."
  ),
  createData(
    1,
    "Go Boat",
    "$346.00",
    "$100",
    "deanna.curtis@example.com",
    "(308) 555-0121",
    "20 Poland Road, Wairau Valley, Auckland .."
  ),
  createData(
    1,
    "Go Boat",
    "$346.00",
    "$100",
    "deanna.curtis@example.com",
    "(308) 555-0121",
    "20 Poland Road, Wairau Valley, Auckland .."
  ),
];

function SearchAdsPerLocation() {
  const classes = useStyles();
  return (
    <Container>
      <div
        style={{ height: `calc(100% - 140px)` }} className="inner-container p-3">
        <div className="HeadingAndSearchSearchAds">
          <h1>
            Search ADs per location
          </h1>
          <div className="RightBarSearchAds">
            <button className="locationBySearchAds">
              Category{" "}
              <img
                style={{ marginLeft: 5 }}
                src={keyboard_arrow_down}
                alt="category"
              />
            </button>
            <button className="filterSearchAds">
              From Date
              <img
                style={{ marginLeft: 10 }}
                src={calendar_month}
                alt="  Sub Category"
              />
            </button>
            <button className="filterSearchAds">
              To Date
              <img
                style={{ marginLeft: 10 }}
                src={calendar_month}
                alt=" Location Filter"
              />
            </button>

            <button className="SearchBySearchAds">
              Search by
              <img
                style={{ marginLeft: 5 }}
                src={keyboard_arrow_down}
                alt="Search by"
              />
            </button>
            <div className="inputDivSearchAds">
              <SearchIcon
                className="searchIconSearchAds"
                style={{ color: "#777", fontSize: 22 }}
              />
              <input
                className="input-fieldSearchAds"
                type="text"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
        <TableContainer sx={{ height: 'calc( 100% - 10px)' }}>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow className={classes.root}>
                <TableCell>
                  <span style={{ display: "flex" }}>
                    {/* <Checkbox checked={checked1}
                            onChange={toggleSelectAll} /> */}
                    <p >Business Name</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
                <TableCell
                >
                  <span style={{ display: "flex" }}>
                    <p>Minimum Prize</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ display: "flex" }}>
                    <p>paid</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ display: "flex" }}>
                    <p>Eamil</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ display: "flex" }}>
                    <p>Phone Number</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
                <TableCell>
                  <span style={{ display: "flex" }}>
                    <p>Address</p>
                    <img
                      style={{ width: 14, marginLeft: "0.2rem" }}
                      src={Group_345}
                      alt="Group_345"
                    />
                  </span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .map((item, i) => {
                  return (
                    <TableRow className={classes.bodyroot} hover role="checkbox"   >
                      <TableCell key={i} >
                        <span className="flex  justify-start items-center  " >
                          {/* <Checkbox
                                  checked={checkboxStates[item?.userId] || checked1}
                                  onChange={() => toggleCheckbox(item, i)} /> */}
                          <div style={{
                            display: "flex",
                            alignItems: "flex-start"
                          }}>
                            <img
                              style={{
                                width: "2rem",
                                height: "2rem",
                                borderRadius: '50%'
                              }}
                              src={
                                require("../../../assets/icons/Frame1234.svg")
                                  .default
                              }
                              alt="user"
                            />
                          </div>

                          <span className=" FirstClild ml-1">
                            <p className="returingDataSearch">  {item?.name}</p>
                          </span>
                        </span>
                      </TableCell>
                      <TableCell >
                        <span className="returingDataSearch" >{item?.calories}</span>
                      </TableCell>

                      <TableCell>
                        <p className="returingDataSearch">{item?.fat}</p>
                      </TableCell>

                      <TableCell>
                        <p className="returingDataSearch">{item?.carbs}</p>
                      </TableCell>
                      <TableCell >
                        <span className="returingDataSearch" >{item?.protein}</span>
                      </TableCell>
                      <TableCell>
                        <p className="returingDataSearch">
                          {item?.multivitamin}
                        </p>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="addItem">
          <p>+ Add new row in advertisment list</p>
        </div>


      </div>
    </Container >
  );
}

export default SearchAdsPerLocation;
const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: '28px',
      padding: '0.2rem 0.8rem',

    },
  },
  bodyroot: {
    '&:hover': {
      backgroundColor: '#c5d9cf !important',
    },
    "& .MuiTableCell-body": {
      height: '28px',
      padding: '0.2rem 1rem',
    },
  }

});