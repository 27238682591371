import React, { useState, useEffect } from "react";
import moment from "moment";
import "./NewMessage.scss";

import {
  AddFavList
} from "../../../redux/chatSlice";

import { useDispatch, useSelector } from "react-redux";
import Socket from "../../../redux/socketConnect";
import ClassNameGenerator from "@mui/utils/ClassNameGenerator";
const FavoritesUsers = ({
  recentMessages,
  conversation,
  currentUser,
  handleClick,
  receiver,
  unreadMessageCounts,
  isFav,
}) => {
  const dispatch = useDispatch();
  const {
    ChatRoomsUsers,
    currentChat,
    unreadMsg,
    favList,
  } = useSelector((state) => state.chat);

  // const [currentChat, setcurrentChat] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState([]);
  const [Favusers, setFavusers] = useState([]);
  const [selectedUserMessages, setSelectedUserMessages] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  // const [messageData, setMessageData] = useState([]);

  const loadSelectedUserMessages = (userId, lastMsg) => {
    if (!lastMsg) {
      const userMessages = recentMessages?.filter(
        (message) =>
          message.senderId === userId || message.receiverId === userId
      );
      setSelectedUserMessages(userMessages);
      setSelectedUserId(userId);
    }
  };

  useEffect(() => {
    let isOpen = unreadMsg.filter((m) => m?.chatId !== ChatRoomsUsers?.id);

    if (isOpen) {
      setIsChatOpen(isOpen);
      setNotificationData((prevData) => [...prevData, ...isOpen]);
    } else {
      setNotificationData([]);
    }

    return () => setIsChatOpen(null);
  }, [unreadMsg]);

  // useEffect(() => {
  //   setMessageData(recentMessages);
  // }, [recentMessages]);

  // const getNotification = () => {
  //   const isOpen =
  //     currentChat === notification[notification.length - 1].senderId;
  //   let isRead = notification[notification.length - 1].isRead;

  //   if (isOpen) {
  //     setIsChatOpen(isOpen);
  //     setNotificationData([{ ...notification, isRead: true }]);
  //     console.log(" isChatOpen notificationData", notificationData);
  //   } else {
  //     setIsChatOpen(false);
  //     // setNotificationData((prev) => [{ notification }, ...prev]);
  //     console.log("!!isChatOpen notificationData", notificationData);
  //   }
  // };

  // useEffect(() => {
  //   getNotification();
  // }, [notification]);

  const handleOpenChat = (conversation) => {
    handleClick(conversation);
    // setcurrentChat(conversation.id);

    if (conversation?.id === isChatOpen[0]?.senderId) {
      setIsChatOpen(false);
      setNotificationData([]);
    }
  };

  const toggleFav = (user) => {
    const updatedFavList = favList?.some((favUser) => favUser.id === user.id)
      ? favList?.filter((favUser) => favUser.id !== user.id)
      : [...favList, user];
    dispatch(AddFavList(updatedFavList));
  };

  const sortUsers = (users, favList) => {
    const sortedUsers = [...users];
    sortedUsers.sort((user1, user2) => {
      const isFav = (user, favList) =>
        favList?.some((fav) => fav?.id === user?.id);

      const user1IsFav = isFav(user1, favList);
      const user2IsFav = isFav(user2, favList);
      if (user1IsFav && !user2IsFav) return -1;
      if (!user1IsFav && user2IsFav) return 1;
      return 0;
    });
    return sortedUsers;
  };

  const sortedRecieverDetails = sortUsers(receiver, favList);

  // useEffect(() => {
  //   let storedFavList = localStorage.getItem("favList");
  //   if (storedFavList) {
  //     dispatch(setFavList(JSON.parse(storedFavList)));
  //   } else {
  //     // dispatch(setFavList([]));
  //   }
  // }, []);

  useEffect(() => {
    Socket.socketInit(currentUser?.id, dispatch);
    // setcurrentChat(sortedRecieverDetails[0]?.id);
  }, [dispatch]);

  return (
    <div className="msgContainer">
      {recentMessages &&
        favList?.map((user, i) => {
          const userMessages = recentMessages?.filter(
            (message) =>
              message.senderId === user.id || message.receiverId === user.id
          );
          let lastMsg;
          let lastChatTime = "";
          if (userMessages?.length > 0) {
            const lastMessage = userMessages[userMessages?.length - 1];
            const maxMessageLength = 40;
            lastMsg =
              lastMessage?.text?.length > maxMessageLength
                ? lastMessage?.text?.substring(0, maxMessageLength - 3) + "..."
                : lastMessage.text;
            const timestamp = parseInt(lastMessage.createdAt, 10);
            lastChatTime = moment(timestamp).format("LT");
          }
          let lastMsgdb;
          const maxdbMessageLength = 40;
          if (user?.lastMessage) {
            lastMsgdb =
              user?.lastMessage?.length > maxdbMessageLength
                ? user?.lastMessage?.substring(0, maxdbMessageLength - 3) +
                "..."
                : user?.lastMessage;
            //  const timestamp = parseInt(lastMessage?.createdAt, 10);
            //  lastChatTime = moment(timestamp).format("LT");
          }

          // const userId = user?.id;
          // const unreadCount = unreadMessageCounts[userId] || 0;
          let nameChar =
            user.accountType == "business"
              ? user?.businessName?.charAt(0).toUpperCase() +
              user?.lastName?.charAt(0).toUpperCase()
              : user?.name?.charAt(0).toUpperCase();

          return (
            <div
              className={`CustomerMessages ${user?.isSessionExpired == false &&
                currentUser?.id !== user?.connectedTo
                ? "setbusy"
                : "setfree"
                } ${currentChat === user.id ? "active" : ""}`}
              onClick={() => {
                handleOpenChat(user);
                loadSelectedUserMessages(user?.id, lastMsg);
              }}
              key={i}
            >
              <div className="divOne">
                {nameChar == undefined ? "NA" : nameChar}
              </div>
              <div className="divTwo">
                <div className="upperDiv">
                  <h6 style={{ fontFamily: "Roboto" }}>
                    {user?.userId || user?.id?.slice(0, 8)}{" "}
                    {user?.isSessionExpired == false &&
                      currentUser?.id !== user?.connectedTo && (
                        <span style={{ color: "red" }}>(busy)</span>
                      )}
                  </h6>
                  <p>{lastChatTime}</p>
                </div>
                <div className="lowerDIv">
                  <p>{lastMsg || lastMsgdb}</p>
                  {user?.id === isChatOpen[0]?.senderId && (
                    <div className="msgCount">
                      <div className="msgRec">
                        {isChatOpen.length > 0
                          ? notificationData?.length
                          : null}
                      </div>
                    </div>
                  )}

                  {
                    <img
                      src={
                        favList?.includes(user)
                          ? require("../../../assets/icons/StarFilled.svg")
                            .default
                          : require("../../../assets/icons/StartEmpty.svg")
                            .default
                      }
                      alt="user"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFav(user);
                      }}
                    />
                  }
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default FavoritesUsers;
