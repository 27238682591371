import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { toast } from "react-toastify";
import { Constants } from "../utils/Constants";

const initialState = {
  getAllAdLoading: false,
  allAdData: [],
  //-------delete
  deleteAdLoading: false,
  deleteAdError: "",

  //-------create
  getTransactionLoading: false,
  transactionData: [],
  getTransactionFailed: "",
  TransactionPaginationDataLength: 0,
  TransactionStartedRecords: 1,
  TransactionToRecords: 20,
  totalTransactionPage: 0,
  // transaction data
};
export const adSlice = createSlice({
  name: "ad",
  initialState,
  reducers: {
    getAllAd: (state) => {
      state.getAllAdLoading = true;
    },
    getAllAdSuccess: (state, action) => {
      state.getAllAdLoading = false;
      state.allAdData = action.payload;
    },
    getAllAdFailed: (state) => {
      state.getAllAdLoading = false;
    },

    deleteAd: (state) => {
      state.deleteAdLoading = true;
      state.deleteAdError = "";
    },
    deleteAdSuccess: (state, action) => {
      state.deleteAdLoading = false;
      state.allAdData = state.allAdData.filter((i) => i.id !== action.payload);
    },
    deleteAdFailed: (state, action) => {
      state.deleteAdLoading = false;
      state.deleteAdError = action.payload;
    },

    createAd: (state) => {
      state.createAdLoading = true;
      state.deleteAdError = "";
    },
    createAdSuccess: (state, action) => {
      state.createAdLoading = false;
      state.allAdData = [...state.allAdData, action.payload];
    },
    createAdFailed: (state) => {
      state.createAdLoading = false;
      state.createAdError = "Unable to create Ad";
    },
    // Get transactions
    getTransactions: (state) => {
      state.getTransactionLoading = true;
    },
    getTransactionsSuccess: (state, action) => {
      state.getTransactionLoading = false;
      state.transactionData = action.payload[0];
      state.TransactionPaginationDataLength =
        action.payload[action.payload.length - 1];
      state.TransactionStartedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.TransactionToRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalTransactionPage = Math.ceil(
        action.payload[action.payload.length - 1] / Constants.TAKE
      );
    },
    getTransactionsFailed: (state, action) => {
      state.getTransactionLoading = false;
      state.getTransactionFailed = action.payload;
    },
  },
});

export const getAllAdAsync = () => (dispatch) => {
  dispatch(getAllAd());
  axiosInstance
    .get("/Category/GetAds")
    .then((response) => {
      console.log(response);
      dispatch(getAllAdSuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(getAllAdFailed());
    });
};

export const deleteAdAsync = (id) => async (dispatch) => {
  dispatch(deleteAd());
  const data = { id };

  try {
    const response = await axiosInstance.delete("/Category/DeleteProject", {
      data,
    });
    console.log(response);
    if (response) {
      toast.success("Ad Deleted !!");

      dispatch(deleteAdSuccess(id));
      return "ad deleted success";
    }
  } catch (err) {
    console.log(err);
    dispatch(deleteAdFailed(err.response.data));
    return "error";
  }
};

export const createAdAsync = (data) => async (dispatch) => {
  dispatch(createAd());

  try {
    const response = await axiosInstance.post("/Category/CreateAd", data);
    console.log(response);
    if (response) {
      toast.success("Ad Created !!");

      dispatch(createAdSuccess(response.data));
      return "ad create success";
    }
  } catch (err) {
    console.log(err);
    dispatch(createAdFailed(err.response.data));
    return "error";
  }
};

// Get transctions details

export const GetAlltransactionsAsync =
  (data, take, CurrentPage) => async (dispatch) => {
    dispatch(getTransactions());
    try {
      const response = await axiosInstance.get(
        "/BusisnessUser/GetTransactionsDetails",
        {
          params: {
            businessId: data?.businessId,
            page: CurrentPage,
            perPage: take,
          },
        }
      );
      if (response) {
        response.data.CurrentPage = CurrentPage;
        dispatch(getTransactionsSuccess(response?.data));
      }
    } catch (err) {
      dispatch(getTransactionsFailed(err.response.data));
    }
  };

// Action creators are generated for each case reducer function
export const {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsFailed,
  getAllAd,
  getAllAdSuccess,
  getAllAdFailed,
  deleteAd,
  deleteAdSuccess,
  deleteAdFailed,
  createAd,
  createAdSuccess,
  createAdFailed,
} = adSlice.actions;

export default adSlice.reducer;
