import React from "react";
import "../../MasterAdmin/MasterAdminCSV/Admincsv.scss"
import CsvUploaderModal from "../../../components/CSV/CsvUploaderModal";
const AdminCSV = () => {
  return (
    <>
      <CsvUploaderModal />
    </>
  );
};

export default AdminCSV;
