import { Navigate, Route, Routes } from "react-router-dom";
import TempCustomerAccount from "../pages/Admin/TempCustomerAccount/TempCustomerAccount";
import NewCustomerVerification from "../pages/Admin/NewCustomerVerification/NewCustomerVerification";
import SearchCustomerAccount from "../pages/Admin/SearchCustomerAccount/SearchCustomerAccount";
import SearchBusinessAccount from "../pages/Admin/SearchBusinessAccount/SearchBusinessAccount";
import TempBusinessAccount from "../pages/Admin/TempBusinessAccount/TempBusinessAccount";
import BusinessLicenseVerification from "../pages/Admin/BusinessLicenseVerification/BusinessLicenseVerification";
import SearchAdsPerLocation from "../pages/Admin/SearchAdsPerLocation/SearchAdsPerLocation";
import SearchByProject from "../pages/Admin/SearchByProject/SearchByProject";
import SearchProBusinessAccount from "../pages/Admin/SearchProBusinessAccount/SearchProBusinessAccount";
import Messages from "../pages/Admin/Messages/Messages";
import SidebarAdmin from "../components/SidebarAdmin/SidebarAdmin";
import AdminCSV from "../pages/Admin/AdminCSV/AdminCSV";
import UserProfile from "../pages/Admin/UserProfile/UserProfile";
import EditUser from "../pages/Admin/EditPage/EditUser";
import CreateTemporaryAccount from "../pages/Admin/CreateTempAccount/CreateTemporaryAccount";
import AddProject from "../pages/Admin/AddProject/AddProject";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Socket from "../redux/socketConnect";
import AddSecondaryEmail from "../pages/Admin/AddSecondaryEmail/AddSecondaryEmail";
import ProjectTabbar from "../components/Tabs/ProjectTabbar";

const AdminRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    Socket.socketInit(loggesInUser?.userId, dispatch);
    return () => {
      Socket.logoutUser(dispatch);
    };
  }, []);
  return (
    <div className="h-screen w-full flex">
      <SidebarAdmin />
      <Routes>
        <Route path="/tempCustomerAccount" element={<TempCustomerAccount />} />
        <Route
          path="/newCustomerVerification"
          element={<NewCustomerVerification />}
        />
        <Route
          path="/searchCustomerAccount"
          element={<SearchCustomerAccount />}
        />
        <Route
          path="/searchBusinessAccount"
          element={<SearchBusinessAccount />}
        />
        <Route path="/tempBusinessAccount" element={<TempBusinessAccount />} />
        <Route
          path="/businessLicenseVerification"
          element={<BusinessLicenseVerification />}
        />
        <Route path="/messages" element={<Messages />} />
        <Route path="/AddSecondaryEmail" element={<AddSecondaryEmail />} />
        <Route
          path="/searchAdsPerLocation"
          element={<SearchAdsPerLocation />}
        />
        <Route path="/searchByProject" element={<SearchByProject />} />
        <Route
          path="/searchProBusinessAccount"
          element={<SearchProBusinessAccount />}
        />

        <Route path="*" element={<Navigate to="/tempCustomerAccount" />} />
        <Route path="/AdminCSV" element={<AdminCSV />} />
        <Route
          exact
          path="/UserProfile/UserProfile"
          element={<UserProfile />}
        />
        <Route path="/EditPage/EditUser" element={<EditUser />} />
        <Route
          path="/CreateTempAccount/CreateTemporaryAccount"
          element={<CreateTemporaryAccount />}
        />
        <Route path="/Messages" element={<Messages />} />
        <Route path="/AddProject" element={<AddProject />} />
        <Route path="/ProjectTabbar" element={<ProjectTabbar />} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;
