import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { FiBell } from "react-icons/fi";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import NotificationSidebar from "../SidebarNotification/NotificationSidebar";
import avatar from "../../assets/icons/avatar.svg";
import { useNavigate } from "react-router-dom";
import { recognizeAccountType } from "../../redux/authSlice";
import {
  setIsNotifyOpen,
  getAllNotifications,
  GetAllNotificationCustomerAsync,
  GetAllNotificationBusinessAsync,
  SetPrevMesageAsyncs,
  chatSessionExpired,
} from "../../redux/chatSlice";
import { toast } from "react-toastify";
function Header({ HideImport }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isNotificationScreenOpen, setIsNotificationScreenOpen] =
    useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const { userDetails } = useSelector((state) => state.auth);
  const {
    isNotifyOpen,
    notifications,
    notificationToSHow,
    oldMessage,
    ChatRoomsUsers,
    SessionExpiredby,
  } = useSelector((state) => state.chat);
  const RenderCSV = () => {
    dispatch(recognizeAccountType(userDetails?.accountType));
    // dispatch(recognizeAccountType(adminData?.data?.accountType));
    navigate("/AdminCSV");
  };
  const url = window.location.href;
  const segments = url.split("/");
  const lastSegment = segments[segments.length - 1];
  const isOpen = () => {
    setIsNotificationScreenOpen(!isNotificationScreenOpen);

    if (isNotifyOpen) {
      dispatch(setIsNotifyOpen(false));
    }
  };

  let isReadAllNotification = notifications?.some((v) => !v.isRead);

  let count = 0;
  const unreadCount = notifications?.map((v) => {
    if (!v.isRead) {
      count = count + 1;
    }

    return count;
  });

  useEffect(() => {
    if (isReadAllNotification) {
      setIsNotificationOpen(true);
    }
    dispatch(getAllNotifications());
  }, [isNotificationScreenOpen, isNotificationOpen]);

  // useEffect(() => {
  //   if (
  //     notificationToSHow?.length > 0 &&
  //     userDetails?.accountType === "admin"
  //   ) {
  //     if (
  //       notificationToSHow[0]?.senderId !== userDetails?.id &&
  //       oldMessage !== notificationToSHow[0]?.text &&
  //       ChatRoomsUsers?.id === notificationToSHow[0]?.chatId &&
  //       notificationToSHow[0]?.projectId !== "101010"
  //     ) {
  //       // toast.success(`One new message - '${notificationToSHow[0]?.text}'`);
  //       dispatch(SetPrevMesageAsyncs(notificationToSHow[0]?.text));
  //     }
  //   }
  // }, [notificationToSHow]);
  //------------
  const hasSessionExpired = useRef(false);

  useEffect(() => {
    if (SessionExpiredby && !hasSessionExpired.current) {
      toast.success(`${SessionExpiredby} left the chat `);
      hasSessionExpired.current = true;
      dispatch(chatSessionExpired(""));
    }
  }, [SessionExpiredby]);

  useEffect(() => {
    dispatch(GetAllNotificationCustomerAsync());
    dispatch(GetAllNotificationBusinessAsync());
  }, []);
  // Broke image handling
  const FailedToLoad = (e) => {
    e.target.src = avatar;
  };


  return (
    <header className="header-container h-[70px] bg-white w-full flex justify-between ">
    
      {[
        "AdminManagement",
        "tempBusinessAccount",
        "tempCustomerAccount",
      ]?.includes(lastSegment) && (
        <button className="btn btn-success mx-4" onClick={RenderCSV}>
          Import
        </button>
      )}
      <div className="admin-details-area flex items-center justify-between w-40">
        <FiBell
          style={{ cursor: "pointer" }}
          color="#1A3447"
          onClick={isOpen}
           className="header-area"
        />
        {isReadAllNotification && (
          <span
            onClick={isOpen}
            className={`${count > 0 ? "bell_notify_count" : "bell_notify"}`}
          >
            {count > 0 ? count : "."}
          </span>
        )}
        {isNotificationScreenOpen && (
          <NotificationSidebar
            isNotificationScreenOpen={isNotificationScreenOpen}
            setIsNotificationScreenOpen={setIsNotificationScreenOpen}
            role={userDetails}
          />
        )}

        <img
          src={
            userDetails?.image
              ? userDetails?.image?.includes("https://")
                ? userDetails?.image &&
                  (userDetails.image.includes(".jpg") ||
                    userDetails.image.includes(".jpeg") ||
                    userDetails.image.includes(".png"))
                : "https://" + userDetails?.image
              : avatar
          }
          onError={FailedToLoad}
          className="profile-pic "
          alt="Admin"
        />
        <div className="NameContainer">
          <span className="name">
            {userDetails
              ? `${userDetails?.firstName} ${userDetails?.lastName}`
              : "Ella Jones"}
          </span>
          <span className="admin flex items-center">
            <img
              src={require("../../assets/icons/tick.svg").default}
              className="w-4 h-4 mr-1"
              alt="tick"
            />
            {userDetails?.accountType === "masteradmin"
              ? "Master Admin"
              : "Admin"}
          </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
