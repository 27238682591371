import React, { useState } from "react";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AddNewMessageAsync,
  EditMessageAsync,
  getMessageCustomerAsync,
} from "../../redux/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../helper/axiosInstance";
const Messages = ({
  setWriteMessage,
  setCheckMessage,
  SaveMessage,
  messageHeading,
  setMessageHeading,
  loopId,
  setState,
  Message
}) => {
  const [id, setId] = useState(loopId);
  const [textMessage, setTextMessage] = useState(id ? Message : "");
  const dispatch = useDispatch();
  const EditMessage = () => {
    let data = {
      id: id,
      msg: textMessage,
    };
    dispatch(EditMessageAsync(data)).then((r) => {
      if (r?.data?.success === true) {
        setId("");
        setState(false);
        dispatch(getMessageCustomerAsync());
      }
    });
  };
  const AddMessage = () => {
    dispatch(AddNewMessageAsync(textMessage));
  };

  return (
    <div
      style={{
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        top: "-5%",
        left: "35%",
        height: "100vh",
        zIndex: 9999999,
      }}
    >
      <div
        className="messageContainer"
        style={{
          width: "38rem",
          height: "23rem",
          margin: "50% auto",
          backgroundColor: "whitesmoke",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px 15px",
          }}
        >
          {!id && (
            <TextField
              style={{ margin: "auto 5px", backgroundColor: "transparent" }}
              id="standard-basic"
              label="Write Message"
              variant="standard"
              placeholder="Write Message"
              value={messageHeading}
              onChange={(e) => setMessageHeading(e.target.value)}
            />
          )}

          {id ? (
            <CloseIcon
              onClick={() => {
                setWriteMessage(false);
              }}
              style={{
                fontSize: 28,
                marginTop: "20px",
                marginLeft: " 5px",
                cursor: "pointer",
              }}
            />
          ) : (
            <CloseIcon
              onClick={() => {
                setCheckMessage(false);
              }}
              style={{
                fontSize: 28,
                marginTop: "20px",
                marginLeft: " 5px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div style={{ height: "100%", marginTop: "5%" }}>
          <div
            style={{
              width: "95%",
              height: "50%",
              margin: "0 auto",
              backgroundColor: "#eaf1f7",
            }}
          >
            <textarea
              placeholder="Compose an epic.."
              value={textMessage}
              onChange={(e) => setTextMessage(e.target.value)}
              // value={message}
              // onChange={(e) => setMessage(e.target.value)}
              style={{
                fontSize: 12,
                fontFamily: "sans-serif",
                fontWeight: "lighter",
                paddingTop: "15px",
                paddingLeft: "10px",
                outline: "none",
                width: "100%",
                height: "100%",
                resize: "none",
              }}
            />

            <div
              style={{
                width: "12rem",
                height: "3rem",
                display: "flex",
                justifyContent: "space-between",
                padding: "5px 20px",
                marginLeft: "70%",
                marginTop: "8px",
              }}
            >
              {id ? (
                <button
                  onClick={() => setWriteMessage(false)}
                  style={{
                    backgroundColor: "grey",
                    width: "4rem",
                    height: "2rem",
                    borderRadius: 5,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  Cancel
                </button>
              ) : (
                <button
                  onClick={() => setCheckMessage(false)}
                  style={{
                    backgroundColor: "grey",
                    width: "4rem",
                    height: "2rem",
                    borderRadius: 5,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  Cancel
                </button>
              )}

              {id ? (
                <button
                  onClick={() => {
                    SaveMessage();
                    EditMessage();
                  }}
                  style={{
                    backgroundColor: "#129160",
                    padding: "2px 10px",
                    width: "4rem",
                    height: "2rem",
                    borderRadius: 5,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  onClick={() => {
                    // SaveMessage();
                    setCheckMessage(false);
                    AddMessage();
                  }}
                  style={{
                    backgroundColor: "#129160",
                    padding: "2px 10px",
                    width: "4rem",
                    height: "2rem",
                    borderRadius: 5,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
