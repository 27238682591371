import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
import { Constants } from "../utils/Constants";

const initialState = {
  getAllStaticsLoading: false,
  getAllStaticsData: [],
  getAllStaticsError: "",
  totalAdminPage: 0,
  allAdminData: [],
  AdminPaginationDataLength: 0,
  adminStartedRecords: 1,
  adminToRecords: 20,

  allAdminDataBanking: [],
  AdminPaginationDataLengthBanking: 0,
  adminStartedRecordsBanking: 1,
  adminToRecordsBanking: 20,

  //
  getAllUserAccountLoading: false,
  getAllUsersData: [],
  getAllUsersFailed: "",
  //
  getBankingDataLoading: false,
  getBankingData: [],
  getBankingDataError: "",
  // Banking statatics 
  getBankingStaticsLoading: false,
  getBankingStatics: [],
  getBankingStaticsError: "",
};

export const staticsSlice = createSlice({
  name: "statics",
  initialState,
  reducers: {
    // Total
    getAllAdminSuccess: (state, action) => {
      state.getAllStaticsLoading = false;
      state.allAdminData = action.payload;
      console.log("mac", action.payload);
      state.AdminPaginationDataLength =
        action.payload[action.payload.length - 1];
      state.adminStartedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.adminToRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalAdminPage = Math.ceil(
        action.payload[action.payload.length - 1] / Constants.TAKE
      );
    },
    getAllAdminBankingSuccess: (state, action) => {
      state.getAllStaticsLoading = false;
      state.allAdminDataBanking = action.payload[0];
      state.AdminPaginationDataLengthBanking =
        action.payload[action.payload.length - 1];
      state.adminStartedRecordsBanking =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.adminToRecordsBanking = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalAdminPageBanking = Math.ceil(
        action.payload[action.payload.length - 1] / Constants.TAKE
      );
    },

    GetStatics: (state) => {
      state.getAllStaticsLoading = true;
    },
    GetStaticsSuccess: (state, action) => {
      state.getAllStaticsLoading = false;
      state.getAllStaticsData = action.payload;
    },
    GetStaticsFailed: (state, action) => {
      state.getAllStaticsLoading = false;
      state.getAllStaticsError = action.payload;
    },
    // Users
    GetAlluserDetail: (state) => {
      state.getAllUserAccountLoading = true;
    },
    GetAllUserDetailSuccess: (state, action) => {
      state.getAllUsersData = action.payload[0];
      console.log("action.payload", action.payload);
      state.AdminPaginationDataLength =
        action.payload[action.payload.length - 1];
      state.adminStartedRecords =
        (action.payload.CurrentPage - 1) * Constants.TAKE + 1;
      state.adminToRecords = Math.min(
        action.payload.CurrentPage * Constants.TAKE,
        action.payload[action.payload.length - 1]
      );
      state.totalAdminPage = Math.ceil(
        action.payload[action.payload.length - 1] / Constants.TAKE
      );
      // state.getAllUsersData = action.payload?.filter((Data) => {
      //   return (
      //     Data.TotalBusiness !== 0 ||
      //     Data?.TotalCustomers !== 0 ||
      //     Data?.TotalProjects !== 0
      //   );
      // });
      state.getAllUserAccountLoading = false;
    },
    GetAllUserDetailFailed: (state, action) => {
      state.getAllUserAccountLoading = false;
      state.getAllUsersFailed = action.payload;
    },
    // Banking
    GetBankingDetail: (state) => {
      state.getBankingDataLoading = true;
    },
    GetBankingDetailSuccess: (state, action) => {
      state.getBankingDataLoading = false;
      state.getBankingData = action.payload;
    },
    GetBankingDetailFailed: (state, action) => {
      state.getBankingDataLoading = false;
      state.getBankingDataError = action.payload;
    },
    // Bacnking Statics 
    GetBankingStatatics: (state) => {
      state.getBankingStaticsLoading = true;
    },
    GetBankingStataticsSuccess: (state, action) => {
      state.getBankingStaticsLoading = false;
      state.getBankingStatics = action.payload;
    },
    GetBankingStataticsFailed: (state, action) => {
      state.getBankingStaticsLoading = false;
      state.getBankingStaticsError = action.payload;
    },
  },
});

export const getAllStaticsDataAsync = () => (dispatch) => {
  dispatch(GetStatics());
  axiosInstance
    .get("/Dashboard/numOfUsers")
    .then((response) => {
      console.log(response);
      dispatch(GetStaticsSuccess(response.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(GetStaticsFailed());
    });
};

export const getAllUsersDataAsync =
  (data, take, skip, CurrentPage) => (dispatch) => {
    dispatch(GetAlluserDetail());
    axiosInstance
      .get("/Dashboard/searchUserAccount", {
        params: {
          State: data?.location,
          month: data?.FromDate,
          page: CurrentPage,
          perPage: take,
          Type: data?.Type
        },
      })
      .then((response) => {
        console.log('response,,,,', response)
        response.data.CurrentPage = CurrentPage;
        dispatch(GetAllUserDetailSuccess(response?.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(GetAllUserDetailFailed());
      });
  };

  export const getAllBankingDataAsync = (data) => (dispatch) => {
    dispatch(GetBankingDetail());
    axiosInstance
      .get("/Merchant/GetPaymentDetails", {
        params: {
          title: data?.title,
          page: data?.page,
          perPage: data?.perPage,
          type: data?.type,
          month: data?.month,
        },
      })
      .then((response) => {
        console.log("response", response);
        response.data.CurrentPage = data?.page;
        dispatch(GetBankingDetailSuccess(response?.data));
        dispatch(getAllAdminBankingSuccess(response?.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(GetBankingDetailFailed());
      });
  };
  export const getAllBankingStatics = (data) => (dispatch) => {
    dispatch(GetBankingStatatics());
    axiosInstance
      .get("/Merchant/getPaymentDetailsStatistics", {
        params: {
          month: data?.month,
        },
      })
      .then((response) => {
        console.log('response>>>>>>>>>>>>>>>>>>', response)
        dispatch(GetBankingStataticsSuccess(response?.data));
      })
      .catch((err) => {
        console.log('err>>>>>>>>>>>>>>>>>>', err)
        dispatch(GetBankingStataticsFailed(err));
      });
  };
// Action creators are generated for each case reducer function
export const {
  GetBankingStatatics,
  GetBankingStataticsSuccess,
  GetBankingStataticsFailed,
  GetStatics,
  GetStaticsSuccess,
  GetStaticsFailed,
  GetAlluserDetail,
  GetAllUserDetailSuccess,
  GetAllUserDetailFailed,
  GetBankingDetail,
  GetBankingDetailSuccess,
  GetBankingDetailFailed,
  getAllAdminSuccess,
  getAllAdminBankingSuccess,
} = staticsSlice.actions;

export default staticsSlice.reducer;
