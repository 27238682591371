import * as React from "react";
import "./OTPSent.scss";
import Dialog from "@mui/material/Dialog";
import cancelOTP from "../../assets/icons/cancelOTP.svg";
import Timer from "../../assets/icons/timer.svg";
import CopyIcon from "../../assets/icons/GreenCopy.svg";
import { useSelector } from "react-redux";
import Sad from "../../assets/icons/sick.gif";

function OTPSent(props) {
  const { RequestOTPdata, RequestOtpError } = useSelector(
    (state) => state.generateOTP
  );
  const { selectedEmail } = useSelector((state) => state.adminManage);
  const { open, setOpen } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {!(RequestOTPdata.userEmail === selectedEmail) ? (
        <Dialog onClose={handleClose} open={open}>
          <div className="OTPBoxError">
            <img
              className="Cross"
              src={cancelOTP}
              onClick={() => setOpen(false)}
              alt="cancel"
            />
            <img className="SadIcon" src={Sad} alt="Sorry" />
            <h3>{RequestOtpError.message}</h3>
            <h5 onClick={() => setOpen(false)}>Close</h5>
          </div>
        </Dialog>
      ) : (
        <Dialog onClose={handleClose} open={open}>
          <div className="OTPBox">
            <img
              className="Cross"
              src={cancelOTP}
              onClick={() => setOpen(false)}
              alt="cancel"
            />
            <h3>Your OTP</h3>
            <div className="OTPandCopy">
              <h1>{RequestOTPdata.otp}</h1>
              <img
                onClick={() => {
                  navigator.clipboard.writeText(RequestOTPdata.otp);
                  alert(` OTP Copied`);
                }}
                alt="Copy OTP"
                src={CopyIcon}
              />
            </div>
            <div className="OTPValid">
              <img src={Timer} alt="Timer" />
              <p>OTP valid for 10 mints</p>
            </div>
            <h4 onClick={() => setOpen(false)}> Close</h4>
          </div>
        </Dialog>
      )}
    </>
  );
}

export default OTPSent;
