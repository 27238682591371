import React from 'react'
import "./Docs.scss";
import Dialog from "@mui/material/Dialog";
import Slider from "react-slick";
const ImageSlider = ({ verificationData, openVerification_link, openDialog, setOpenDialog }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };
    console.log('verificationData_', verificationData)

    return (


        <Dialog
            fullWidth={true}
            maxWidth="md"
            open={openDialog}
            onClose={() => setOpenDialog(false)}
        >
            <div className="image-slider">
                <Slider  {...settings}>
                    {verificationData?.map((imageUrl, index) => (
                        <div className='Slider_Container' key={index}>
                            <img src={"https://" + imageUrl} alt='docs' />
                            {/* {
                                openVerification_link?.map((link,) => (
                                    <div className="common_button_image">
                                        <p onClick={() => window.open(
                                            "https://" + link)}> Download</p>
                                    </div>
                                ))
                            } */}
                        </div>
                    ))}

                </Slider >
            </div>
        </Dialog>



    )
}

export default ImageSlider;