import * as React from "react";
import { useState } from "react";
import "./GenrateOTPDialog.scss";
import Dialog from "@mui/material/Dialog";
import OTPSent from "../OTPSent";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { getReqOTPAsysnc } from "../../../redux/OTPSlice";

function GenrateOTPDialog(props) {
  const { open, setOpen, onClose, selectedValue } = props;
  const [send, setSend] = useState(false);
  const dispatch = useDispatch();
  const { RequestOTPLoading, RequestOtpError, RequestOTPdata } = useSelector(
    (state) => state.generateOTP
  );
  const { selectedEmail } = useSelector((state) => state.adminManage);

  const SendOTP = (e) => {
    e.preventDefault();
    dispatch(getReqOTPAsysnc(selectedEmail.trim()));
    setSend(true);
  };
  const handleClose = () => {
    onClose(selectedValue);
  };
  const Close = () => {
    setOpen(false);
  };
  return (
    <>
      <div>
        {RequestOTPLoading ? (
          <Loader />
        ) : (
          <>
            <Dialog onClose={handleClose} open={open}>
              <div className="DialogBoxOTP">
                <h3>Generate OTP</h3>
                <div className="UserEmail">
                  <p className="UserEmailText">Email</p>
                  <input
                    type="text"
                    value={selectedEmail}
                    placeholder={selectedEmail}
                    disabled
                  />
                </div>
                <div className="BottomOTPSec">
                  <div>
                    {RequestOTPdata ? <p>OTP fetched successfully</p> : null}
                    {RequestOtpError ? <p>{RequestOtpError.message}</p> : null}
                  </div>

                  <div className="btnView">
                    <button onClick={Close} className="closeButton">
                      Close
                    </button>
                    <button onClick={SendOTP} className="SendOTP">
                      Send OTP
                    </button>
                  </div>
                </div>
              </div>
            </Dialog>
            <OTPSent open={send} setOpen={setSend} onClose={handleClose} />
          </>
        )}
      </div>
    </>
  );
}

export default GenrateOTPDialog;
