import React, { useState, useEffect } from "react";
import { Slide } from "react-slideshow-image";
import "./Draggbleimage.scss";
const DraggableImage = ({ openDialog, setOpenDialog, verificationData }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [size, setSize] = useState({ width: "800px", height: "550px" });
  const [dragging, setDragging] = useState(false);
  const [resizing, setResizing] = useState(false);

  useEffect(() => {
    if (openDialog) {
      const centerX = (window.innerWidth - 800) / 2;
      const centerY = (window.innerHeight - 600) / 2;
      setPosition({ x: centerX, y: centerY });
    }
  }, [openDialog]);

  const noSelectStyle = {
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    MsUserSelect: "none",
  };

  const applyNoSelect = () => {
    for (const key in noSelectStyle) {
      document.body.style[key] = noSelectStyle[key];
    }
  };

  const removeNoSelect = () => {
    for (const key in noSelectStyle) {
      document.body.style[key] = "";
    }
  };

  const handleDragStart = (e) => {
    if (!resizing) {
      setDragging(true);
      applyNoSelect();
      const offsetX = e.clientX - position.x;
      const offsetY = e.clientY - position.y;
      const handleDrag = (e) => {
        setPosition({
          x: e.clientX - offsetX,
          y: e.clientY - offsetY,
        });
      };
      const handleDragEnd = () => {
        setDragging(false);
        removeNoSelect();
        document.removeEventListener("mousemove", handleDrag);
        document.removeEventListener("mouseup", handleDragEnd);
      };
      document.addEventListener("mousemove", handleDrag);
      document.addEventListener("mouseup", handleDragEnd);
    }
  };

  const handleResizeStart = (e) => {
    setResizing(true);
    applyNoSelect();
    const initialX = e.clientX;
    const initialWidth = parseFloat(size.width); // Store the initial width when resizing starts
    const handleResize = (e) => {
      let newWidth = initialWidth + (e.clientX - initialX);
      if (newWidth < 800) {
        newWidth = 700; // Adjust the minimum width here
      }
      setSize({
        width: `${newWidth}px`,
        height: "550px",
      });
    };
    const handleResizeEnd = () => {
      setResizing(false);
      removeNoSelect();
      document.removeEventListener("mousemove", handleResize);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
    document.addEventListener("mousemove", handleResize);
    document.addEventListener("mouseup", handleResizeEnd);
  };

  const watermerk_image =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png";
  const handleLinkClick = (url) => {
    window.open("https://" + url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      {openDialog ? (
        <div
          style={{
            position: "absolute",
            left: position.x,
            top: position.y,
            width: size.width,
            height: size.height,
            cursor: resizing ? "nwse-resize" : dragging ? "grabbing" : "grab",
            backfaceVisibility: "transparent",
            zIndex: 99999999,
            border: "1px solid black",
            backgroundColor: "#E8E8E8",
            overflow: "hidden",
          }}
          onMouseDown={handleDragStart}
        >
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "space-between",
              alignSelf: "center",
              position: "relative",
              left: "40%",
              padding: "0.3rem 0.8rem",
              marginTop: "0.5rem",
            }}
          >
            <p style={{ borderBottom: "2px solid grey", color: "#129160" }}>
              Documents
            </p>
            <div
              onClick={() => setOpenDialog(false)}
              style={{
                cursor: "pointer",
                backgroundColor: "red",
                borderRadius: "1.5rem",
                padding: "0rem 1rem",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0.2rem",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontSize: "0.8rem",
                  fontFamily: "sans-serif",
                }}
              >
                Close
              </p>
            </div>
          </div>
          <div className="Images_demo_container">
            <Slide autoplay={false} infinite={false} transitionDuration={500}>
              {verificationData?.map((img, i) => (
                <div key={i} className="Image_demoDrag">
                  {img !== "null" ? (
                    <div onClick={() => handleLinkClick(img)}>
                      <div className="Image_scroll_containerDrag">
                        <img
                          src={"https://" + img}
                          alt="Document"
                          className="Image_scrollDrag"
                        />
                      </div>
                      <h3>Document {i + 1} </h3>
                    </div>
                  ) : (
                    <div className="Image_demoDrag">
                      <div className="Image_scroll_containerDrag">
                        <img
                          src={watermerk_image}
                          alt="Document"
                          className="Image_scrollDrag"
                        />
                      </div>
                      <h3>NO documents uploaded</h3>
                    </div>
                  )}
                </div>
              ))}
            </Slide>
          </div>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "10px",
              height: "10px",
              background: "blue",
              cursor: "nwse-resize",
            }}
            onMouseDown={handleResizeStart}
          />
        </div>
      ) : null}
    </>
  );
};

export default DraggableImage;
