import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../helper/axiosInstance";
const initialState = {
  RequestOTPLoading: false,
  RequestOTPdata: "",
  RequestOtpError: "",
  //------Regenerate OTP --------------
  RegenerateOTPLoading: false,
  RegenerateOTPData: "",
  RegenerateOTPError: "",
};
export const OTPSlice = createSlice({
  name: "generateOTP",
  initialState,
  reducers: {
    reqestOTP: (state) => {
      state.RequestOTPLoading = true;
      state.RegenerateOTPData = "";
      state.RequestOtpError = "";
    },
    reqestOTPSuccess: (state, action) => {
      state.RequestOTPdata = action.payload;
      state.RequestOTPLoading = false;
    },
    reqestOTPFailed: (state, action) => {
      state.RequestOtpError = action.payload;
      state.RequestOTPLoading = false;
    },
    regenerateOTP: (state, action) => {
      state.RegenerateOTPLoading = true;
    },
    regenerateOTPSuccess: (state, action) => {
      state.RegenerateOTPLoading = false;
      state.RegenerateOTPData = action.payload;
      state.RegenerateOTPError = action.payload;
    },
    regenerateOTPfailed: (state, action) => {
      state.RegenerateOTPError = action.payload;
      state.RegenerateOTPLoading = false;
    },
    resetOtpState: (state, action) => {
      state.RequestOTPLoading = false;
      // state.RequestOTPdata = "";
      // state.RequestOtpError = "";
      state.RegenerateOTPLoading = false;
      // state.RegenerateOTPData = "";
      // state.RegenerateOTPError = "";
    },
  },
});
export const getReqOTPAsysnc = (emailAddress) => (dispatch) => {
  // console.log(emailAddress);
  console.log("otp action called");
  dispatch(reqestOTP());
  axiosInstance
    .get("/AdminConroller/GetOtpForAdmin", {
      params: {
        emailAddress,
      },
    })
    .then((resp) => {
      dispatch(reqestOTPSuccess(resp.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(reqestOTPFailed(err.response.data));
    });
};

export const regenerateOTPDataAsysnc = (userId) => async (dispatch) => {
  console.log(userId);
  dispatch(regenerateOTP());

  try {
    const resp = await axiosInstance.post("/AdminConroller/RegenerateOtp", {
      userId,
    });
    if (resp?.status === 200 || 201) {
      dispatch(regenerateOTPSuccess(resp.data.data));
      return resp?.data?.data;
    }
  } catch (err) {
    console.log(err);
    dispatch(regenerateOTPfailed(err.response.data));
  }
};
export const {
  reqestOTP,
  reqestOTPSuccess,
  reqestOTPFailed,
  regenerateOTP,
  regenerateOTPSuccess,
  regenerateOTPfailed,
  resetOtpState,
} = OTPSlice.actions;

export default OTPSlice.reducer;
