import React from "react";
import "./DeleteSuccess.scss";
import Dialog from "@mui/material/Dialog";
import cross from "../../../assets/icons/cross.svg";
import Deleted from "../../../assets/icons/Deleted.svg";

const DeleteSuccess = ({ open, setOpen, handleClose }) => {
  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="DeleteSuccess">
        <div className="cancel">
          <img onClick={() => setOpen(false)} src={cross} alt="cancel" />
        </div>
        <div className="DeleteFrame">
          <img src={Deleted} alt="Deleting" />
        </div>
        <div className="textSuccess">
          <p>Deleted Successfully</p>
        </div>
        <div className="buttonDiv">
          <div onClick={() => setOpen(false)} className="buttonClose">
            Close
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteSuccess;
