import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import "./LoginHistory.scss";
import cancelOTP from "../../../assets/icons/cancelOTP.svg";
import search from "../../../assets/icons/search.svg"
import FilterIcon from "../../../assets/icons/FilterIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import calenderIcon from "../../../assets/icons/calender.svg";
import Calendar from "moedim";

const LoginHistory = ({ logHistory, setLogHistory }) => {
  const { selectedId, loginHistoryData, loginHistoryLoading } = useSelector(
    (state) => state.adminManage
  );
  const dispatch = useDispatch()
  // Pagination -----------
  const [currentData, setCurrentData] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [imagesOffset, setImagesOffset] = useState(0);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('Login');
  const [fromDateValue, setFromDateValue] = useState(new Date());
  const [toDateValue, setToDateValue] = useState(new Date());
  const [openToDate, setOpenToDate] = useState(false);
  const [openFromDate, setOpenFromDate] = useState(false)
  const endOffset = imagesOffset + 8;

  const handlePageClick = (event) => {
    const newOffset = (event?.selected * 8) % loginHistoryData?.length;
    setImagesOffset(newOffset);
  };


  useEffect(() => {
    setCurrentData(loginHistoryData.slice(imagesOffset, endOffset))
    setPageCount(Math.ceil(loginHistoryData.length / 8));
  }, [])
  const handleClose = () => {
    setLogHistory(false);
  };
  // const handleChangeSelect = (e) => {
  //   e.preventDefault()
  //   setFilter((e.target.value));
  // };
  // console.log('filter', filter)
  // const handleCloseSelect = () => {
  //   setOpen(false);
  //   if (filter === 'Login') {
  //     const LoginData = loginHistoryData?.filter((i) => i.LoginLogout === 1);
  //     setCurrentData(LoginData.slice(imagesOffset, endOffset))
  //     setPageCount(Math.ceil(LoginData.length / 8));
  //   } else if (filter === 'Logout') {
  //     const LogoutData = loginHistoryData?.filter((i) => i.LoginLogout === 0);
  //     setCurrentData(LogoutData.slice(imagesOffset, endOffset))
  //     setPageCount(Math.ceil(LogoutData.length / 8));
  //   } else {
  //     setCurrentData(loginHistoryData.slice(imagesOffset, endOffset));
  //     setPageCount(Math.ceil(loginHistoryData.length / 8));
  //   }

  // };
  //----

  const FromDateClose = () => {
    setOpenFromDate(false)
    const formattedToDate = fromDateValue.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

  };
  const ToDateClose = () => {
    setOpenToDate(false)
    const formattedFromDate = toDateValue.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };
  const formatDate = (dateString) => {
    const [date] = dateString.split(', ');
    const [month, day, year] = date.split('/');
    return `${day}/${month}/${year}`;
  };

  // Formated time 
  const formatTime = (time) => {
    const timePart = time.split(", ")[1];
    return timePart;
    // const dateTime = new Date(time);
    // const hours = dateTime.getHours();
    // const minutes = dateTime.getMinutes();
    // const ampm = hours >= 12 ? "PM" : "AM";
    // const formattedHours = hours % 12 || 12;
    // const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    // return formattedTime
  };
  
  console.log("loginHistoryData",loginHistoryData)
  return (
    <Dialog maxWidth="lg" open={logHistory} onClose={handleClose}>
      {loginHistoryLoading ? <Loader /> : null}
      <div className="LHContainer">
        <div className="topItems">
          <div className="Headings">
            <h3>Login Logout History</h3>
            <p>Recently First</p>
          </div>
          <div className="searchContainer">

            <div className="FilterBox">
              <h2>From Date</h2>
              <img
                onClick={() => setOpenFromDate(true)}
                className="CalnederIcon"
                src={calenderIcon}
                alt="select "
              />
            </div>
            <div className="FilterBox">
              <h2>To Date</h2>
              <img
                onClick={() => setOpenToDate(true)}
                className="CalnederIcon"
                src={calenderIcon}
                alt="select"
              />
            </div>

            <img
              onClick={() => setLogHistory(false)}
              className="Cross"
              src={cancelOTP}
              alt="cancel"
            />
          </div>
          <Dialog
            open={openFromDate} onClose={FromDateClose}>
            <Calendar value={fromDateValue} onChange={(d) => setFromDateValue(d)} />
          </Dialog>
          <Dialog
            open={openToDate} onClose={ToDateClose}>
            <Calendar value={toDateValue} onChange={(d) => setToDateValue(d)} />
          </Dialog>
        </div>
        <table style={{ width: "100%", marginTop: "1%" }}>
          {/* <Dialog disableEscapeKeyDown
          // open={open} onClose={handleCloseSelect}
          >
            <DialogContent>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel htmlFor="demo-dialog-native">Filter By</InputLabel>
                  <Select
                    native
                    value={filter}
                    onChange={handleChangeSelect}
                    input={
                      <OutlinedInput label="Filter by" id="demo-dialog-native" />}
                  >
                    <option value='Login'>Login</option>
                    <option value='Logout'>Logout</option>
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSelect}>Cancel</Button>
              <Button onClick={handleCloseSelect}>Ok</Button>
            </DialogActions>
          </Dialog> */}
          <thead style={{ height: 50 }}>
            <tr >
              <th >
                <span className="flex item-center ml-2">
                  <p style={{ marginLeft: "1rem" }}>Ip Address</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Date</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Time</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th
              //  onClick={() => setOpen(true)}
              >
                <span className="flex item-center ml-2">
                  {
                    filter === 'Login' ? (<p>Login</p>) : null || filter === 'Logout' ? (<p>Logout</p>) : null || filter === '' || null ? <p> Login/Logout</p> : null
                  }
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
              <th>
                <span className="flex item-center ml-2">
                  <p>Name</p>
                  <img src={FilterIcon} alt="Filter" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {loginHistoryData?.map((item, i) => (
              <tr key={i}>
                <td>
                  <span className="flex item-center ml-4">{item.IPAddress}</span>
                </td>
                <td> <span className="flex item-center ml-2">{formatDate(item.Date)}</span></td>
                <td><span className="flex item-center ml-2">{formatTime(item.Date)}</span></td>
                <td>   {item.LoginLogout === 1 ? (
                  <span className="flex item-center ml-2 text-green-700"> Login</span>
                ) : (
                  <span className="flex item-center ml-2 text-red-700">Logout</span>
                )}</td>
                <td> <span className="flex item-center ml-2">{item.username}</span></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      /> */}
    </Dialog >
  );
};

export default LoginHistory;
