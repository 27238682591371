import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import categorySlice from "./categorySlice";
import OTPSlice from "./OTPSlice";
import adminManagementSlice from "./adminManagementSlice";
import announcementSlice from "./announcementsSlice";
import usersSlice from "./usersSlice";
import locationsSlice from "./locationsSlice";
import { persistStore, persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import staticsSlice from "./staticsSlice";
import projectSlice from "./projectSlice";
import chatSlice from "./chatSlice";
import adSlice from "./adSlice";
import socketReducer from "./socketSlice";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
};
const rootReducer = combineReducers({
  auth: authSlice,
  category: categorySlice,
  adminManage: adminManagementSlice,
  generateOTP: OTPSlice,
  announcement: announcementSlice,
  users: usersSlice,
  statics: staticsSlice,
  project: projectSlice,
  chat: chatSlice,
  ad: adSlice,
  socket: socketReducer,
  location: locationsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);
export default store;
