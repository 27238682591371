import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import DeleteModalPic from "../../assets/icons/delete-modal-pic.svg";
import Cross from "../../assets/icons/cross.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DelSubCategoryModal({
  subCategorySelected,
  handleDelSubCategoryClose,
  delSubCategory,
  openDelModal,
}) {
  return (
    <Modal
      open={openDelModal}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-end"
        >
          <button onClick={handleDelSubCategoryClose}>
            <img src={Cross} alt="cross" />
          </button>
        </div>
        <div id="modal-modal-description" className="mt-4" sx={{ mt: 2 }}>
          <img src={DeleteModalPic} alt="delete modal pic" className="mb-4" />

          <p>
            Are you sure you want to delete this sub category "
            {subCategorySelected.name}"?
          </p>
        </div>
        <div
          id="modal-modal-footer"
          sx={{ mt: 2 }}
          className="flex justify-end items-center mt-4"
        >
          <button
            className=" p-2 cursor-pointer  text-[#00309F] mr-2"
            onClick={handleDelSubCategoryClose}
          >
            No, Cancel
          </button>
          <button
            className="bg-[#FF3232] text-white p-2 cursor-pointer rounded-md"
            onClick={delSubCategory}
          >
            Yes Delete
          </button>
        </div>
      </Box>
    </Modal>
  );
}

export default DelSubCategoryModal;
