import React, { useState, useEffect } from "react";
import "./LocationBy.scss";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader";
import { SelectedFilterLocation } from "../../../redux/usersSlice";
const LocationBy = ({ openLocationBy, setOpenLocationby, sendData }) => {
  const { AllCitiesData, AllCitesLoading } = useSelector(
    (state) => state.announcement
  );
  const { getAllCitiesData, getAllCitiesLoading } = useSelector(
    (state) => state.location
  );
  const { selectedLocationForFilter } = useSelector((state) => state.users);
  const [filteredCities, setFilteredCities] = useState(AllCitiesData);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [IsShort, setIsShort] = useState(false);
  const [data, setData] = useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemsPerPage = 10;
  const containerHeight = 250;
  const itemHeight = containerHeight / itemsPerPage;

  useEffect(() => {
    dispatch(SelectedFilterLocation(null));
    setIsShort(false);
  }, []);

  useEffect(() => {
    if (openLocationBy) {
      setFilteredCities(AllCitiesData);
    }
  }, [openLocationBy, AllCitiesData]);

  const SeacrhQuery = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = AllCitiesData?.filter((item) =>
      item.shortNameOfState.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const GetingLocation = (item) => {  
    dispatch(SelectedFilterLocation(item?.state));
    setOpenLocationby(false);
    setSearchQuery("");
    setFilteredCities(AllCitiesData);
  };
  // Pagination----
  useEffect(() => {
    const randomData = getAllCitiesData.map((item, index) => {
      return { item, index };
    });

    setData(randomData);
  }, []);

  useEffect(() => {
    const startIndex = scrollPosition * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleItems = data.slice(startIndex, endIndex);
  }, [scrollPosition, data]);

  const handleScroll = (event) => {
    const { scrollTop } = event.target;
    const newPosition = Math.floor(scrollTop / itemHeight);
    setScrollPosition(newPosition);
  };


  const handleClick = () => {
    setOpenLocationby(false);
    // setIsShort(false);
  };
  return (
    <Dialog
      PaperProps={{
        sx: {
          position: "fixed",
          right: 10,
          m: 0,
          top: "30%",
        },
      }}
      open={openLocationBy}
      onClose={handleClick}
    >
      {getAllCitiesLoading || AllCitesLoading ? Loader : null}
      <div className="InputDiv">
        <img src={SearchIcon} alt="Search" />
        <input
          value={searchQuery}
          onChange={SeacrhQuery}
          type="text"
          placeholder="Search"
        />
      </div>
      <div
        className="LocationDiv"
        style={{ height: `${containerHeight}px`, overflowY: "scroll" }}
        onScroll={handleScroll}
      >
        {!IsShort && (
          <FormControl>
            {Array.isArray(filteredCities) &&
              filteredCities
                .slice()
                .sort((a, b) => {
                  const nameA = a.shortNameOfState?.toLowerCase();
                  const nameB = b.shortNameOfState?.toLowerCase();
                  if (nameA < nameB) return -1;
                  if (nameA > nameB) return 1;
                  return 0;
                })
                .map((item, i) => (
                  <div
                    onClick={() => GetingLocation(item)}
                    key={i}
                    className="LocationName"
                  >
                    {item?.shortNameOfState}
                  </div>
                ))}
          </FormControl>
        )}
      </div>
    </Dialog>
  );
};

export default LocationBy;
