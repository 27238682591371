import React from "react";
// import Container from "../../../components/HOC/Container";
import "./Admincsv.scss";
import CsvUploaderModal from "../../../components/CSV/CsvUploaderModal.jsx";

function AdminCSV() {
  return (
    <>
      <CsvUploaderModal />
    </>
  );
}

export default AdminCSV;
