import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";
import "./AddSecondaryEmail.scss";
import {
  ChangesecondaryemailAsync,
  connectSocket,
  getAllNotifications,
  UpdateNotifications,
} from "../../../redux/chatSlice";
import { UpdateStatusAsync } from "../../../redux/usersSlice";
import Setting from "../../../assets/icons/ChangeEmail.svg";
// import avatar from "../../../assets/icons/avatar.svg";
// import avatarlight from "../../../assets/icons/avatarlight.svg";
import moment from "moment";
import { displayImages } from "../../../components/CheckImage";
import { formatDate } from "../../../components/Utils/formatDate";
// import axios from "axios";
import Swal from "sweetalert2";
import Docs from "../../../components/Dialogs/ViewFiles/Docs/Docs";

const AddSecondaryEmail = ({ role }) => {
  const navigate = useNavigate();
  const { userDetails, updateProfileLoading } = useSelector(
    (state) => state.users
  );
  const {
    notifications,
    AddSecondryEmailLoading,
    getnotificationLaoding,
    isNotificationLoading,
    notificationErr,
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const { AllCitiesData, AllCitesLoading } = useSelector(
    (state) => state.announcement
  );
  const { categoryData, subCategoryData } = useSelector(
    (state) => state.category
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [UserId, setUserID] = useState();
  const [SecondaryEmail, setSecondaryEmail] = useState("");
  const [verificationData, setVerificationData] = useState([]);

  const userLoggedDetails = JSON.parse(
    sessionStorage.getItem("userLoggedDetails")
  );
  const changeHandler = (e) => {
    setSecondaryEmail(e.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = () => {
    if (SecondaryEmail) {
      dispatch(ChangesecondaryemailAsync(SecondaryEmail)).then((r) => {
        if (r?.status === 200 || r?.status === 201) {
          setIsModalOpen(false);
          toast.success("Updated Successfully");
          setSecondaryEmail("");
        }
      });
    } else {
      toast.error("Please write email");
    }
  };
  const gotoNotiScreen = (value) => {
    console.log("RRvalue", value);

    if (value?.businessUserTbl) {
      setOpenDialog(true);
      setUserID(value?.businessUserTbl?.userId);
      setVerificationData(value?.businessUserTbl?.ShowDocsUrl);
    } else {
      setOpenDialog(true);
      setUserID(value?.customerTbl?.userId);
      setVerificationData(value?.customerTbl?.ShowDocsUrl);
    }

    const data = {
      id: value?.id,
      isRead: true,
      readBy: [role?.id],
    };

    dispatch(UpdateNotifications(data));

    let accType;
    let id;
    if (value?.notificationProvider === 2) {
      accType = "customer";
      id = value?.customerTbl?.id;
    } else if (value?.notificationProvider === 3) {
      accType = "business";
      id = value?.businessUserTbl?.id;
    }

    const rdata = {
      accountType: accType,
      id: id,
      isAdminApproved: true,
      isReview: true,
    };

    dispatch(UpdateStatusAsync(rdata));
  };

  const renderNotificationContent = (value, i) => {
    let url = value?.businessUserTbl
      ? value?.businessUserTbl?.image
      : value?.customerTbl?.image;
    // let images;
    let displayImage;
    if (url) {
      // images = checkImage(url);
      displayImage = displayImages(url);
    }

    moment(parseInt(value?.updatedAt, 10) * 1000).format("LT");

    const timestamp = parseInt(value?.updatedAt, 10) * 1000;
    // const time = moment(timestamp).format("LT");
    const timeInDay = formatDate(timestamp);
    const validImageExtensions = ["jpeg", "jpg", "png"];
    const isValidImage =
      displayImage &&
      validImageExtensions.some((ext) =>
        displayImage.toLowerCase().endsWith(ext)
      );

    const imageSrc = isValidImage
      ? displayImage
      : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

    return (
      <div
        style={{ borderRadius: "1rem", margin: "0.5rem 0rem" }}
        className={`${value.isRead ? "isRead-noti-content" : "noti-content"}`}
        key={i}
      >
        <div className="noti_inner">
          <img
            style={{
              width: "1rem",
              height: "1rem",
              marginRight: "2%",
              borderRadius: "50%",
            }}
            src={imageSrc}
            alt="Settings Icon"
            onError={(e) => {
              e.target.src =
                "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";
            }}
          />
          <span
            style={{
              width: "90%",
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {`${
                value?.businessUserTbl?.userId
                  ? value?.businessUserTbl?.userId
                  : value?.customerTbl?.userId
              } `}
            </span>
            {value.notificationMsg}
          </span>
        </div>
        <div className="footer_notify">
          <span
            style={{
              fontSize: "0.5rem",
              color: "#616b7a !important",
              fontFamily: "roboto !important",
            }}
          >
            {timeInDay}
          </span>
          <span
            style={{
              cursor: "pointer",
              color: value.isRead ? "#9898e3" : "#1d0fff",
              marginLeft: "2px",
              fontSize: "0.6rem",
              textDecoration: "underline",
            }}
            onClick={() => gotoNotiScreen(value)}
          >
            View
          </span>
        </div>
      </div>
    );
  };

  return (
    <ContainerWithoutFooter>
      {updateProfileLoading || AllCitesLoading || AddSecondryEmailLoading ? (
        <Loader />
      ) : null}
      <div className="main">
        <div className="Notification_header">
          <span>Notifications</span>
          <img
            src={Setting}
            alt="Settings Icon"
            width="3%"
            height="3%"
            onClick={openModal}
          />
        </div>
        <div className="main_content">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="SecEmail">
              {userLoggedDetails.secondaryEmail && (
                <h1>
                  <strong>Secondary Email:</strong>{" "}
                  {userLoggedDetails.secondaryEmail}
                </h1>
              )}
            </div>
            <div className="Container">
              <button
                style={{
                  padding: "0.5rem 1rem",
                  background: "#38ab7c",
                  borderRadius: "20%",
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  textAlign: "center",
                }}
                onClick={openModal}
              >
                +
              </button>
              {userLoggedDetails.secondaryEmail ? (
                <h1>Update Email for Notifications</h1>
              ) : (
                <h1>Add Email for Notifications</h1>
              )}
            </div>
          </div>
          <div
            style={{
              margin: "0.3rem 0rem",
              // display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "1rem",
              width: "100%",
            }}
          >
            {isNotificationLoading && <Loader />}

            {!isNotificationLoading &&
            notifications !== undefined &&
            notifications.length > 0 ? (
              notifications.map((value, i) => (
                <div>{renderNotificationContent(value, i)}</div>
              ))
            ) : (
              <div className="Empty_Container">
                <p style={{ fontSize: "0.9rem" }}>{notificationErr}</p>
              </div>
            )}
          </div>

          <Docs
            verificationData={verificationData}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            UserId={UserId}
          />
          {isModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <button className="close-button" onClick={closeModal}>
                  Close
                </button>
                <div className="EmailVerifyDiv">
                  <h1>
                    {notifications.length > 0
                      ? "Change Email for Notifications"
                      : "Add Email for Notifications"}
                  </h1>
                  <div className="emailDiv">
                    <div className="emails">
                      <p>New Email</p>
                      <input
                        type="text"
                        placeholder="Enter here"
                        value={SecondaryEmail}
                        name="seconemail"
                        onChange={changeHandler}
                      />
                    </div>
                  </div>
                  <div className="Buttons">
                    <button className="button1" onClick={closeModal}>
                      Cancel
                    </button>
                    <button className="button2" onClick={handleSubmit}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ContainerWithoutFooter>
  );
};

export default AddSecondaryEmail;
