import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./TermsAndConditions.scss";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import {
  getAllTermAndConditionAsync,
  updateTermAndConditionAsync,
} from "../../../redux/announcementsSlice";
import moment from "moment";
import { Pagination } from "@mui/material";
import PaginationItem from "@mui/material/PaginationItem";
function TermsAndConditions() {
  const {
    masterTnCData,
    masterTnCLoading,
    updateMasterTnCLoading,
    masterTnCtotalPages,
    masterTnCtoRecords,
    masterTnCstartedRecords,
    masterTnCDataLength,
  } = useSelector((state) => state.announcement);
  const { currentSideBarStatus } = useSelector((state) => state.auth);
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [version, setVersion] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentV, setCurrentV] = useState(null);
  const [currentVerCount, setCurrentVerCount] = useState(null);
  const [userEnteredVersion, setUserEnteredVersion] = useState("");
  const dispatch = useDispatch();
  const listContainerRef = useRef(null);

  let data = {
    page: 1,
    perPage: 6,
  };

  useEffect(() => {
    dispatch(getAllTermAndConditionAsync(data));
  }, [dispatch]);

  const handleChange = (event, value) => {
    data.page = value;
    dispatch(getAllTermAndConditionAsync(data));
  };

  useEffect(() => {
    if (masterTnCData && masterTnCData.length > 0) {
      // let latest = masterTnCData.filter(
      //   (item) => item?.currentversion === true
      // );
      setEditorContent(masterTnCData[0]?.text);
      setVersion(masterTnCData[0]?.version);
      setSelectedItem(masterTnCData[0]?.id);
      setCurrentV(masterTnCData[0]?.currentversion);
      setCurrentVerCount(masterTnCData[0]?.version);
    }
  }, [masterTnCData]);

  const UpdateValue = (e) => {
    setUserEnteredVersion(e.target.value);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

  const getEditorContent = () => {
    // Ensure the user-entered version is higher than the current version
    const [currentMajor, currentMinor] = currentVerCount.split(".").map(Number);
    const [enteredMajor, enteredMinor] = userEnteredVersion
      .split(".")
      .map(Number);

    if (
      userEnteredVersion &&
      (enteredMajor < currentMajor ||
        (enteredMajor === currentMajor && enteredMinor <= currentMinor))
    ) {
      alert("Entered version must be higher than the current version.");
      return;
    }

    // Generate a new version if no valid version is provided
    const [major, minor] = userEnteredVersion
      ? userEnteredVersion.split(".").map(Number)
      : version.split(".").map(Number);
    let newMinor = minor + 1;
    let newMajor = major;

    if (newMinor > 99) {
      newMinor = 0;
      newMajor += 1;
    }

    const newVersion = `${newMajor}.${newMinor.toString().padStart(2, "0")}`;

    let Data = {
      id: masterTnCData[0]?.id,
      version: userEnteredVersion || newVersion,
      currentversion: true,
      text: editorContent,
      masterAdminId: loggesInUser?.id,
    };
    dispatch(updateTermAndConditionAsync(Data)).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setIsOpenEditor(false);
        dispatch(getAllTermAndConditionAsync(data));
      }
    });
  };

  const fontOptions = [
    { value: "sans-serif", label: "Sans Serif" },
    { value: "serif", label: "Serif" },
    { value: "monospace", label: "Monospace" },
    { value: "arial", label: "Arial" },
    { value: "times-new-roman", label: "Times New Roman" },
    { value: "courier", label: "Courier" },
  ];

  const modules = {
    toolbar: {
      container: [
        [{ font: fontOptions.map((option) => option.value) }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  };

  useEffect(() => {
    const link = document.createElement("link");
    link.href =
      "https://fonts.googleapis.com/css?family=Arial|Courier|Sans+Serif|Serif|Times+New+Roman|Monospace";
    link.rel = "stylesheet";
    document.head.appendChild(link);
  }, []);

  const formatDate = (timestamp) => {
    const messageDate = moment(parseInt(timestamp, 10));
    return messageDate.format("DD MMMM YYYY");
  };

  const getCurrentInfo = (item) => {
    setEditorContent(item?.text);
    setSelectedItem(item?.id);
    setCurrentV(item?.currentversion);
    setCurrentVerCount(item?.version);
  };

  return (
    <ContainerWithoutFooter>
      {masterTnCLoading || updateMasterTnCLoading ? <Loader /> : null}
      <div className="Action_Button">
        {isOpenEditor ? (
          <>
            <input
              className="versionEnter"
              type="number"
              defaultValue={currentVerCount}
              onChange={UpdateValue}
            />
            <p onClick={() => setIsOpenEditor(false)} className="Bank_BTN">
              Cancel
            </p>
          </>
        ) : (
          <p className="CurrentVersion">Version:- {currentVerCount}</p>
        )}
        <p
          className="Update_Button"
          onClick={
            isOpenEditor ? getEditorContent : () => setIsOpenEditor(true)
          }
        >
          {isOpenEditor ? "Save" : "Update"}
        </p>
      </div>
      <div className="ListContainer" ref={listContainerRef}>
        <div className="Previous_TnC" style={{ position: "relative" }}>
          {masterTnCData?.map((item, i) => (
            <div
              onClick={() => getCurrentInfo(item)}
              style={{
                border:
                  item.id === selectedItem
                    ? "3px solid #38AB7C"
                    : "1px solid #aca3a3",
                backgroundColor: item?.currentversion ? "#38AB7C" : null,
                position: "relative",
              }}
              key={i}
              className="Version_Container"
            >
              <p
                style={{
                  color: item?.currentversion ? "#fff" : "#222",
                }}
                className="VersionTitle"
              >
                Version : {item?.version}
              </p>

              {item?.currentversion === false ? (
                <div className="UpdatedUser">
                  <p
                    style={{
                      fontSize: "0.5rem",
                      fontWeight: "500",
                      color: "grey",
                      fontFamily: "roboto",
                      // position: "relative",
                      // bottom: "-0.3rem",
                    }}
                  >
                    Updated by {item?.masterAdmin?.firstName}{" "}
                    {item?.masterAdmin?.lastName}
                  </p>
                  <p
                    style={{
                      fontSize: "0.45rem",
                      fontWeight: "500",
                      color: "grey",
                      // position: "relative",
                      // bottom: "-0.3rem",
                      fontFamily: "italic",
                    }}
                  >
                    {formatDate(item?.updatedAt * 1000)}
                  </p>
                </div>
              ) : (
                <p
                  style={{
                    fontSize: "0.6rem",
                    fontWeight: "500",
                    color: item?.currentversion ? "#fff" : "rgb(56 171 124)",
                    // position: "relative",
                    // bottom: "-0.3rem",
                    padding: "0rem 0.2rem 0.2rem 0.3rem",
                  }}
                >
                  Current Version
                </p>
              )}
              {item?.id === selectedItem && (
                <span
                  style={{
                    position: "absolute",
                    // right:currentSideBarStatus? "-250px":"-300",
                    right: "-280px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "2rem",
                    color: "#38AB7C",
                    zIndex: 1,
                  }}
                >
                  <svg width="435" height="40" viewBox="0 0 100 30">
                    <path
                      d="M5 15 L85 15 L75 5 M85 15 L75 25"
                      stroke="rgb(56, 171, 124)"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    />
                  </svg>
                </span>
              )}
            </div>
          ))}

          {/* {masterTnCDataLength?.length > 5 && ( */}
          {/* <div
            style={{
              position: "absolute",
              bottom: "0px",
              height: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "1.2rem",
              backgroundColor: "#fff",
              padding: "0 10px",
            }}
           >
            <p style={{ fontSize: "0.6rem" }}>
              {masterTnCstartedRecords} to {masterTnCtoRecords} of{" "}
              {masterTnCDataLength}
            </p>

            <Pagination
              // count={masterTnCtotalPages}
              color="primary"
              onChange={(event, value) => handleChange(event, value)}
              sx={{
                ".MuiPaginationItem-root": {
                  fontSize: "0.6rem",
                  height: "15px",
                  width: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                ".MuiPaginationItem-page.Mui-selected": {
                  backgroundColor: "primary.main", 
                  color: "#fff",
                  width: "10px",
                },
              }}
            />
          </div> */}
          {/* )} */}
          <div
            style={{
              position: "absolute",
              bottom: "-3px",
              height: "30px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              backgroundColor: "#fff",
              padding: "0 10px",
            }}
          >
            <p style={{ fontSize: "0.55rem", fontFamily: "Arial",color:'#333' }}>
              {masterTnCstartedRecords} to {masterTnCtoRecords} of{" "}
              {masterTnCDataLength}
            </p>

            <Pagination
              count={masterTnCtotalPages}
              siblingCount={0}
              boundaryCount={0}
              hidePrevButton={false}
              hideNextButton={false}
              onChange={(event, value) => handleChange(event, value)}
              renderItem={(item) =>
                item.type === "previous" ? (
                  <PaginationItem
                    {...item}
                    components={{ previous: () => <span>Previous</span> }} // Custom "Previous" text
                    sx={{
                      fontSize: "0.6rem",
                      height: "15px",
                      width: "auto",
                      backgroundColor: "primary.main",
                      color: "#fff",
                      outline: "none",
                      border: "none",
                    }}
                  />
                ) : item.type === "next" ? (
                  <PaginationItem
                    {...item}
                    components={{ next: () => <span>Next</span> }} // Custom "Next" text
                    sx={{
                      fontSize: "0.6rem",
                      height: "15px",
                      width: "auto",
                      backgroundColor: "primary.main",
                      color: "#fff",
                      outline: "none",
                      border: "none",
                    }}
                  />
                ) : null
              }
            />
          </div>
        </div>

        <div className="terms-and-conditions-container">
          {isOpenEditor ? (
            <div
              style={{ border: !currentV ? "2px solid #38AB7C" : null }}
              className="editor-container"
            >
              <ReactQuill
                value={editorContent}
                onChange={handleEditorChange}
                modules={modules}
                formats={[
                  "font",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "align",
                  "list",
                  "bullet",
                  "indent",
                ]}
              />
            </div>
          ) : (
            <div
              style={{ border: !currentV ? "2px solid #38AB7C" : null }}
              className="editor-content ql-editor"
              dangerouslySetInnerHTML={{ __html: editorContent }}
            />
          )}
        </div>
      </div>
    </ContainerWithoutFooter>
  );
}

export default TermsAndConditions;
