import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Container from "../../../components/HOC/Container";
import "./AdminManagement.scss";
import Email from "../../../assets/icons/attach_email.svg";
import Eye from "../../../assets/icons/eye.svg";
import Plus from "../../../assets/icons/round-plus-white.svg";
import avatar from "../../../assets/icons/avatar.svg";
import Group_345 from "../../../assets/icons/Group_345.svg";
import Eye1 from "../../../assets/icons/eyeClose.svg";
import ThreeDot from "../../../assets/icons/threedot.svg";
import ListView from "../../../assets/icons/ListView.svg";
import ListView2 from "../../../assets/icons/ListView2.svg";
import CardView from "../../../assets/icons/CardView.svg";
import CardView2 from "../../../assets/icons/CardView2.svg";
import Load from "../../../assets/icons/RippleLoad.svg";

import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { useNavigate } from "react-router-dom";
import {
  getAllAdminAsync,
  getLoginHistoryAsync,
  getUnsuccessfulLoginAsync,
  storeUserId,
} from "../../../redux/adminManagementSlice";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import AdminManagementCard from "../../../components/AdminManagementCard/AdminManagementCard";
import GenrateOTPDialog from "../../../components/Dialogs/OTP/GenrateOTPDialog";
import ChangePassword from "../../../components/Dialogs/ChangePassword/ChangePassword";
import Delete from "../../../components/Dialogs/Delete/Delete";
import LoginHistory from "../../../components/Dialogs/LoginHistory/LoginHistory";
import Unsuccessfully from "../../../components/Dialogs/Unsuccessfully/Unsuccessfully";
import EmailVerification from "../../../components/Dialogs/EmailVarification/EmailVerification";
import NOdataFound from "../../../assets/icons/NoAdminData.svg";
import Footer from "../../../components/Footer/Footer";
import { Constants } from "../../../utils/Constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "react-tooltip";
import { regenerateOTPDataAsysnc } from "../../../redux/OTPSlice";
import { updateMAdmin } from "../../../redux/adminManagementSlice";

function AdminManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    allAdminData,
    getAllAdminLoading,
    deleteLoading,
    AdminPaginationDataLength,
    adminStartedRecords,
    adminToRecords,
    totalAdminPage,
    selectedId,
    selectedEmail,
  } = useSelector((state) => state.adminManage);
  const { specialLoginLoading, adminData, userDetails } = useSelector(
    (state) => state.auth
  );
  const MainAdmin =
    allAdminData?.length > 0 &&
    allAdminData?.filter((v) => v?.isPrimaryAccount);
  const loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [change, setChange] = useState(false);
  const [deleteAdmin, setDeleteAdmin] = useState(false);
  const [logHistory, setLogHistory] = useState(false);
  const [unsuccessfull, setUnsuccessfull] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState(false);
  const [selectedUserPasswords, setSelectedUserPasswords] = useState({});
  const [selectedUserOtp, setSelectedUserOtp] = useState({});
  const [showPasswordForRows, setShowPasswordForRows] = useState({});
  const [otp, setOtp] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [carView, setcarView] = useState(true);
  const [showAllCheck, setshowAllCheck] = useState();
  const [firstName, setFirstName] = useState("");
  const [page, setPage] = useState(1);
  const lastTenDaysDate = new Date();
  const [userIdOpen, setUserIdOpen] = useState(false);
  lastTenDaysDate.setDate(lastTenDaysDate.getDate() - 10);

  const handleChange = (event, value) => {
    setPage(value);
    const skip = (value - 1) * Constants.TAKE;

    dispatch(
      getAllAdminAsync(
        "",
        "",
        loggesInUser?.isPrimaryAccount,
        MainAdmin?.isShowMasterAdminAcc,
        Constants.TAKE,
        value
      )
    );
  };
  const handleClose = () => {
    setChange(false);
    setDeleteAdmin(false);
    setLogHistory(false);
    setUnsuccessfull(false);
  };

  const togglePasswordVisibility = (userId) => {
    setSelectedUserPasswords((prevPasswords) => ({
      ...prevPasswords,
      [userId]: !prevPasswords[userId],
    }));
  };

  useEffect(() => {
    dispatch(
      getAllAdminAsync(
        "",
        "",
        loggesInUser.isPrimaryAccount,
        MainAdmin?.isShowMasterAdminAcc,
        Constants.TAKE,
        1
      )
    );
  }, [dispatch]);

  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };

  const Generateotp = () => {
    setOpenOtpDialog(true);
  };
  const ChangePass = () => {
    setChange(true);
  };
  const ChangeEmail = () => {
    setEmailOpen(true);
  };
  const Deleted = () => {
    setDeleteAdmin(true);
  };
  const ChangeUserId = () => {
    setUserIdOpen(true);
  };
  const LoginHis = () => {
    setLogHistory(true);
    const UserId = selectedId;
    const Udate = "";
    const Ddate = "";
    dispatch(getLoginHistoryAsync(UserId, Udate, Ddate));
  };

  const setShowMadmin = () => {
    console.log("setShowMadmin.....");
    const loggesInUser = JSON.parse(
      sessionStorage.getItem("userLoggedDetails")
    );
    let isTrue;
    console.log("loggesInUser", loggesInUser);
    if (loggesInUser?.isShowMasterAdminAcc) {
      isTrue = false;
    } else {
      isTrue = true;
    }
    const data = {
      id: loggesInUser?.id,
      isShowPrimaryAccount: isTrue,
      // currentEmail: null,
      // newEmail: null,
      isShowSwitchBtn: true,
    };
    dispatch(updateMAdmin(data));
  };

  const Unsuccessfull = () => {
    setUnsuccessfull(true);
    const UserId = selectedId;
    const Udate = "";
    const Ddate = "";
    dispatch(getUnsuccessfulLoginAsync(UserId, Udate, Ddate));
  };
  const SearchQuerys = (e) => {
    setFirstName(e.target.value);
  };
  const SeacrhQueryRequest = () => {
    dispatch(
      getAllAdminAsync(
        firstName?.trim(),
        "",
        loggesInUser.isPrimaryAccount,
        MainAdmin?.isShowMasterAdminAcc,
        Constants.TAKE,
        18,
        1
      )
    );
    setFirstName("");
  };

  const ShowOTP = async (item) => {
    setSelectedUserOtp((prevOtp) => ({
      ...prevOtp,
      [item?.userId]: !prevOtp[item?.userId],
    }));
    setOtp();
    dispatch(storeUserId(item.emailAddress));
    setShowOTP(!showOTP);
    if (!showOTP) {
      setOtpLoading(true);
      try {
        const response = await dispatch(regenerateOTPDataAsysnc(item.userId));
        setOtp(response.otp);
        setOtpLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setOtpLoading(false);
      }
    }
  };

  // useEffect(()=>{
  //   let resAdmin = allAdminData?.filter(item=>item?.accountType==="admin")
  //  let resSentOtp= resAdmin.every(item=>item?.isSentOtpAuto===false)
  //   setshowAllCheck(resSentOtp)
  // },[showAllCheck])

  const Check = (item) => {
    // if(!item){
    //   let res = allAdminData?.filter(item=>item?.accountType==="masteradmin")
    //   let resAdmin = allAdminData?.filter(item=>item?.accountType==="admin")
    //  let resSentOtp= resAdmin.every(item=>item?.isSentOtpAuto===false)
    //   console.log("All Admin",resSentOtp)
    //   setshowAllCheck(resSentOtp)
    //   console.log(res)
    //   const data = {
    //     isSentOtpAuto: resSentOtp,
    //     isSetAll:true
    //   };
    //   dispatch(updateMAdmin(data));
    // }
    if (item) {
      let isTrue;
      if (item?.isSentOtpAuto) {
        isTrue = false;
      } else {
        isTrue = true;
      }
      const data = {
        id: item?.id,
        isSentOtpAuto: isTrue,
        isSetAll: false,
      };
      dispatch(updateMAdmin(data));
    }
  };

  return (
    <Container>
      {getAllAdminLoading || deleteLoading || specialLoginLoading ? (
        <Loader />
      ) : null}
      <div className=" AdminManagementMainContainer">
        <div className=" TopContainer">
          <h1>Admin Management</h1>
          <div className=" RightSideContainer">
            <div className="ViewAdminList">
              <img
                style={{
                  cursor: "pointer",
                }}
                src={carView ? ListView : ListView2}
                alt="More"
                width="20rem"
                height="20rem"
                onClick={() => setcarView(false)}
                title="List View"
              />
              <img
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  position: "relative",
                  top: "0.2rem",
                }}
                onClick={() => setcarView(true)}
                src={carView ? CardView2 : CardView}
                alt="More"
                width="30rem"
                height="30rem"
                title="Card View"
              />
            </div>
            <div className="search-area ">
              <div className="p-1 SearchIcon">
                <SearchIcon
                  onClick={SeacrhQueryRequest}
                  style={{ color: "#777" }}
                />
              </div>
              <input
                // defaultValue={firstName}
                onKeyDown={(event) => {
                  event.key === "Enter" && SeacrhQueryRequest();
                }}
                onChange={SearchQuerys}
                type="text"
                placeholder="Search"
              />
            </div>
            <button
              className="createAdminBtn"
              onClick={() => navigate("/AdminManagement/CreateAdmin")}
            >
              <img src={Plus} alt="Plus" />

              <span className="text">Create Admin Account</span>
            </button>
            <button
              onClick={() => setEmailOpen(true)}
              className=" mailAdminBtn "
            >
              <img src={Email} alt="Email" />
            </button>
          </div>
        </div>
        {allAdminData.length === 0 && !getAllAdminLoading ? (
          <div className="NoAdminData">
            <img src={NOdataFound} alt="NO DATA FOUND" />
            <div className="NoDataTextContainer">
              <h1>No Admins Available</h1>
              <h3>Currently there are admins .</h3>
            </div>
          </div>
        ) : (
          <div
            style={{
              maxHeight: `calc(100% - 50px)`,
              marginTop: "1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              flexWrap: "wrap",
              overflow: "hidden",
              overflowY: "auto",
            }}
          >
            {carView ? (
              allAdminData?.map((item, index) => (
                <div style={{ marginLeft: "0.5rem", width: "14rem" }}>
                  <AdminManagementCard
                    Generateotp={Generateotp}
                    ChangePass={ChangePass}
                    changeEmail={ChangeEmail}
                    ChangeUserId={ChangeUserId}
                    Deleted={Deleted}
                    LoginHis={LoginHis}
                    Unsuccessfull={Unsuccessfull}
                    setShowMadmin={setShowMadmin}
                    item={item}
                    index={index}
                    key={index}
                  />
                </div>
              ))
            ) : (
              <div className="table-areaForTempBussiness ">
                <TableContainer sx={{ height: "calc( 100% - 70px)" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow className={classes.root}>
                        <TableCell>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* <Checkbox checked={checked1} */}
                            {/* // onChange={toggleSelectAll}  */}
                            {/* /> */}
                            <p>Name</p>
                            {/* <img
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> */}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ display: "flex" }}>
                            <p>Email Address</p>
                            {/* 
                            <img
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> */}
                          </span>
                        </TableCell>

                        <TableCell>
                          <span style={{ display: "flex" }}>
                            <p>UserId</p>

                            {/* <img
                              // onClick={sorting}
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> */}
                          </span>
                        </TableCell>

                        <TableCell sx={{ height: "30px" }}>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {/* <Checkbox checked={checked1}

                        onChange={toggleSelectAll} />  */}

                            <p>Password</p>

                            {/* <button
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        className="eyeButton w-4 ml-1"
                      >
                        <img className="Eye" src={Eye} alt="Eye" />
                      </button> */}
                            {/* {checked1 || selectAllIds.length > 0 ? (
                        <div className="ApplyBTNBusiness">
                          <img
                            onClick={EnterPassword}
                            src={FilledEditd}
                            alt="Edit"
                          />
                        </div>
                      ) : (
                        <div className="ApplyBTNBusiness">
                          <img src={FilledEditd} alt="Edit" />
                        </div>
                      )} */}
                          </span>
                        </TableCell>

                        <TableCell>
                          <span style={{ display: "flex" }}>
                            <p>Otp</p>

                            {/* <img
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> */}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span style={{ display: "flex" }}>
                            <p>IP</p>

                            {/* <img
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> */}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Checkbox
                              checked={true}
                              icon={
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    backgroundColor: "#6ab193",
                                    alignSelf: "stretch",
                                  }}
                                >
                                  <Icon.FiCheck color="white" size={20} />
                                </div>
                              }
                              borderColor="#616b7a"
                              // borderWidth={0}
                              borderRadius={20}
                              style={{ overflow: "hidden" }}
                              size={20}
                              // label="Send Otp"
                              // onChange={() => Check(null)}
                            />
                            <p style={{ color: "#1a344", marginLeft: "3%" }}>
                              Send Otp
                            </p>
                          </span>
                        </TableCell>
                        {/* <TableCell>
                          <span style={{ display: "flex" }}>
                            <p>Actions</p>

                             <img
                              style={{ width: 14, marginLeft: "0.2rem" }}
                              src={Group_345}
                              alt="Group_345"
                            /> 
                          </span>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {allAdminData?.map((item, i) => {
                        return (
                          <TableRow
                            className={classes.bodyroot}
                            hover
                            role="checkbox"
                          >
                            <TableCell key={i}>
                              <span className="flex  justify-start items-center  ">
                                {/* <Checkbox */}
                                {/* checked={checkboxStates[item?.userId] || checked1} */}
                                {/* onChange={() => { */}
                                {/* // toggleCheckbox(item, i); */}
                                {/* // setshowPassword1(!showPassword1); */}

                                <div
                                  style={{
                                    width: "1.8rem",
                                    height: "1.8rem ",
                                    border: "1px solid grey",
                                    borderRadius: "50%",
                                  }}
                                >
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      width: "1.8rem",
                                      height: "1.8rem",
                                      borderRadius: "50%",
                                    }}
                                    src={
                                      item?.image &&
                                      (item.image.includes(".jpg") ||
                                        item.image.includes(".jpeg") ||
                                        item.image.includes(".png"))
                                        ? `https://${item?.image}`
                                        : avatar
                                    }
                                    alt="user"
                                    onError={FailedToLoad}
                                  />
                                </div>

                                <span className=" FirstClildTempBusiness ">
                                  <span
                                    style={{
                                      borderBottom: item?.isLoggedIn
                                        ? "2px solid red"
                                        : null,
                                    }}
                                    className="returingDataDate"
                                  >
                                    {item?.firstName} {item?.lastName}
                                  </span>
                                </span>
                              </span>
                            </TableCell>

                            <TableCell>
                              <span className="returingDataTempBusiness">
                                {item?.emailAddress}
                              </span>
                            </TableCell>

                            <TableCell>
                              <p className="returingDataTempBusiness">
                                {item?.userId}
                              </p>
                            </TableCell>

                            <TableCell>
                              <div className="passWordContainerTempcustomer">
                                {selectedUserPasswords[item.userId] ||
                                showPasswordForRows[item.userId] ? (
                                  <>
                                    <p
                                      data-tooltip-id="my-tooltip"
                                      data-tooltip-content={item?.password}
                                      className="WrittenPassword"
                                    >
                                      {item?.password?.length > 5
                                        ? item?.password.substring(0, 5) + ".."
                                        : item?.password}
                                      {item?.password?.length > 5 ? (
                                        <Tooltip
                                          id="my-tooltip"
                                          style={{
                                            backgroundColor: "#38ab7c",
                                            zIndex: 100,
                                          }}
                                        />
                                      ) : null}
                                    </p>
                                  </>
                                ) : (
                                  <p className="WrittenPassword_star">*****</p>
                                )}
                                {/* {showPasswordForRows[item.userId] ? (
                            <p className="WrittenPassword">{item.password}</p>
                          ) : (
                            <p className="WrittenPassword">*****</p>
                          )} */}
                                {item?.accountType?.toLowerCase() ===
                                  "masteradmin" &&
                                !loggesInUser?.isPrimaryAccount &&
                                item?.isPrimaryAccount ? null : (
                                  <button
                                    onClick={() =>
                                      togglePasswordVisibility(item.userId)
                                    }
                                    className="eyeButton w-4 ml-3"
                                  >
                                    <img
                                      className="hide_show"
                                      src={
                                        selectedUserPasswords[item.userId]
                                          ? Eye
                                          : Eye
                                      }
                                      alt="Eye"
                                    />
                                  </button>
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <p className="returingDataTempBusiness">
                                <div className="OTPContainer">
                                  <div className="OTP_digit">
                                    {showOTP &&
                                    selectedEmail === item.emailAddress &&
                                    selectedUserOtp[item.userId] ? (
                                      <p className="WrittenPassword">{otp}</p>
                                    ) : (
                                      <p className="noneStar">*****</p>
                                    )}
                                    {otpLoading &&
                                    selectedEmail === item.emailAddress ? (
                                      <img
                                        className="Loader"
                                        src={Load}
                                        alt="Wait"
                                      />
                                    ) : null}
                                  </div>
                                  {item?.accountType?.toLowerCase() ===
                                    "masteradmin" &&
                                  !loggesInUser?.isPrimaryAccount &&
                                  item?.isPrimaryAccount ? null : (
                                    <button
                                      onClick={() => ShowOTP(item)}
                                      className="eyeButton_OTP w-5  ml-1"
                                    >
                                      <img
                                        className="hide_show"
                                        src={
                                          selectedUserOtp[item.userId]
                                            ? Eye
                                            : Eye
                                        }
                                        alt="Eye"
                                      />
                                    </button>
                                  )}
                                </div>
                              </p>
                            </TableCell>
                            <TableCell>
                              <p className="returingDataTempBusiness">
                                {item?.accountType?.toLowerCase() ===
                                  "masteradmin" &&
                                !loggesInUser?.isPrimaryAccount &&
                                item?.isPrimaryAccount
                                  ? "******"
                                  : item?.iPAddress}
                              </p>
                            </TableCell>

                            <TableCell>
                              {item?.accountType?.toLowerCase() === "admin" && (
                                <p className="returingDataTempBusiness">
                                  <Checkbox
                                    checked={item?.isSentOtpAuto}
                                    icon={
                                      <div
                                        style={{
                                          display: "flex",
                                          flex: 1,
                                          backgroundColor: "#6ab193",
                                          alignSelf: "stretch",
                                        }}
                                      >
                                        <Icon.FiCheck color="white" size={20} />
                                      </div>
                                    }
                                    borderColor="#616b7a"
                                    // borderWidth={0}
                                    borderRadius={20}
                                    style={{ overflow: "hidden" }}
                                    size={20}
                                    label="Send Otp"
                                    onChange={() => Check(item)}
                                  />
                                </p>
                              )}
                            </TableCell>
                            {/* <TableCell> 
                              <p className="returingDataTempBusiness">
                                <img
                                  // onClick={() =>
                                  //  DeleteItem(item)
                                  // }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  src={ThreeDot}
                                  alt="More"
                                />
                              </p>
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        )}
        <GenrateOTPDialog
          open={openOtpDialog}
          setOpen={setOpenOtpDialog}
          onClose={handleClose}
        />
        <ChangePassword
          change={change}
          setChange={setChange}
          onClose={handleClose}
        />
        <Delete
          deleteAdmin={deleteAdmin}
          setDeleteAdmin={setDeleteAdmin}
          onClose={handleClose}
        />
        <LoginHistory
          logHistory={logHistory}
          setLogHistory={setLogHistory}
          onClose={handleClose}
        />
        <Unsuccessfully
          unsuccessfull={unsuccessfull}
          setUnsuccessfull={setUnsuccessfull}
          onClose={handleClose}
        />
        <EmailVerification
          emailOpen={emailOpen}
          setEmailOpen={setEmailOpen}
          role={false}
        />
        <EmailVerification
          setUserIdOpen={setUserIdOpen}
          userIdOpen={userIdOpen}
          role={false}
          isUser={true}
        />
      </div>
      {AdminPaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={AdminPaginationDataLength}
          startedRecords={adminStartedRecords}
          toRecords={adminToRecords}
          totalPages={totalAdminPage}
        />
      ) : null}
    </Container>
  );
}

export default AdminManagement;

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.2rem 0.8rem",

      height: "28px",

      padding: "0.2rem 0.8rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 0.8rem",
    },
  },
});
