import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import "./CountryBy.scss";
import SearchIcon from "../../../assets/icons/SearchIcon.svg";
import FormControl from '@mui/material/FormControl';
const CountryBy = ({ openCountryDialog, setOpenCountryDialog, sendData }) => {
  const country = [
    'USA',
    'CANADA',
  ];
  const [selectedCountry, setSelectedCountry] = useState([]);
  const handleLocationChange = (e) => {
    
    const { value, checked } = e.target;
    if (checked) {
      setSelectedCountry([...selectedCountry, value]);
    } else {
      setSelectedCountry(selectedCountry.filter((location) => location !== value));
    }
  };

  const handleClick = () => {
    setOpenCountryDialog(false)
    sendData(selectedCountry.slice(1, selectedCountry.length))
    setSelectedCountry([null])
  };

  return (
    <Dialog

      PaperProps={{
        sx: {
          position: "fixed", right: 10, m: 0, top: '30%'
        }
      }}

      open={openCountryDialog} onClose={handleClick}>
      <div className="CountryBox">
        <div className="InputDiv">
          <img src={SearchIcon} alt="Search" />
          <input type="text" placeholder="Search" />
        </div>

        <div className="ContryContainer">
          <FormControl>
            {
              country.map((name) => (
                <div className="CountryName">
                  <input
                    type="checkbox"
                    value={name}
                    checked={selectedCountry.includes(name)}
                    onChange={handleLocationChange}
                  />
                  <p> {name}</p>
                </div>
              ))
            }

          </FormControl>
        </div>
      </div>
    </Dialog>
  );
};

export default CountryBy;
