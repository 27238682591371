import React, { useEffect, useState } from "react";
import "./Category.scss";
import {
  editCategoryAsync,
  addSubCategoryAsync,
  getCategoryAsync,
  categoryOpen,
  getSubCategoryAsync,
  editSubCategoryAsync,
  delSubCategoryAsync,
} from "../../../redux/categorySlice";
import { useSelector, useDispatch } from "react-redux";
import Backup from "../../../assets/icons/backup.svg";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import Loader from "../../../components/Loader";
import EditCategoryModal from "../../../components/Modals/EditCategoryModal";
import AddSubCategoryModal from "../../../components/Modals/AddSubCategoryModal";
import EditSubCategoryModal from "../../../components/Modals/EditSubCategoryModal";
import DelSubCategoryModal from "../../../components/Modals/DelSubCategoryModal";

function Category() {
  const dispatch = useDispatch();

  const {
    categoryData,
    categoryOpenedDetail,
    // categoryOpenedName,
    subCategoryData,
    categoryLoading,
    subCategoryLoading,
  } = useSelector((state) => state.category);

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openSubCategoryModal, setopenSubCategoryModal] = useState(false);
  const [openEditSubCategoryModal, setopenEditSubCategoryModal] =
    useState(false);

  const [openDelModal, setopenDelModal] = useState(false);

  const [categoryName, setcategoryName] = useState("");
  const [subCategoryName, setsubCategoryName] = useState("");
  const [subCategorySelected, setsubCategorySelected] = useState("");
  // const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(getCategoryAsync(true));
    const subData = categoryData.map((itm, i) => {
      // console.log("diosvnbobvob", itm.subCategoryTbls.length);
    });
  }, [dispatch]);

  const handleOpenCategoryModal = () => setOpenCategoryModal(true);

  const handleCloseCategoryModal = () => setOpenCategoryModal(false);

  const handleSubCategoryOpen = () => setopenSubCategoryModal(true);
  const handleSubCategoryClose = () => {
    setopenSubCategoryModal(false);
    emptySubCategoryState();
  };

  const handleDelSubCategoryOpen = (item) => {
    setsubCategorySelected(item);
    setopenDelModal(true);
  };
  const handleDelSubCategoryClose = () => {
    setopenDelModal(false);
    emptySubCategoryState();
  };
  const handleEditSubCategoryOpen = (item) => {
    console.log(item);
    setopenEditSubCategoryModal(true);
    setsubCategorySelected(item);
    setsubCategoryName(item.name);
  };
  const handleEditSubCategoryClose = () => {
    setopenEditSubCategoryModal(false);
    emptySubCategoryState();
  };

  const emptySubCategoryState = () => {
    setsubCategorySelected("");
    setsubCategoryName("");
  };

  const updateCategory = () => {
    console.log("categgory save");
    const data = {
      name: categoryName,
      id: categoryOpenedDetail.id,
    };
    dispatch(editCategoryAsync(data));

    handleCloseCategoryModal();
  };

  const saveSubCategory = () => {
    console.log("categgory save");
    const data = {
      name: subCategoryName,
      categoryId: categoryOpenedDetail.id,
    };
    dispatch(addSubCategoryAsync(data));
    setsubCategoryName("");
    handleSubCategoryClose();
  };

  const editSubCategory = () => {
    console.log("categgory edit");
    const data = {
      categoryId: categoryOpenedDetail?.id,
      name: subCategoryName,
      id: subCategorySelected.id,
    };
    dispatch(editSubCategoryAsync(data));

    handleEditSubCategoryClose();
  };

  const openCategory = (cat) => {
    setcategoryName(cat.name);

    dispatch(categoryOpen(cat));
    dispatch(getSubCategoryAsync("", cat.id));
  };

  const delSubCategory = () => {
    console.log(subCategoryName);
    const data = {
      name: subCategorySelected.name,
      id: subCategorySelected.id,
      isDeleted: true,
    };
    dispatch(delSubCategoryAsync(data));
    setopenDelModal(false);
  };

  const SearchQueary = (e) => {
    setName(e.target.value);
    // console.log(name);
  };
  const getSeacrhData = () => {
    dispatch(getSubCategoryAsync(name, categoryOpenedDetail.id));
  };
  return (
    <ContainerWithoutFooter>
      {categoryLoading ? <Loader /> : null}

      <EditCategoryModal
        categoryName={categoryName}
        setcategoryName={setcategoryName}
        handleCloseCategoryModal={handleCloseCategoryModal}
        updateCategory={updateCategory}
        openCategoryModal={openCategoryModal}
      />

      <AddSubCategoryModal
        subCategoryName={subCategoryName}
        setsubCategoryName={setsubCategoryName}
        handleSubCategoryClose={handleSubCategoryClose}
        saveSubCategory={saveSubCategory}
        openSubCategoryModal={openSubCategoryModal}
      />

      <EditSubCategoryModal
        subCategoryName={subCategoryName}
        setsubCategoryName={setsubCategoryName}
        handleEditSubCategoryClose={handleEditSubCategoryClose}
        editSubCategory={editSubCategory}
        openEditSubCategoryModal={openEditSubCategoryModal}
      />

      <DelSubCategoryModal
        subCategorySelected={subCategorySelected}
        handleDelSubCategoryClose={handleDelSubCategoryClose}
        delSubCategory={delSubCategory}
        openDelModal={openDelModal}
      />

      <div className="Cat_Main-container">
        <div className=" Tob_Bar_Cat">
          <h1>Category/Sub Category</h1>
          <div className="publish-change-btn">
            <img src={Backup} alt="publish" className="publish icon" />

            <span className="publish-change-btn__text">Publish Changes</span>
          </div>
        </div>

        <div className=" Cat_Bottom_container">
          <div className=" category-box">
            <h1>Categories</h1>

            <div className=" Cat_Items_data">
              {categoryData.map((data, i) => (
                <div
                  className="category-name-area"
                  onClick={() => openCategory(data)}
                  key={data.id}
                  style={{
                    backgroundColor:
                      data.id === categoryOpenedDetail.id ? "#ddd9" : "#0000",
                  }}
                >
                  <div className="TextAndNumber ">
                    <span className="category-name">{data.name}</span>
                    <span className="NumberOfSubCategories">
                      {data.subCategoryTbls ? data?.subCategoryTbls?.length : 0}
                    </span>
                  </div>

                  <div className="RightButtons">
                    <button
                      className="roundPlus"
                      onClick={handleSubCategoryOpen}
                    >
                      <img
                        src={
                          require("../../../assets/icons/round-plus.svg")
                            .default
                        }
                        alt="plus"
                      />
                    </button>
                    <button
                      className=" roundPlus roundPlus2 ml-2"
                      onClick={handleOpenCategoryModal}
                    >
                      <img
                        src={
                          require("../../../assets/icons/pencil-edit.svg")
                            .default
                        }
                        alt="edit"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="subcategory-box">
            {subCategoryLoading && !categoryLoading ? <Loader /> : null}

            <div className="TopconTainer">
              <div className="subcategory-box__heading">
                <span className="category-name">
                  {categoryOpenedDetail?.name}
                </span>
                <span className="NumberOfSubCategories">
                  {subCategoryData.length}
                </span>
              </div>

              <div className="searchBar">
                <button className="h-full pl-2 pr-2">
                  <img
                    src={require("../../../assets/icons/search.svg").default}
                    alt="search"
                    className="h-5 "
                    onClick={getSeacrhData}
                  />
                </button>

                <input
                  onKeyDown={(event) => {
                    event.key === "Enter" && getSeacrhData();
                  }}
                  onChange={SearchQueary}
                  placeholder="Search"
                  type="text"
                  className="h-full w-full search-input"
                />
              </div>
            </div>

            <div className="BottomSubContainer">
              {subCategoryData.map((item, index) => (
                <div className="subCatIndex " key={index}>
                  <span className="sub-category-text">{item.name}</span>
                  <div className="bottomButtons">
                    <button
                      className="sub-category-text sub-category-edit"
                      onClick={() => handleEditSubCategoryOpen(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="sub-category-text sub-category-del "
                      onClick={() => handleDelSubCategoryOpen(item)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ContainerWithoutFooter>
  );
}

export default Category;
