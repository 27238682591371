import React, { useState, useEffect } from "react";
import "./AnnouncementDialog.scss";
import Dialog from "@mui/material/Dialog";
import Loader from "../../../components/Loader";
import { toast } from "react-toastify";

import {
  updateAnnouncementAsync,
  getAllAnnouncementAsync,
} from "../../../redux/announcementsSlice";
import { useDispatch, useSelector } from "react-redux";
const AnnouncementDialog = (props) => {
  const { updateAnnouncementLoading, updateAnnouncementData } = useSelector(
    (state) => state.announcement
  );
  const {
    openAnnouncementDialog,
    setopenAnnouncementDialog,
    announcementItem,
  } = props;
  const SelectedId = announcementItem.id;
  const [val, setVal] = useState(0);
  const [updatedQuery, setUpdatedQuery] = useState(announcementItem);
  const [updatedDescription, setUpdatedDescription] =
    useState(announcementItem);
  useEffect(() => {
    if (val === 0) {
      setUpdatedQuery(announcementItem.title);
      setUpdatedDescription(announcementItem.description);
    } else {
      setUpdatedQuery(updatedQuery);
      setUpdatedDescription(updatedDescription);
    }
  }, [announcementItem.title, announcementItem.description]);
  const dispatch = useDispatch();
  const handleClose = () => {
    setopenAnnouncementDialog(false);
  };

  const changeAnnouncementHeading = (e) => {
    setVal(1);
    setUpdatedQuery(e.target.value);
  };
  const changeAnnouncementDesc = (e) => {
    setVal(1);
    setUpdatedDescription(e.target.value);
  };

  const updateAnnouncement = () => {
    const UpdatedData = {
      title: updatedQuery,
      description: updatedDescription,
      id: SelectedId,
    };
    if (val === 1) {
      dispatch(updateAnnouncementAsync(UpdatedData));
      toast.success("Updated ");
      handleClose();
    } else {
      // dispatch(updateAnnouncementAsync(PreData));
      toast.error("You have not updated anything");
      handleClose();
    }
  };

  return (
    <Dialog
      open={openAnnouncementDialog}
      PaperProps={{
        sx: {
          width: "90%",
        },
      }}
    >
      {updateAnnouncementLoading === true ? <Loader /> : null}

      <div className="AnnouncementDialogBox">
        <div className="inputBox">
          <input
            type="text"
            placeholder="Please type your heading"
            onChange={changeAnnouncementHeading}
            defaultValue={announcementItem.title}
          />
          <textarea
            type="text"
            defaultValue={announcementItem.description}
            onChange={changeAnnouncementDesc}
            rows="6"
            cols="20"
          />

          <div className="buttons">
            <button onClick={handleClose} className="button1">
              Cancel
            </button>
            <button className="button2" onClick={updateAnnouncement}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AnnouncementDialog;
