import { Navigate, Route, Routes } from "react-router-dom";
import Statics from "../pages/MasterAdmin/Statics/Statics";
import AdminManagement from "../pages/MasterAdmin/AdminManagement/AdminManagement";
import BankingInfo from "../pages/MasterAdmin/BankingInfo/BankingInfo";
import Announcement from "../pages/MasterAdmin/Announcement/Announcement";
import Category from "../pages/MasterAdmin/Category/Category";
import TermsAndConditions from "../pages/MasterAdmin/TermsAndConditions/TermsAndConditions";
import SidebarMasterAdmin from "../components/SidebarMasterAdmin/SidebarMasterAdmin";
// import CreateMasterAdmin from "../pages/MasterAdmin/CreateMasterAdmin/CreateMasterAdmin";
import CreateAdmin from "../pages/MasterAdmin/CreateAdmin/CreateAdmin";
import AdminCSV from "../pages/MasterAdmin/MasterAdminCSV/AdminCSV.jsx";
import { useDispatch } from "react-redux";
import Socket from "../redux/socketConnect";
import { useEffect } from "react";

const MasterAdminRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
    Socket.socketInit(loggesInUser?.userId, dispatch);
    return () => {
      Socket.logoutUser(dispatch);
    };
  }, []);
  return (
    <div className="h-screen w-full flex">
      <SidebarMasterAdmin />
      <Routes>
        <Route path="/Statistics" element={<Statics />} />
        <Route exact path="/AdminManagement" element={<AdminManagement />} />
        {/* <Route
          exact
          path="/AdminManagement/CreateMasterAdmin"
          element={<CreateMasterAdmin />}
        /> */}
        <Route
          exact
          path="/AdminManagement/CreateAdmin"
          element={<CreateAdmin />}
        />
        <Route path="/BankingInfo" element={<BankingInfo />} />
        <Route path="/Announcement" element={<Announcement />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="/AdminCSV" element={<AdminCSV />} />
        <Route path="*" element={<Navigate to="/Statistics" />} />
      </Routes>
    </div>
  );
};

export default MasterAdminRoutes;
