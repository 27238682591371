import React, { useEffect } from "react";
import "./Csv.scss";
import importIcon from "../../assets/icons/import.svg";
import RightIcon from "../../assets/icons/RightIcon.svg";
import cross from "../../assets/icons/cross-blue.svg";
import downloadsvg from "../../assets/icons/downloadsvg.svg";
import TermNCondition from "../../assets/icons/TermNCondition.svg";
import { useState } from "react";
import { useRef } from "react";
import EditIcon from "../../assets/icons/EditIcon.svg";
import Swal from "sweetalert2";
import roundPlus from "../../assets/icons/round-plus-white.svg";
import { Select } from "antd";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import { Steps } from "antd";
import Group_345 from "../../assets/icons/Group_345.svg";
import {
  InfoCircleOutlined,
  FileProtectOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { signUpAsync } from "../../redux/adminManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { createTempAccounts } from "../../redux/usersSlice";
import Loader from "../Loader";
const CsvUploaderModal = () => {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [progressBar, setProgressBar] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [openInvalidData, setOpenInvalidData] = useState(false);
  const [showStep, setShowStep] = useState(true);
  const { CSVVerificationAccountType } = useSelector((state) => state.auth);
  const { selectedAccountType, createBusinessUserLoading, createTempCustomerAccountData, createTempBusinessAccountData } = useSelector((state) => state.users);
  //  -Step Bar Functionality --------
  const steps = [
    {
      title: "Step 1",
      content: "First-content",
      icon: <FileTextOutlined />,
    },
    {
      title: "Step 2",
      content: "Second-content",
      icon: <FileProtectOutlined />,
    },
    {
      title: "Step 3",
      content: "Last-content",
      icon: <InfoCircleOutlined />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
  //------------------------
  const handleDragover = (e) => {
    e.preventDefault();
  };
  const handleDrop = (e) => {
    e.preventDefault();
    Papa.parse(e.dataTransfer.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFile(results.data);
        setProgressBar(progressBar + 1);
      },
    });
    if (file?.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      return false;
    } else {
      Swal.fire({
        icon: "success",
        title: "CSV Uploaded successfully",
        text: "CSV Uploaded successfully",
        html: "You will be redirected to the data mapping screen...",
      });
      return true;
    }
  };

  // step bar functionality ----end----------
  const CSVFileData = (e) => {
    e.preventDefault();
    Papa.parse(e.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setFile(results.data);
        setProgressBar(progressBar + 1);
      },
    });
    if (file?.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      return false;
    } else {
      Swal.fire({
        icon: "success",
        title: "CSV Uploaded successfully",
        text: "CSV Uploaded successfully",
        html: "You will be redirected to the data mapping screen...",
      });
      return true;
    }
  };
  const InvalidData = file?.filter((items, i) => {
    let Valid = file?.findIndex((item) => item.emailAddress === items.emailAddress) !== i;
    return Valid;
  });
  const ValidData = file?.filter((items, i) => {
    let Invalid = file?.findIndex((item) => item.emailAddress === items.emailAddress) === i;
    return Invalid;
  });
  const CreateAdmins = async () => {
    if (!file) {
      Swal.fire({
        icon: "error",
        title: "Please select CSV file ",
      });
      return false;
    }
    Swal.fire({
      icon: "success",
      title: "Data uploaded successfully",
      text: "You will be redirected to the data validation screen...",
    });
    setProgressBar(progressBar + 1);
    setUploaded(true);
    setOpenInvalidData(true);

    return true;
  };
  const CheckInvalidData = () => {
    setOpenInvalidData(false);
    setShowStep(false);
  };
  const Import_into_DB = async () => {
    setProgressBar(progressBar + 1);
    try {
      if (CSVVerificationAccountType === "masteradmin") {
        const res = await dispatch(signUpAsync(ValidData));
        if (res === "success") {
          setUploaded(true);
          setShowStep(false);
          setOpenInvalidData(true);
          Swal.fire({
            icon: "success",
            title: "Created",
            // text: "You will be redirected to the data validation screen...",
          });
        }
      } else {
        if (selectedAccountType === "CUSTOMER_USER") {
          const res = await dispatch(createTempAccounts(ValidData, navigate));
          if (createTempCustomerAccountData.status === true) {
            setUploaded(true);
            setShowStep(false);
            setOpenInvalidData(true);
            Swal.fire({
              icon: "success",
              title: "Customer Created",
              // text: "You will be redirected to the data validation screen...",
            });
          }
          // navigate(-1)
        } else if (selectedAccountType === "BUSSINESS_USER") {
          dispatch(createTempAccounts(ValidData));
          if (createTempBusinessAccountData.status === true) {
            setUploaded(true);
            setShowStep(false);
            setOpenInvalidData(true);
            Swal.fire({
              icon: "success",
              title: "Business Created",
              text: "You will be redirected to the data validation screen...",
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("Process bar ", progressBar);
  return (
    <>

      {
        createBusinessUserLoading === true ? (<Loader />) : null
      }
      <div className="MainConatiner">
        <div className="Main_Header">
          <div className="Import_Icon_Container">
            <img src={importIcon} alt="Import" />
            <h2>Import Data</h2>
          </div>
          <div className="ContinueContainer">
            {!uploaded ? (
              <div onClick={CreateAdmins} className="ContainerButton">
                <img src={RightIcon} alt="" />
                <p>Continue</p>
              </div>
            ) : (
              <>
                {showStep ? (
                  <div onClick={Import_into_DB} className="ContainerButton">
                    <img src={RightIcon} alt="" />
                    <p>Import</p>
                  </div>
                ) : null}
              </>
            )}

            <img
              onClick={() => navigate(-1)}
              className="ml-2 cursor-pointer"
              src={cross}
              alt="Cancel"
            />
          </div>
        </div>
        {showStep ? (
          <div className="SteppBarContainer">
            <Steps
              style={{ cursor: "pointer" }}
              current={progressBar}
              items={items}
              icon={items?.icon}
            />
          </div>
        ) : null}

        <div className="CSV_Container">
          {!file && !uploaded ? (
            <>
              <div className="Import_heading_and_Download">
                <h1>User Import options</h1>
                <div className="DownloadFile">
                  <img src={downloadsvg} alt="import" />
                  <p>Download Sample File</p>
                </div>
              </div>
              <div
                className="ImportFile_container"
                onDragOver={handleDragover}
                onDrop={handleDrop}
              >
                <img src={importIcon} alt="import" />
                <h3>Drag & Drop your file(s) here to upload</h3>
                <h5>
                  or
                  <input
                    type="file"
                    multiple
                    onChange={CSVFileData}
                    hidden
                    ref={inputRef}
                  />
                  <button onClick={() => inputRef.current.click()}>
                    select file
                  </button>
                </h5>
              </div>

              <div className="ConditionContainer">
                <div className="Icon_FileFormat">
                  <img src={TermNCondition} alt="" />
                  <p>File format should be CSV</p>
                </div>
                <p>Maximum limit: 150,000 records</p>
              </div>
            </>
          ) : null}
          <>
            {!uploaded && file ? (
              <div className="SelectedFile_container">
                <h6>Selected File</h6>
                <div className="FileType_container">
                  <div className="CSV_Type">
                    <p>CSV</p>
                    <CSVLink
                      data={file}
                      filename={"my-file.csv"}
                      target="_blank"
                    >
                      <span>Download File</span>
                    </CSVLink>
                  </div>

                  <img src={EditIcon} alt="Edit" />
                </div>
              </div>
            ) : null}
            {!uploaded && file ? (
              <div>
                <div className="Fields_Mapping_Container">
                  <h1>Fields mapping</h1>
                  <div className="Add_Custom_Field_Button">
                    <img src={roundPlus} alt="Add" />
                    <p>Add custom fields</p>
                  </div>
                </div>
                <div className="Mapping_data_Heading">
                  <h2>Fields in your file</h2>
                  <h2>Map to your data fields</h2>
                </div>
                <div className="Mapping_Datas">
                  <h2>Email</h2>
                  <Select
                    defaultValue="Email"
                    style={{ width: "50%" }}
                    disabled
                    label="Email"
                  />
                </div>
                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Business First Name</h2>
                    <Select
                      defaultValue="First Name"
                      style={{ width: "50%" }}
                      disabled
                      label="First Name"
                    />
                  </div>) : (<div className="Mapping_Datas">
                    <h2>First Name</h2>
                    <Select
                      defaultValue="First Name"
                      style={{ width: "50%" }}
                      disabled
                      label="First Name"
                    />
                  </div>)
                }
                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Business Last Name</h2>
                    <Select
                      defaultValue="Last Name"
                      style={{ width: "50%" }}
                      disabled
                      label="Last Name"
                    />
                  </div>) : (<div className="Mapping_Datas">
                    <h2>Last Name</h2>
                    <Select
                      defaultValue="Last Name"
                      style={{ width: "50%" }}
                      disabled
                      label="Last Name"
                    />
                  </div>)
                }

                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Phone No.</h2>
                    <Select
                      defaultValue="Phone no"
                      style={{ width: "50%" }}
                      disabled
                      label="Phone no"
                    />
                  </div>) : null
                }

                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Category</h2>
                    <Select
                      defaultValue="Category"
                      style={{ width: "50%" }}
                      disabled
                      label="Category"
                    />
                  </div>) : null
                }

                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Sub Category</h2>
                    <Select
                      defaultValue="Unknown"
                      style={{ width: "50%" }}
                      disabled
                      label="Unknown"
                    />
                  </div>) : null
                }

                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Location</h2>
                    <Select
                      defaultValue="Location"
                      style={{ width: "50%" }}
                      disabled
                      label="Location"
                    />
                  </div>) : null
                }

                {
                  CSVVerificationAccountType !== "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>Address</h2>
                    <Select
                      defaultValue="Address"
                      style={{ width: "50%" }}
                      disabled
                      label="Address"
                    />
                  </div>) : null
                }
                {
                  CSVVerificationAccountType === "masteradmin" ? (<div className="Mapping_Datas">
                    <h2>userId</h2>
                    <Select
                      defaultValue="userId"
                      style={{ width: "50%" }}
                      disabled
                      label="userId"
                    />
                  </div>) : null
                }
              </div>
            ) : null}
            {openInvalidData && file ? (
              <div className="inValid_Data_Container">
                <div className="inValid_Data">
                  <h1>Imported data</h1>
                  <div className="Mapping_Datas_Header">
                    <h2>Record type</h2>
                    <h2>Total records</h2>
                  </div>
                  <div className="Mapping_Datas">
                    <p>Total records</p>
                    <p>{file?.length}</p>
                  </div>
                  <div className="Mapping_Datas">
                    <p>Valid records</p>
                    <p>{ValidData?.length}</p>
                  </div>

                  <div className="Mapping_Datas">
                    <p>Invalid records</p>
                    <p>
                      {InvalidData?.length}
                      {
                        InvalidData?.length > 0 ? (<span onClick={CheckInvalidData} className="letsReview">
                          Let's review
                        </span>) : null
                      }

                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {!openInvalidData && uploaded ? (
              <div className="Duplicate_Data_Container">
                <p>
                  We found {InvalidData?.length} records are invalid and
                  <span className="Errors">system gonna reject this</span>,
                  please review this information and update this by uploading a
                  new CSV.
                </p>
                <table style={{ width: "100%", marginTop: "1%" }}>
                  <thead
                    style={{
                      height: 50,
                      paddingLeft: 10,
                      backgroundColor: " #F3F6F9",
                    }}
                  >
                    <tr>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p>First name</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p>Last name</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>

                      <th>
                        <span style={{ display: "flex" }}>
                          <p>Email</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p>Phone No.</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p> Category</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p> Sub Category</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p>Location</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                      <th>
                        <span style={{ display: "flex" }}>
                          <p>Address</p>
                          <img
                            style={{ width: 14, marginLeft: "0.3rem" }}
                            src={Group_345}
                            alt="Group_345"
                          />
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {InvalidData?.map((item, i) => (
                      <tr key={i}>
                        <td>{item.firstName}</td>
                        <td>{item.lastName}</td>
                        <td className="duplicate_email">
                          {item?.emailAddress}
                        </td>
                        <td>{item?.location}</td>
                        <td>{item?.category?.name}</td>
                        <td>{item?.serviceSubCategoryId}</td>
                        <td>{item?.businessAddress}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </>
        </div>
      </div>
    </>
  );
};

export default CsvUploaderModal;
