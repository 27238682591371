export const checkImage = async (url) => {
  let image;
  try {
    if (url) {
      console.log("firstRR", url);
      const response = await fetch(url);
      if (response.status == 200) {
        const modifiedUrl = response?.url.replace("http://localhost:3000/", "");
        console.log("responsellll", modifiedUrl);
        return (image = "https://" + modifiedUrl.json());
      } else {
        return (image = null);
      }
    }
  } catch (error) {}
};

export const displayImages = (url) => {
  return url && url.includes("https://") ? url : "https://" + url;
};
