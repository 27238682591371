import axios from "axios";
import { ApiConnection } from "../config/env"; // Make sure this is correctly set up

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: ApiConnection.BACKEND_URL, // Ensure this is correctly set in your environment
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Set the token in the Authorization header if it exists in sessionStorage
    const token = sessionStorage.getItem("userLoggedToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + JSON.parse(token);
    }
    config.headers["Content-Type"] = "application/json"; // Default to application/json
    return config;
  },
  (error) => {
    // Handle request errors
    console.error("Error in request", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Log and return the response
    console.log("API Response:", response);
    return response;
  },
  (error) => {
    // Handle response errors
    console.error("Error in response", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
