import React, { useEffect, useState, useRef } from "react";
import "./TempBussinessAccount.scss";
import Vector from "../../../assets/icons/Vector.svg";
import Group_345 from "../../../assets/icons/Group_345.svg";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import Container from "../../../components/HOC/Container";
import Switch from "react-switch";
import { Checkbox } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ThreeDot from "../../../assets/icons/threedot.svg";
import threedot_red from "../../../assets/icons/threedot_red.svg";
import { Constants } from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../../components/Footer/Footer";
import Eye from "../../../assets/icons/eye.svg";
import Eye1 from "../../../assets/icons/eyeClose.svg";
import FilledEditd from "../../../assets/icons/FilledEditd.svg";
import { Tooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import avatar from "../../../assets/icons/avatar.svg";
import {
  getSearchBusinessAsync,
  selectedUserDetails,
  UserAccountType,
  UpdateTempPassAsync,
  getAllTempBusinessSuccess,
} from "../../../redux/usersSlice";
import TempBusAccountVerificationDialog from "../../../components/Dialogs/AdminPanelActionDialog/TempBusAccountVerificationDialog";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/Loader";

function TempBusinessAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SelectedTempId = useRef("");
  const {
    tempBusinessAccountData,
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages,
    deleteBusinessLoading,
    updateTempLoading,
    tempBusinessDataLoading,
  } = useSelector((state) => state.users);

  const [tempBussAccountDeleteData, setTempBussAccountDeleteData] =
    useState(false);

  const [checked, setchecked] = useState(false);
  const [showOnHoverPassword, setShowOnHoverPassword] = useState(false);
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [address, setAddress] = useState("");
  const [lastName, setlastName] = useState("");
  const [enteredPass, setInteredPass] = useState("");
  const SelectedBusinessEmail = useRef(null);
  const [allIds, setAllIds] = useState([]);
  const [selectAllIds, setSelectAllIds] = useState([]);
  const [checked1, setChecked1] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [showPassword1, setshowPassword1] = useState(false);
  const [selectedUserPasswords, setSelectedUserPasswords] = useState({});
  const [showPasswordForRows, setShowPasswordForRows] = useState({});
  const [isSort, setIsSort] = useState(false);
  const [passwordCheck, setPasswprdCheck] = useState(false);
  const classes = useStyles();
  console.log(
    "ALLL",
    PaginationDataLength,
    startedRecords,
    toRecords,
    totalPages
  );
  const data = {
    location,
    email,
    username,
    address,
    businessName,
    lastName,
    demo: 1,
    page: 1,
    perPage: Constants.TAKE,
  };

  //-----------------Temporary Data API call ------------------

  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    // setPage(value);
    data.page = value;
    dispatch(getSearchBusinessAsync(data));
  };
  useEffect(() => {
    dispatch(getSearchBusinessAsync(data));
    dispatch(UserAccountType("BUSSINESS_USER"));
  }, [updateTempLoading, dispatch]);

  //------------------------------------

  const TempBusAccountHandleClose = () => {
    setTempBussAccountDeleteData(false);
  };

  const DeleteItem = (row) => {
    SelectedTempId.current = row?.id;
    setTempBussAccountDeleteData(true);
  };

  const SearchQuery = (e) => {
    setLocation("");
    setUserName("");
    setAddress("");
    setEmail("");
    setBusinessName(e.target.value);
  };

  const getDatabyQuery = () => {
    console.log("this is data ", data);
    dispatch(getSearchBusinessAsync(data, Constants.TAKE, 0, 1));
  };

  const NavigateToTempBusinessUserProfile = (row) => {
    dispatch(selectedUserDetails(row));

    navigate("/UserProfile/UserProfile");
  };

  const toggleSelectAll = () => {
    const extractedIds = tempBusinessAccountData?.map((item) => item?.userId);

    if (selectAllIds.length === extractedIds.length) {
      setCheckboxStates({});

      setSelectAllIds([]);

      setChecked1(false);
    } else {
      // If not all checkboxes are selected, select them all

      const newCheckboxStates = {};

      extractedIds.forEach((id) => {
        newCheckboxStates[id] = true;
      });

      setCheckboxStates(newCheckboxStates);

      setSelectAllIds(extractedIds);

      setChecked1(true);
    }
  };

  const toggleCheckbox = (item) => {
    const extractedIds = tempBusinessAccountData?.map((item) => item?.userId);

    const rowId = item?.userId;

    const newCheckboxStates = { ...checkboxStates };

    newCheckboxStates[rowId] = !newCheckboxStates[rowId];

    const newSelectAllIds = newCheckboxStates[rowId]
      ? [...selectAllIds, rowId]
      : selectAllIds.filter((id) => id !== rowId);

    setCheckboxStates(newCheckboxStates);

    setSelectAllIds(newSelectAllIds);

    if (newSelectAllIds.length !== extractedIds.length) {
      setChecked1(false);
    } else {
      setChecked1(true);
    }
  };

  const EnterPassword = (userId) => {
    setPasswordDialog(true);
  };

  const SubmitChange = (e) => {
    e.preventDefault();
    // if (validate()) {

    const passwordsArray = selectAllIds.map(() => enteredPass);

    const passwordsArrayAll = allIds.map(() => enteredPass);

    let data = {
      accountType: "business",

      changePassword: checked1 === true ? passwordsArrayAll : passwordsArray,

      userIds: checked1 === true ? allIds : selectAllIds,
    };

    dispatch(UpdateTempPassAsync(data, setPasswordDialog));

    setPasswordDialog(false);

    setCheckboxStates(false);

    setChecked1(false);

    setAllIds([]);

    setSelectAllIds([]);
    // }
  };
  const validate = () => {
    if (enteredPass?.length < 8) {
      setPasswprdCheck(true);
    } else {
      setPasswprdCheck(false);
    }
  };

  useEffect(() => {
    if (enteredPass?.length === 0) {
      setPasswprdCheck(false);
    } else if (enteredPass?.length >= 8 && enteredPass?.length < 20) {
      setPasswprdCheck(false);
    } else {
      setPasswprdCheck(true);
    }
  }, [validate]);
  const sortDataDesc = () => {
    const sortedData = [...tempBusinessAccountData];
    sortedData.sort((a, b) => {
      const locationA = a.location.toString().toLowerCase();
      const locationB = b.location.toString().toLowerCase();
      return locationB.localeCompare(locationA);
    });
    sortedData.isSort = true;
    dispatch(getAllTempBusinessSuccess(sortedData));
  };

  const sortDataAsc = () => {
    const sortedData = [...tempBusinessAccountData];
    sortedData.sort((a, b) => {
      const locationA = a.location.toString().toLowerCase();
      const locationB = b.location.toString().toLowerCase();
      return locationA.localeCompare(locationB);
    });
    sortedData.isSort = true;

    dispatch(getAllTempBusinessSuccess(sortedData));
  };

  const sorting = () => {
    setIsSort(!isSort);
    if (!isSort) {
      sortDataAsc(); // Ascending order
    } else {
      sortDataDesc(); // Descending order
    }
  };
  const togglePasswordVisibility = (userId) => {
    setSelectedUserPasswords((prevPasswords) => ({
      ...prevPasswords,
      [userId]: !prevPasswords[userId],
    }));
  };
  // Broken image functionality

  const FailedToLoad = (e) => {
    console.log("not opened");
    e.target.src = avatar;
  };
  return (
    <Container>
      {tempBusinessDataLoading || deleteBusinessLoading || updateTempLoading ? (
        <Loader />
      ) : null}

      <div className="inner-containerForTempBusiness p-3">
        <div className="HeadingAndSearchTemp">
          <h1>Temporary Business Account</h1>

          <div className="RightBarTemp">
            <div className="inputDivTemp">
              <SearchIcon
                className="searchIconTemp"
                style={{ color: "#777", fontSize: 22 }}
              />

              <input
                onChange={SearchQuery}
                onKeyDown={(event) => {
                  event.key === "Enter" && getDatabyQuery();
                }}
                className="input-fieldTemp"
                type="text"
                placeholder="Search"
              />
            </div>

            <div
              onClick={() =>
                navigate("/CreateTempAccount/CreateTemporaryAccount")
              }
              className="Create-icons"
            >
              <AddIcon className="AddIcon-style " style={{ fontSize: 20 }} />

              <p>Create</p>
            </div>
          </div>
        </div>

        <div className="table-areaForTempBussiness ">
          <TableContainer sx={{ height: "calc( 100% - 70px)" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow className={classes.root}>
                  <TableCell>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <Checkbox checked={checked1} onChange={toggleSelectAll} />
                      <p>Business Name</p>
                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Email Address</p>

                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>

                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Location</p>

                      <img
                        onClick={sorting}
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>

                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Category</p>

                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>

                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Sub Category</p>

                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>

                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Address</p>

                      <img
                        style={{ width: 14 }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>

                  <TableCell sx={{ height: "30px" }}>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {/* <Checkbox checked={checked1}

                        onChange={toggleSelectAll} /> */}

                      <p>Password</p>

                      {/* <button
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                        className="eyeButton w-4 ml-1"
                      >
                        <img className="Eye" src={Eye} alt="Eye" />
                      </button> */}
                      {checked1 || selectAllIds.length > 0 ? (
                        <div className="ApplyBTNBusiness">
                          <img
                            onClick={EnterPassword}
                            src={FilledEditd}
                            alt="Edit"
                          />
                        </div>
                      ) : (
                        <div className="ApplyBTNBusiness">
                          <img src={FilledEditd} alt="Edit" />
                        </div>
                      )}
                    </span>
                  </TableCell>

                  <TableCell>
                    <span style={{ display: "flex" }}>
                      <p>Actions</p>

                      <img
                        style={{ width: 14, marginLeft: "0.2rem" }}
                        src={Group_345}
                        alt="Group_345"
                      />
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {tempBusinessAccountData?.length > 0 &&
                  tempBusinessAccountData?.map((item, i) => {
                    return (
                      <TableRow
                        className={classes.bodyroot}
                        hover
                        role="checkbox"
                      >
                        <TableCell key={i}>
                          <span className="flex  justify-start items-center  ">
                            <Checkbox
                              checked={checkboxStates[item?.userId] || checked1}
                              onChange={() => {
                                toggleCheckbox(item, i);
                                setshowPassword1(!showPassword1);
                              }}
                            />

                            <div
                              style={{
                                width: "1.8rem",
                                height: "1.8rem ",
                                border: "1px solid grey",
                                borderRadius: "50%",
                              }}
                            >
                              <img
                                style={{
                                  cursor: "pointer",
                                  width: "1.8rem",
                                  height: "1.8rem",
                                  borderRadius: "50%",
                                }}
                                src={
                                  item?.image &&
                                  (item.image.includes(".jpg") ||
                                    item.image.includes(".jpeg") ||
                                    item.image.includes(".png"))
                                    ? `https://${item?.image}`
                                    : avatar
                                }
                                alt="user"
                                onError={FailedToLoad}
                                onClick={() =>
                                  NavigateToTempBusinessUserProfile(item)
                                }
                              />
                            </div>

                            <span
                              onClick={() =>
                                NavigateToTempBusinessUserProfile(item)
                              }
                              className=" FirstClildTempBusiness "
                            >
                              {/* <span
                               
                                className="underLineTextTempBusiness "
                              >
                                {item?.userId}
                              </span> */}
                              <span className="returingDataDate">
                              {item?.firstName}  {item?.lastName}
                              </span>
                            </span>
                          </span>
                        </TableCell>

                        <TableCell>
                          <span className="returingDataTempBusiness">
                            {item?.emailAddress}
                          </span>
                        </TableCell>

                        <TableCell>
                          {/* <span className="flex  items-center ">
                          {item?.location?.map((data, i) => (
                            <>
                              <span className="returingDataTempBusiness">
                                {data}
                              </span>

                              {data.length !== 0 ? null : (
                                <div className="carbDiv">
                                  {item?.location.length - 1}
                                </div>
                              )}
                            </>
                          ))}
                        </span> */}

                          {/* new */}
                          {isSort == true ? (
                            <span className="flex items-center">
                              {/* Sort the original array */}
                              {item?.location.map((item, i, array) => (
                                <React.Fragment key={i}>
                                  {/* Check if the current item is the same as the previous item to skip duplicates */}
                                  {i === 0 || item !== array[i - 1] ? (
                                    <div className="returingData">{item} {i < item.location.length - 1 && ", "}</div>
                                  ) : null}
                                  {data.length !== 0 ? null : (
                                    <div className="carbDiv">
                                      {item?.location.filter(
                                        (val) => val === item
                                      ).length - 1}
                                    </div>
                                  )}
                                </React.Fragment>
                              ))}
                            </span>
                          ) : (
                            <span className="flex  items-center ">
                              {item?.location?.map((data, i) => (
                                <span key={i} className="returingDataTempBusiness">
                                  {data}
                                  {i < item.location.length - 1 && ", "}
                                </span>
                              ))}
                            </span>
                          )}
                        </TableCell>
                        <TableCell>
                          <p className="returingDataTempBusiness">
                            {item?.category?.name}
                          </p>
                        </TableCell>

                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "0.2rem",
                            }}
                          >
                            {item?.subCategory?.map((sub, i) => (
                              <p key={i} className="returingDataTempBusiness">
                                {sub?.name}
                                {i < item.subCategory.length - 1 ? "," : ""}
                              </p>
                            ))}
                          </div>
                        </TableCell>

                        <TableCell>
                          <p className="returingDataTempBusiness">
                            {item?.streetAddress}
                          </p>
                        </TableCell>

                        <TableCell>
                          <div className="passWordContainerTempcustomer">
                            {selectedUserPasswords[item.userId] ||
                            showPasswordForRows[item.userId] ? (
                              <>
                                <p
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content={item?.password}
                                  className="WrittenPassword"
                                >
                                  {item?.password?.length > 5
                                    ? item?.password.substring(0, 5) + ".."
                                    : item?.password}
                                  {item?.password?.length > 5 ? (
                                    <Tooltip
                                      id="my-tooltip"
                                      style={{
                                        backgroundColor: "#38ab7c",
                                        zIndex: 100,
                                      }}
                                    />
                                  ) : null}
                                </p>
                              </>
                            ) : (
                              <p className="WrittenPassword_star">*****</p>
                            )}
                            {/* {showPasswordForRows[item.userId] ? (
                            <p className="WrittenPassword">{item.password}</p>
                          ) : (
                            <p className="WrittenPassword">*****</p>
                          )} */}
                            <button
                              onClick={() =>
                                togglePasswordVisibility(item.userId)
                              }
                              className="eyeButton w-4 ml-3"
                            >
                              <img
                                className="hide_show"
                                src={
                                  selectedUserPasswords[item.userId]
                                    ? Eye
                                    : Eye1
                                }
                                alt="Eye"
                              />
                            </button>
                          </div>
                        </TableCell>
                        <TableCell>
                          <img
                            onClick={() => DeleteItem(item)}
                            style={{
                              cursor: "pointer",
                            }}
                            src={ThreeDot}
                            alt="More"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <TempBusAccountVerificationDialog
        SelectedTempId={SelectedTempId}
        tempBussAccountDeleteData={tempBussAccountDeleteData}
        setTempBussAccountDeleteData={setTempBussAccountDeleteData}
        TempBusAccountHandleClose={TempBusAccountHandleClose}
      />

      {PaginationDataLength > Constants.TAKE ? (
        <Footer
          page={page}
          handleChange={handleChange}
          totalRecord={PaginationDataLength}
          startedRecords={startedRecords}
          toRecords={toRecords}
          totalPages={totalPages}
        />
      ) : // <Footer
      //   page={page}
      //   handleChange={handleChange}
      //   totalRecord={AdminPaginationDataLength}
      //   startedRecords={adminStartedRecords}
      //   toRecords={adminToRecords}
      //   totalPages={totalAdminPage}
      // />
      null}
      <Dialog onClose={() => setPasswordDialog(false)} open={passwordDialog}>
        <div className="ChangePassDialog">
          <span style={{ fontFamily: "sans-serif" }}>Enter password</span>
          <input
            onChange={(e) => setInteredPass(e.target.value)}
            type="text"
            placeholder="Enter New password"
          />
          {passwordCheck === true ? (
            <h6 className="password_validate">
              Password must be min 8 character and max 20 character “Please use
              lower and upper case letters, numbers and special characters such
              as: #, $, &, ect.” for strong password
            </h6>
          ) : null}
          <div className="change_pass_btns">
            {enteredPass?.length >= 8 && enteredPass?.length < 20 ? (
              <div
                className="btn"
                onClick={SubmitChange}
                style={{ fontFamily: "sans-serif", fontSize: 15 }}
              >
                Change
              </div>
            ) : (
              <div
                className="btn_disable"
                // onClick={SubmitChange}
                style={{ fontFamily: "sans-serif", fontSize: 15 }}
              >
                Change
              </div>
            )}

            <div className="CloseBTN" onClick={() => setPasswordDialog(false)}>
              <span style={{ fontFamily: "sans-serif", fontSize: 15 }}>
                Cancel
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </Container>
  );
}

export default TempBusinessAccount;

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-head": {
      color: "white",
      height: "28px",
      padding: "0.2rem 0.8rem",

      height: "28px",

      padding: "0.2rem 0.8rem",
    },
  },
  bodyroot: {
    "&:hover": {
      backgroundColor: "#c5d9cf !important",
    },
    "& .MuiTableCell-body": {
      height: "28px",
      padding: "0.2rem 0.8rem",
    },
  },
});
