import React, { useEffect, useState, useRef } from "react";
import ContainerWithoutFooter from "../../../components/HOC/ContainerWithoutFooter";
import "./Announcement.scss";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import No_Data_Found from "../../../assets/icons/No_Data_Found.svg";
import Pdf from "react-to-pdf";
import moment from "moment";
import {
  getAllAnnouncementAsync,
  // updateAnnouncementAsync,
} from "../../../redux/announcementsSlice";
import AnnouncementDialog from "../../../components/Dialogs/AnnouncementDialog/AnnouncementDialog";
import Loader from "../../../components/Loader";
import ShowMoreText from "react-show-more-text";
import { Tooltip } from "react-tooltip";
function Announcement() {
  const dispatch = useDispatch();
  const [openAnnouncementDialog, setopenAnnouncementDialog] = useState(false);
  const [announcementItem, setannouncementItem] = useState("");
  const [serachAnnouncement, setSearchAnnouncement] = useState("");
  const [showTitle, setshowTitle] = useState(false);
  const ref = useRef();
  useEffect(() => {
    dispatch(getAllAnnouncementAsync("all"));
  }, [dispatch]);

  const {
    announcementData,
    updateAnnouncementLoading,
    announcementLoading,
    // announcementFailedData,
  } = useSelector((state) => state.announcement);
  const openDialog = (item) => {
    setannouncementItem(item);
    setopenAnnouncementDialog(true);
  };
  const SearchAnnouncementByQuery = (e) => {
    setSearchAnnouncement(e.target.value);
  };
  const SeacrhQueryRequestF_APICall = () => {
    if (serachAnnouncement.trim()?.length > 0) {
      dispatch(getAllAnnouncementAsync(serachAnnouncement.trim()));
    } else {
      dispatch(getAllAnnouncementAsync("all"));
    }
  };

  const pagePrint = () => {
    window.print();
  };
  const getByTitle = (item) => {
    const sameData = announcementData?.filter((data) => {
      return data?.id === item?.id;
    });
    return sameData;
  };
  const formatDate = (timestamp) => {
    const today = moment().startOf("day");
    const messageDate = moment(parseInt(timestamp, 10));
    if (messageDate.isSame(today, "day")) {
      return "Today";
    } else if (messageDate.isSame(today.clone().subtract(1, "day"), "day")) {
      return "Yesterday";
    } else {
      return messageDate.format("DD/MM/YYYY");
    }
  };
  return (
    <ContainerWithoutFooter>
      {updateAnnouncementLoading || announcementLoading ? <Loader /> : null}
      <AnnouncementDialog
        openAnnouncementDialog={openAnnouncementDialog}
        setopenAnnouncementDialog={setopenAnnouncementDialog}
        announcementItem={announcementItem}
      />
      <div className="topBar">
        <h1>Announcements Text</h1>
        <div className="rightIconContainer">
          <div className="search-area ">
            <div className="p-1 SearchIcon">
              <SearchIcon
                onClick={SeacrhQueryRequestF_APICall}
                style={{ color: "#777", cursor: "pointer" }}
              />
            </div>
            <input
              onChange={SearchAnnouncementByQuery}
              type="text"
              value={serachAnnouncement}
              placeholder="Search like this IPT_ID"
              onKeyDown={(event) => {
                event.key === "Enter" && SeacrhQueryRequestF_APICall();
              }}
            />
          </div>
          <div className="pdf">
            <PictureAsPdfOutlinedIcon className="Icons" />
            <Pdf
              targetRef={ref}
              filename="document.pdf"
              x={0.5}
              y={0.5}
              scale={0.8}
            >
              {({ toPdf }) => <p onClick={toPdf}>Save as pdf</p>}
            </Pdf>
          </div>

          <div onClick={pagePrint} className="pdf2">
            <LocalPrintshopOutlinedIcon className="Icons" />
            <p>Print</p>
          </div>
        </div>
      </div>

      <div ref={ref} className="inner-container-Announcement ">
        <div className="CardContainer">
          {announcementData?.length !== 0 ? (
            <>
              {announcementData &&
                announcementData?.map((item) => (
                  <div className="cards" key={item?.id}>
                    <div className="topitems">
                      {item?.title?.length > 21 ? (
                        <>
                          <p
                            className="itemText"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content={item?.title}
                          >
                            {item?.title?.length > 5
                              ? item?.title?.slice(0, 21) + "..."
                              : item?.title}
                          </p>
                          {item?.title?.length > 5 ? (
                            <Tooltip
                              id="my-tooltip"
                              style={{
                                backgroundColor: "#38ab7c",
                                zIndex: 100,
                              }}
                            />
                          ) : null}
                        </>
                      ) : (
                        <div className="itemText">{item?.title}</div>
                      )}
                      <div
                        className="itemIcon"
                        onClick={() => openDialog(item)}
                      >
                        <DriveFileRenameOutlineOutlinedIcon className="editIcon" />
                      </div>
                    </div>
                    <ShowMoreText
                      lines={2}
                      more={
                        <span
                          onClick={() => setshowTitle(true)}
                          className="SeeMore"
                        >
                          See More
                        </span>
                      }
                      less={
                        <span
                          onClick={() => setshowTitle(false)}
                          className="SeeLess"
                        >
                          {" "}
                          ↑
                        </span>
                      }
                      className="paracontainer"
                      anchorClass="paracontainer_Hide"
                      expanded={false}
                      truncatedEndingComponent={"..."}
                      // width={100}
                    >
                      <p className="_showAndLess">{item?.description}</p>
                    </ShowMoreText>
                    <p>  {formatDate(item?.updatedAt * 1000)} </p>
                  </div>
                ))}
            </>
          ) : (
            <>
              <div className="NoDataFound">
                <div className="imageContainer">
                  <img src={No_Data_Found} alt=" No Data Found" />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ContainerWithoutFooter>
  );
}

export default Announcement;
