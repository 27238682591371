import moment from "moment";

export const formatDate = (timestamp) => {
  const messageDate = moment(parseInt(timestamp, 10));

  const todayTimestamp = moment().startOf("day").valueOf();
  const yesterdayTimestamp = moment()
    .subtract(1, "days")
    .startOf("day")
    .valueOf();

  if (timestamp >= todayTimestamp) {
    return `Today at ${messageDate.format("h:mm A")}`;
  } else if (timestamp >= yesterdayTimestamp) {
    return `Yesterday at ${messageDate.format("h:mm A")}`;
  } else {
    return messageDate.format("MMM DD [at] h:mm A");
  }
};

export const formatDateMsg = (timestamp) => {
  if (!timestamp) {
    return "";
  }
  const messageDate = moment(parseInt(timestamp, 10));

  const todayTimestamp = moment().startOf("day").valueOf();
  const yesterdayTimestamp = moment()
    .subtract(1, "days")
    .startOf("day")
    .valueOf();

  if (timestamp >= todayTimestamp) {
    return `${messageDate.format("h:mm A")}`;
  } else if (timestamp >= yesterdayTimestamp) {
    return `Yesterday`;
  } else {
    return messageDate.format("MMM DD");
  }
};
