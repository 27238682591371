import React from "react";
import Header from "../Header/Header";

function ContainerWithoutFooter(props) {
  return (
    <div className="h-screen w-full flex overflow-hidden">
      <div className="whole-container h-full w-full">
        <Header />
        {props.children}
      </div>
    </div>
  );
}

export default ContainerWithoutFooter;
