import React from "react";
import "./Delete.scss";
import Dialog from "@mui/material/Dialog";
import cross from "../../../assets/icons/cross.svg";
import Deleting from "../../../assets/icons/Deleting.svg";
import DeleteSuccess from "../Deleted/DeleteSuccess";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { deleteAdminAsync } from "../../../redux/adminManagementSlice";

const Delete = ({ deleteAdmin, setDeleteAdmin }) => {
  const [open, setOpen] = useState(false);
  const { SelectedUUID } = useSelector((state) => state.adminManage);
  const dispatch = useDispatch();

  const handleClose = () => {
    setDeleteAdmin(false);
    setOpen(false);
  };
  const YesDelete = (e) => {
    e.preventDefault();
    setDeleteAdmin(false);
    dispatch(deleteAdminAsync(SelectedUUID)).then((r) => {
      console.log("resp after deleted",r)
      setOpen(true);
    });
  };
  return (
    <>
      <Dialog onClose={handleClose} open={deleteAdmin}>
        <div className="DeleteContainer">
          <div className="cancelCont">
            <img
              onClick={() => setDeleteAdmin(false)}
              src={cross}
              alt="cancel"
            />
          </div>
          <div className="FrameDelete">
            <img src={Deleting} alt="Deleting" />
          </div>
          <div className="textCont">
            <p>Are you sure you want to delete this admin?</p>
          </div>
          <div className="buttonDiv">
            <div onClick={() => setDeleteAdmin(false)} className="button1">
              No, Cancel
            </div>
            <div onClick={YesDelete} className="button2">
              Yes Delete
            </div>
          </div>
        </div>
      </Dialog>
      <DeleteSuccess open={open} setOpen={setOpen} onClose={handleClose} />
    </>
  );
};

export default Delete;
