import React from "react";
import Dialog from "@mui/material/Dialog";
import DeleteImage from "../../../assets/icons/delete-modal-pic.svg";
import Cross from "../../../assets/icons/cross.svg";
import "./CommonStylingForDeleteDialog.scss";
import { deleteBusinessAsync } from "../../../redux/usersSlice";
import { useDispatch } from "react-redux";

const BusinessLicenceverificationDialog = ({
  busLicenseVerificationDeleteData,
  setbusLicenseVerificationDeleteData,
  BusLicenseVerificationhandleClose,
  SelectedBus_License_Veri_Email,
}) => {
  const dispatch = useDispatch();
  const DeleteUser = (e) => {
    e.preventDefault();
    setbusLicenseVerificationDeleteData(false);
    dispatch(deleteBusinessAsync(SelectedBus_License_Veri_Email.current));
  };
  return (
    <Dialog open={busLicenseVerificationDeleteData}>
      <div className="DeleteContainer">
        <img
          onClick={BusLicenseVerificationhandleClose}
          className="Cancelicon"
          src={Cross}
          alt="Cancel"
        />
        <img className="DeleteImage" src={DeleteImage} alt="Delete User" />
        <p>Are you sure you want to delete this admin?</p>
        <div className="CancelAndDeleteBTN">
          <h5 onClick={() => setbusLicenseVerificationDeleteData(false)}>
            No, Cancel
          </h5>
          <button onClick={DeleteUser}>
            <h4>Yes Delete</h4>
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default BusinessLicenceverificationDialog;
