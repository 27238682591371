import React, { useState } from "react";
import "./ChangePassword.scss";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAsync,
  getAllAdminAsync,
} from "../../../redux/adminManagementSlice";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { useEffect } from "react";
import { Constants } from "../../../utils/Constants";
const ChangePassword = (props) => {
  const { change, setChange } = props;
  const { selectedId, changePasswordLoading, SelectedUUID } = useSelector(
    (state) => state.adminManage
  );
  const { userDetails } = useSelector((state) => state.auth);
  const loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));
  useEffect(() => {
    console.log("itemmm", changePasswordLoading);
  }, []);

  const [userDetail, setUserDetail] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const handleClose = () => {
    setChange(false);
    setUserDetail({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };
  const dispatch = useDispatch();

  const changeCurrentPassword = (e) => {
    const value = e.target.value;
    setUserDetail({
      ...userDetail,
      currentPassword: value,
    });
  };

  const changeNewPassword = (e) => {
    const value = e.target.value;
    setUserDetail({
      ...userDetail,
      newPassword: value,
    });
  };

  const changeConfirmPassword = (e) => {
    const value = e.target.value;
    setUserDetail({
      ...userDetail,
      confirmPassword: value,
    });
  };
  const changePasswordApiCall = () => {
    if (!userDetail.currentPassword) {
      toast.error("Please Enter All Fields");
      return;
    } else if ((userDetail.newPassword || userDetail.confirmPassword) === "") {
      toast.error("Please enter your new password");
      return;
    } else if (userDetail.newPassword === "") {
      toast.error("Please enter your new password");
      return;
    } else if (userDetail.confirmPassword === "") {
      toast.error("Please re-enter your new password");
      return;
    } else if (!(userDetail.newPassword === userDetail.confirmPassword)) {
      toast.error("Password Not Matched");
      return;
    } else {
      const data = {
        id: SelectedUUID,
        password: userDetail.currentPassword,
        newPassword: userDetail.newPassword,
      };
      // dispatch(changePasswordAsync(data, handleClose));
      dispatch(changePasswordAsync(data)).then((r) => {
        console.log("res", r);
        if (r === "success") {
          dispatch(
            getAllAdminAsync(
              "",
              "",
              userDetails.isPrimaryAccount,
              userDetails?.isShowMasterAdminAcc,
              Constants.TAKE,
              1
            )
          );
          handleClose();
          // setChange(false)
        }
      });
    }
  };
  return (
    <Dialog onClose={handleClose} open={change}>
      <>
        {changePasswordLoading ? <Loader /> : null}
        <div className="ChangePassBox">
          <div className="head_container">
            <h3>Change Password</h3>
            <span>UserId: {selectedId}</span>
          </div>
          <div className="inputBox">
            <div className="emailInputs">
              <p>Current Password</p>
              <input
                type="text"
                placeholder="Enter Here"
                value={userDetail.currentPassword}
                onChange={changeCurrentPassword}
              />
            </div>
            <div className="emailInputs">
              <p>New Password</p>
              <input
                type="text"
                placeholder="Enter Here"
                value={userDetail.newPassword}
                onChange={changeNewPassword}
              />
            </div>
            <div className="emailInputs">
              <p>Re-enter Password</p>
              <input
                type="text"
                placeholder="Enter Here"
                value={userDetail.confirmPassword}
                onChange={changeConfirmPassword}
              />
            </div>
            <div className="buttons">
              <button onClick={() => setChange(false)} className="button1">
                Cancel
              </button>
              <button className="button2" onClick={changePasswordApiCall}>
                Save
              </button>
            </div>
          </div>
        </div>
      </>
    </Dialog>
  );
};

export default ChangePassword;
