import {
  connectSocket,
  disconnectSocket,
  receiveMessage,
  getNotifications,
  getDocsNotifications,
  GetAllNotificationBusinessAsync,
  GetAllNotificationCustomerAsync,
  getPreviousChatRoomId,
  getChatNotification,
  AddNewUserToChat,
  setNewlyCreatedQA,
  closeChatSession,
  RemoveUsersFromChat,
  // incrementUnreadMessageCount,
} from "./chatSlice";

// import { sendMessageSuccess } from "./chatSlice";
import { UpdateUserForChat } from "./usersSlice";
import { RefreshAdminAsync } from "./adminManagementSlice";
// import { useDispatch } from "react-redux";
import io from "socket.io-client";
import { ApiConnection } from "../config/env";
import axiosInstance from "../helper/axiosInstance";
import { setHideShowMA } from "./adminManagementSlice";

// let loggesInUser = JSON.parse(sessionStorage.getItem("userLoggedDetails"));

// loggesInUser = loggesInUser?.data;

class Socket {
  static async socketInit(userId, dispatch) {
    if (!this.socket) {
      this.socket = io.connect(ApiConnection.BACKEND_URL, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
      });

      await new Promise((resolve) => {
        this.socket.on("connect", async (res) => {
          this.isConnected = this.socket.connected;
          resolve();
          console.log("socket connected----", this.socket);
          dispatch(connectSocket(this.isConnected));

          let userId;
          let resp = sessionStorage.getItem("userLoggedDetails");
          if (resp) {
            let user = JSON.parse(resp);
            userId = user.userId;
          }
          console.log("loggedin userId is here!!!!!!!!!!!", userId);
          let updated = await axiosInstance.post(
            "/BusisnessUser/updatedConnectionId",
            {
              userId: userId,
              connectionId: this.socket.id,
            }
          );
        });

        this.socket.on("disconnect", (res) => {
          console.log("disconnect", res);
          this.isConnected = this.socket.connected;
        });
        this.socket.on("reconnect", (e) => {
          console.log("reconnect", this.socket, e);
          this.isConnected = this.socket.connected;
        });

        this.socket.on("newMessage", (sendMessageData) => {
          console.log("newMessage----", sendMessageData);

          let data = [];
          let newMassage = sendMessageData;
          newMassage.text = sendMessageData.text;
          if (userId === sendMessageData.senderId) {
            newMassage.user = { _id: 1 };
          } else {
            newMassage.user = { _id: 2 };
          }
          data.push(newMassage);
          dispatch(receiveMessage(data));
        });

        this.socket.on("chatNotify", (data) => {
          console.log("chatNotify----", data);
          dispatch(getChatNotification(data));
          dispatch(AddNewUserToChat(data));
        });
        this.socket.on("handleChatNotify", (data) => {
          console.log("handleChatNotify----", data);
          dispatch(getChatNotification(data));
          dispatch(UpdateUserForChat(data));
        });
        this.socket.on("getNotification", (msgNotificationData) => {
          let data = [];
          let newMassage = msgNotificationData;
          newMassage.text = msgNotificationData.text;
          if (userId === msgNotificationData.senderId) {
            newMassage.user = { _id: 1 };
          } else {
            newMassage.user = { _id: 2 };
          }
          data.push(newMassage);
          dispatch(getNotifications(data));
        });
        this.socket.on("handleDocsUpload", (docsData) => {
          console.log("handleDocsUpload in socket Connect", docsData);
          dispatch(getDocsNotifications(docsData));
          if (docsData?.businessUserTb) {
            dispatch(GetAllNotificationBusinessAsync());
          } else {
            dispatch(GetAllNotificationCustomerAsync());
          }
        });
        this.socket.on("adminAvailable", (data) => {
          console.log("previous users chat in socket ", data);
          dispatch(getPreviousChatRoomId(data));
        });
        this.socket.on("chatSessionExpired", (data) => {
          console.log("chatSessionExpired ", data);
          dispatch(closeChatSession(data));
        });
        this.socket.on("RemoveUserFromChat", (data) => {
          console.log("RemoveUserFromChat^^^^^^^^^^^^^^^^^^^^ ", data);
          dispatch(RemoveUsersFromChat(data));
        });
        this.socket.on("chatNotify", (data) => {
          console.log("chatNotify here ", data);
          dispatch(RemoveUsersFromChat(data));
        });
        this.socket.on("showHidePrimaryMadmin", (data) => {
          console.log("showHidePrimaryMadmin here ", data);
          dispatch(setHideShowMA(data));
        });
        this.socket.on("QuestionCreatedForTempCust", (data) => {
          console.log("QuestionCreated in socket ", data);
          dispatch(setNewlyCreatedQA(data));
        });
        this.socket.on("AdminLogin", (data) => {
          console.log("AdminLogin login in socket >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data);
          dispatch(RefreshAdminAsync(data));
        });
      });
    }
  }

  static joinRoom(roomData) {
    this.socket.emit("joinChat", roomData);
  }

  static onSend(messageData) {
    console.log("sendMessage", messageData);
    this.socket.emit("sendMessage", messageData);
    // const data = [];
    // dispatch(receiveMessage(data));
  }

  // static handleNewMessage(messageData, dispatch) {
  //   if (this.newMessageCallback) {
  //     this.newMessageCallback(messageData);
  //     if (messageData.senderId !== loggesInUser.id) {
  //       dispatch(incrementUnreadMessageCount({ userId: messageData.senderId }));
  //     }
  //   }
  // }

  static logoutUser(dispatch) {
    this.socket.disconnect();
    this.socket = null;
    this.isConnected = false;
    dispatch(disconnectSocket());
  }
}

export default Socket;
